// Pagination

.pagination {
  margin: auto;
  clear: both;
  text-align: center;

  li {
    display: inline-block;
    margin: 4px 2px;
    vertical-align: top;
    @media (max-width: 350px) {
      margin: 4px 0;
    }

    &.prev {
      a {
        color: $color-grey-dark;

        &::before {
          @include font-awesome;
          font-size: 24px;
          content: '\f104';
        }
      }
    }

    &.next {
      a {
        color: $color-grey-dark;

        &::before {
          @include font-awesome;
          font-size: 24px;
          content: '\f105';
        }
      }
    }

    &.active {
      a {
        border-color: $color-red;
        background-color: transparent;
        color: $color-red;
      }
    }
  }

  a {
    display: block;
    width: 35px;
    height: 35px;
    transition: border-color .2s ease, transform .2s ease;
    border: 1px solid transparent;
    border-radius: .8rem;
    background-color: transparent;
    color: $color-grey-darker;
    font-size: 20px;
    font-weight: 400;
    line-height: 34px;
    text-decoration: none;

    &:hover {
      border-color: $color-red;
    }

    &:active {
      transform: translateY(1px);
      transition: border-color 0s, transform 0s;
    }
  }

  span {
    display: inline-block;
    width: 25px;
    height: 35px;
    line-height: 37px;
    vertical-align: top;
  }
}
