// Socials

.socials {
  text-align: center;

  li {
    display: inline-block;
    margin: .5rem;
    vertical-align: top;
  }

  a {
    display: inline-block;
    width: 28px;
    height: 28px;
    color: $color-white-50;
    text-align: center;

    &::before {
      width: 28px;
      height: 28px;
      font-size: 2.2rem;
      line-height: 28px;
    }
  }

  .fa-facebook {
    &:hover {
      color: $color-socials-facebook;
    }
  }

  .fa-linkedin {
    &:hover {
      color: $color-socials-linkedin;
    }
  }

  .fa-youtube-play {
    &:hover {
      color: $color-socials-youtube;
    }
  }

  .fa-google-plus {
    &:hover {
      color: $color-socials-google-plus;
    }
  }

  .fa-paper-plane {
    &:hover {
      color: $color-socials-telegram;
    }
  }

  .ico-blogger {
    &:hover {
      color: $color-socials-blogger;
    }
  }

  .fa-twitter {
    &::before {
      font-size: 2.3rem;
    }

    &:hover {
      color: $color-socials-twitter;
    }
  }

  .fa-instagram {
    &:hover {
      color: $color-socials-instagram;
    }
  }

  .fa-vk {
    &:hover {
      color: $color-socials-vk;
    }
  }

  .fa-periscope {
    transition: background-position 0s;
    background: {
      position: top 4px center;
      image: url('../images/icon/ico-periscope.png');
      size: 22px 60px;
      repeat: no-repeat;
    }

    &:hover {
      background-position: top -26px center;
    }
  }
  
  &-sidebar {
    .socials-nav {
      display: flex;
      justify-content: center;
      border-bottom: 1px solid transparentize($color-black, .8);
      
      a {
        width: 100%;
        max-width: 95px;
        height: 100%;
        padding: 12px 0;
        transition: border-bottom .3s, color .3s;
        border-bottom: 3px solid transparent;
        color: $color-grey-darker;
        
        &:hover {
          color: $color-grey-darker;
        }
        
        &.fa-paper-plane {
          &.active {
            border-bottom-color: $color-socials-telegram;
            color: $color-socials-telegram;
          }
        }
        
        &.fa-twitter {
          &.active {
            border-bottom-color: $color-socials-twitter;
            color: $color-socials-twitter;
          }
        }
        
        &.fa-facebook {
          &.active {
            border-bottom-color: $color-socials-facebook;
            color: $color-socials-facebook;
          }
        }
      }
    }
    
    .socials-container {
      height: 300px;
      background-color: $color-grey3;
      overflow: hidden;
      overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 5px;
      }
      &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 3px #aaa;
        -webkit-border-radius: 3px;
        border-radius: 3px;
      }
      &::-webkit-scrollbar-thumb {
        -webkit-border-radius: 3px;
        border-radius: 5px;
        background: #aaa;
        -webkit-box-shadow: inset 0 0 3px #aaa;
      }

      .social-container {
        display: none;
        height: 100%;
        
        &.active {
          display: block;
        }
      }
    }
  }
  
  &-counter {
    display: none;
    position: relative;
    margin-left: 7px;
    padding: 2px 6px 0;
    border: 1px solid transparentize($color-grey2, .6);
    border-radius: 5px;
    font-size: 1.4rem;
    
    &:empty {
      display: none;
    }
    
    &::before {
      position: absolute;
      top: 50%;
      left: -6px;
      width: 6px;
      height: 6px;
      transform: rotate(45deg) translateY(-50%);
      border-bottom: 1px solid transparentize($color-grey2, .6);
      border-left: 1px solid transparentize($color-grey2, .6);
      background-color: $color-white;
      content: '';
    }
  }
  
  &-colored {
    a {
      width: auto;
      height: 18px;
      color: $color-grey2;
      
      &::before {
        width: 18px;
        height: 18px;
        font-size: 1.8rem;
      }
      
      &.fa-facebook {
        color: $color-socials-facebook;
      }

      &.fa-linkedin {
        color: $color-socials-linkedin;
      }
      
      &.fa-google-plus {
        color: $color-socials-google-plus;
      }
      
      &.fa-twitter {
        color: $color-socials-twitter;
      }
      
      &.fa-vk {
        color: $color-socials-vk;
      }
    }
  }
  
  @media only screen and (max-width: 989px) {
    &-sidebar {
      .socials-nav {
        a {
          &::before {
            font-size: 1.8rem;
          }
          
          &.fa-twitter {
            &::before {
              font-size: 1.9rem;
            }
          }
        }
      }
    }
  }
  
  @media only screen and (max-width: 499px) {
    &-counter {
      display: none;
    }
    
    &-colored {
      li {
        margin: .3rem;
      }
    }
  }
}
