

.minsk-inner-page{
    background-color: #b40e33;
    .minsk-header{
        position: relative;
            .minsk-bg{
                background: url('../images/minsk_igaming/minsk-header-bg.png') 50%  90% / cover no-repeat;
                position:absolute;
                top: 0;
                left: 0;
                bottom: 0;
                right: 0;
            }
            .minsk-container-img{
                position: relative;
                &::before{
                    z-index: 0;
                    content:'';
                    position: absolute;
                    top: 0;
                    right: 0;
                    background: url('../images/minsk_igaming/minsk_laptop.png') 50% 50% /cover no-repeat;
                    width: 100%;
                    max-width: 800px;
                    height: 592px;
                    @media only screen and (max-width:1120px){
                        opacity: .5;
                    }
                    @media only screen and (max-width:1120px){
                        opacity: .3;
                    }
        
                }
            .minsk-offer{
                position: relative;
                z-index: 2;
                width: 100%;
                max-width: 655px;
                &__title{
                    h1{
                        text-align: left;
                        font-size: 58px;
                        color: #fff;
                        font-weight: 700;
                        text-transform: none;
                        line-height: 80px;
                    }
                }
                &__info{
                    p{
                        color: #fff;
                        font-size: 32px;
                        font-weight: 300;
                        line-height: 42px; 
                        margin-bottom: 100px;
                        span{
                            text-transform: uppercase;
                        }
                    }
                }
                &__button{
                    button{
                        background-color: #c91c1c;
                        padding: 20px;
                        color: #fff;
                        width: 100%;
                        max-width: 300px;
                        border-radius: 10px;
                        font-size: 21px;
                        font-weight: 300;
                        border-bottom: 0;
                        transition: .2s ease-in-out;
                        &:hover{
                            box-shadow: 0 0 80px 1px #c91c1c;
                        }
                    }
                }
                @media only screen and (max-width:767px){
                   &__title h1{
                        font-size: 50px;
                   }
                   &__info p{
                       font-size: 30px;
                   }
                }
                @media only screen and (max-width:575px){
                   max-width:100%;
                   text-align: center;
                    &__button button{
                        max-width: 230px;
                        font-size: 18px;
                    }
                    &__title h1{
                        font-size: 40px;
                        text-align: center;
                        line-height: 50px;
                        margin-bottom: 30px;
                   }
                   &__info p{
                    font-size: 25px;
                    margin-bottom: 30px;
                }
                }
            }
            .minsk-arrow{
                padding: 100px 20px 50px;
                img{
                    margin: 0 auto;
                    cursor: pointer;
                    transform: scale(1);
                    transition: .2s ease-in-out;
                    &:hover{
                        transform: scale(1.2);
                    }
                }
            }
        }       
    }
    .minsk-inform{
        position: relative;
        // background: url('../images/minsk_igaming/1.png') 0% 50% / cover no-repeat;
        .minsk-inform-bg{
            background: url('../images/minsk_igaming/1.png') 50% 20% / cover no-repeat;
            position:absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
        }
        .expect{
            margin-bottom: 75px;
            &__title{
                color: #fff;
                font-size: 42px;
                text-shadow: 3px 3px 1px rgba(2, 6, 8, 0.2);
                margin: none;
                text-transform: none;
                max-width: none;
            }
            &__info{
                color: #fff;
                text-align: center;
                font-size: 22px;
                font-weight: 300;
                line-height: 30px;

            }
            @media only screen and (max-width:767px){
                &__info{
                    font-size: 20px;
                }
                &__title{
                    font-size: 35px;
                }
            }
            @media only screen and (max-width:575px){
                &__info{
                    font-size: 18px;
                }
                &__title{
                    font-size: 33px;
                }
            }
        }
        .block-inform{
            padding: 40px 65px;
            font-size: 21px;
            box-shadow: 0 0 18px rgba(237, 13, 50, 0.18);
            width: 100%;
            max-width: 857px;
            margin: 0 auto;
            background-color: rgba(23, 23, 62, 0.78);
            border-radius: 10px;
            &__top{
                margin-bottom: 30px;
                h3{
                    font-weight: 700;
                    line-height: 26px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                ul{
                    color: #fff;
                    list-style-type: disc;

                    li{
                        font-weight: 300;
                    }
                }
            }
            &__bottom{
                h3{
                    font-weight: 700;
                    line-height: 26px;
                    color: #fff;
                    margin-bottom: 20px;
                }
                ul{
                    color: #fff;
                    list-style-type: disc;

                    li{
                        font-weight: 300;
                    }
                }
            }
            @media only screen and (max-width:767px){
                padding:20px;
                font-size: 20px;
                &__top{
                    h3{
                        padding:10px;
                    }
                    ul{
                        padding: 10px;
                    }
                }
                &__bottom{
                    h3{
                        padding:10px;
                    }
                    ul{
                        padding: 10px;
                    }
                }
            } 
            @media only screen and (max-width:575px){
                padding:20px;
                font-size: 18px;
                &__top{
                    h3{
                      font-size: 20px;
                    }
                   
                }
                &__bottom{
                    h3{
                        font-size: 20px;
                    }
                }
            } 
        } 
    }
    .minsk-map{
        padding: 0;
    }
    .minsk-form{
        background: url('../images/minsk_igaming/2.png') 50% 50% / cover no-repeat;
        padding: 0;
        .block-form{
            position: relative;
            top: -80px;
            box-shadow: 8px 6px 8px rgba(2, 6, 8, 0.19);
            background-color: rgba(27, 27, 72, 0.904);
            padding: 33px 150px;
            border-radius: 10px;
            &__title{
                h2{
                    color: #fff;
                    font-weight: 700;
                    text-transform: none;
                    font-size: 42px;
                    margin-bottom: 15px;

                }
            }
        }
    }
    .minsk-form{
        .block-form{
            &__title h2{
                font-size: 42px;
            }
            .minsk-page-form{
                input{
                    font-size: 20px;
                    border-radius: 10px;
                    border-color: #fff;
                    color: #fff;
                    &::placeholder{
                        color: #fff;
                    }
                }
            }
        }
        @media only screen and (max-width:767px){
            .block-form{
                padding: 20px;
                .minsk-page-form{
                    input{
                        font-size: 18px;
                    }
                }
                &__title h2{
                    font-size: 33px;
                }
            }
            
        }  
             
    }
   
} 

 
@media only screen and (max-width:1140px){

}
@media only screen and (max-width:768px){
    
}   
@media only screen and (max-width:575px){
    
}        