.news-list {
  .slider-news {
    margin-bottom: 30px;
  }
  .read-also-title{
    display: block;
    margin-bottom: 2.5rem;
    color: $color-grey-darker;
    text-align: center;
    text-transform: uppercase;
    font-size: 2.2rem;
    font-weight: 300;
    line-height: 1.29;
    max-width: 770px;
    margin: 0 auto 50px;
  }

  .news-groups {
    display: block;


    @media only screen and (max-width: 1300px) {
      width: 85%;
      margin: 0 auto;
    }
    @media only screen and (max-width: 799px) {
      width: 100%;
    }

    ul {
      display: block;
      width: calc(100% + 30px);
      margin-right: -15px;
      margin-left: -15px;
      font-size: 0;

      li {
        display: inline-block;
        width: 33.333%;
        padding: 0 15px;
        vertical-align: top;
        .illustration{
          >img{
            object-fit: fill;
          }
        }
        &.hover {
          .illustration {
            >img {
              &:not(.logo-slotegrator) {
                transform: scale(1.1);
              }
            }
          }

          p {
            a {
              border-color: $color-grey-darker;
              color: lighten($color-grey-darker, 10%);
            }
          }
        }
      }
    }
    h3 {
      display: block;
      margin-bottom: 2.5rem;
      color: $color-grey-darker;
      text-align: center;
      text-transform: uppercase;

      a {
        transition: color .2s ease;
        color: $color-grey-darker;

        &:hover {
          color: $color-red;
        }
      }
    }

    p {
      display: block;
      margin-top: 20px;
      height: 50px;

      a {
        transition: all .2s ease;
        border-bottom: 1px solid transparent;
        color: $color-black;
        font-size: 1.6rem;
        font-weight: 400;
      }
    }
  }

  @media only screen and (max-width: 799px) {
    .news-groups {
      ul {
        li {
          width: 50%;
          margin-bottom: 35px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      
      &.slider {
        padding: 0 70px;
        
        ul {
          li {
            margin-bottom: 0;
            padding: 0;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1132px) {
    .slider-news {
      .slick-dots {
        bottom: 30px;
      }
    }
  }
  @media only screen and (max-width: 750px) {
    .slider-news {
      .slick-dots {
        bottom: 80px;
      }
    }
  }

  @media only screen and (max-width: 519px) {
    .slider-news {
      width: calc(100% + 20px);
      margin-right: -10px;
      margin-left: -10px;

      .slick-dots {

      }
    }
  }

  @media only screen and (max-width: 479px) {
    .news-groups {
      ul {
        li {
          width: 100%;
          margin-bottom: 25px;

          &:last-of-type {
            margin-bottom: 0;
          }
        }
      }
      
      &.slider {
        padding: 0 45px;
        
        .slick-arrow {
          &::before {
            font-size: 3.5rem;
          }
        }
        
        .fa-angle-left {
          left: -35px;
        }
        
        .fa-angle-right {
          right: -35px;
        }
      }
    }
  }

  @media only screen and (max-width: 399px) {
    .slider-news {
      .slick-dots {

      }
    }
  }
}