.table-standart {
  width: 100%;
  margin-bottom: 45px;
  border-radius: 3px;
  font-size: 1.4rem;
  text-align: center;
  overflow: hidden;
  
  th {
    padding: 12px 10px 10px;
    border-right: 1px solid $color-grey-light;
    background-color: $color-grey3;
    
    &:last-child {
      border-right: 0;
    }
  }
  
  tr {
    td {
      padding: 9px 10px 7px;
      border-right: 1px solid transparentize($color-black, .9);
      font-weight: 300;
      
      &:last-child {
        border-right: 0;
      }
    }
    
    &:nth-child(even) {
      background-color: $color-grey-lightest;
    }
  }
}

table {
  width: 100%;
  margin-bottom: 40px;
  border-collapse: collapse;
  border: 0 !important;
  text-align: center;
  tbody {
    tr {
      border: 0;
      &:nth-child(2n+1) {
        background-color: #f4f4f4;
        text-align: center;
       }
      //  &:last-child {
      //   background-color: #ffffff;
      //  }
      td {
        font-weight: 300;
        font-size: 1.4rem;
        border: 0;
        border-right: 3px solid #ffffff;
        padding: 10px 10px;
        text-align: center;
        p {
          font-size: 14px;
          padding: 10px 10px !important;
        }
      }
    }
  }
}
.table-wrap{
  @media only screen and (max-width:400px){
    width: 300px;
    margin: 0 auto;
    height: 500px;
  }
  @media only screen and (max-width:320px){
    width: 250px;
    margin: 0 auto;
    height: 500px;
  }
}
// table {
//   width: 100%;
//   margin:0;
//   padding:0;
//   border-collapse: collapse;
//   border-spacing: 0;
//   border: 10px;
//   table{
//     tr{
//       margin-bottom: 30px;
//       display: block;
//       td{
//         padding: 10px;
//         text-align: center;
//         border:none;
//         display: block;
//         text-align: right;
//         font-size: 13px;
//         &:nth-child(2n+1) {
//         background-color: #f4f4f4;
//          text-align: right;
//         }
//         ul{
//           width: 150px;
//           text-align: center;
//           margin-left: auto;
//         }
//         ol{
//           width: 150px;
//           text-align: left;
//           margin-left: auto;
//         }
//         p:before {
//           content: attr(data-label);
//           float: left;
//           text-transform: uppercase;
//           font-weight: bold;
//         }
//         &:last-child {
//           border-bottom: 0;
//         }
//       }
//     }
//   }
// }

.page-partners-inner {
  .partner-content {
    table {
      tr {
        td {
          text-align: left;
          padding-left: 20px;
        }
        td:nth-child(1) {
          width: 30%;
        }
        td:nth-child(2) {
          width: 70%;
        }
      }
    }
  }
}

