.btn {
  display: inline-block;
  position: relative;
  height: 5rem;
  padding: 0 2.5rem;
  transition: transform .2s ease, box-shadow .2s ease, background-color .2s ease, color .2s ease;
  border-radius: .5rem;
  font-size: 1.6rem;
  font-weight: 400;
  line-height: 4.9rem;
  text-align: center;
  text-decoration: none !important;
  text-transform: uppercase;
  cursor: pointer;
  vertical-align: top;
  z-index: 1;

  &:hover {
    transform: translateY(-2px);
  }

  &:active {
    transform: translateY(0);
    transition: transform 0s, box-shadow 0s;
    box-shadow: none !important;
  }

  &.btn-default {
    border: .1rem solid $color-red;
    background-color: transparent;
    color: $color-red;

    &:hover {
      background-color: $color-red;
      color: $color-white;
      box-shadow: 0 2px 0 0 $color-red-dark;
    }
  }

  &.btn-primary {
    border: .1rem solid $color-red;
    background-color: $color-red;
    color: $color-white;
    overflow: hidden;

    &:hover {
      background-color: $color-red-bright;
      box-shadow: 0 2px 0 0 $color-red-dark;
    }
    
    &.disabled {
      border-color: $color-grey;
      background-color: $color-grey;
      cursor: not-allowed;
      pointer-events: none;
      
      &:hover {
        transform: none;
        box-shadow: none;
      }
    }
  }

  &.btn-secondary {
    border: .1rem solid $color-red;
    background-color: transparent;
    color: $color-white;

    &:hover {
      background-color: $color-red;
      box-shadow: 0 2px 0 0 $color-red-dark;
    }
  }
  
  &.btn-blue {
    border: .1rem solid $color-blue3;
    background-color: $color-blue3;
    color: $color-white;

    &:hover {
      background-color: $color-blue-hover;
      box-shadow: 0 2px 0 0 $color-blue-shadow;
    }
  }

  &.btn-fb {
    border: .1rem solid $color-socials-facebook2;
    background-color: $color-socials-facebook2;
    color: $color-white;
    text-transform: none;

    &:hover {
      background-color: lighten($color-socials-facebook2, 8%);
      box-shadow: 0 2px 0 0 darken($color-socials-facebook2, 20%);
    }
  }

  &.btn-li {
    border: .1rem solid $color-socials-linkedin2;
    background-color: $color-socials-linkedin2;
    color: $color-white;
    text-transform: none;

    &:hover {
      background-color: lighten($color-socials-linkedin2, 8%);
      box-shadow: 0 2px 0 0 darken($color-socials-linkedin2, 20%);
    }
  }

  &.btn-tw {
    border: .1rem solid $color-socials-twitter2;
    background-color: $color-socials-twitter2;
    color: $color-white;
    text-transform: none;

    &:hover {
      background-color: lighten($color-socials-twitter2, 8%);
      box-shadow: 0 2px 0 0 darken($color-socials-twitter2, 20%);
    }
  }
}
