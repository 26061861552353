// MIXINS

// Font mixins
@mixin font-main {
  font-family: 'Roboto', sans-serif;
}

@mixin font-awesome {
  font: normal normal normal 14px/1 FontAwesome;
}

@mixin font-blockquote {
  font-family: Georgia, Times, 'Times New Roman', serif;
}


// Breakpoint
@mixin breakpoint($max: 0, $min: 0) {
  $type: type-of($max);
  
  @if $type == string {
    @if $max == xs {
      @media only screen and (max-width: 767px) { @content; }
    } @else if $max == sm {
      @media only screen and (min-width: 768px) { @content; }
    } @else if $max == md {
      @media only screen and (min-width: 992px) { @content; }
    } @else if $max == lg {
      @media only screen and (min-width: 1200px) { @content; }
    } @else {
      @warn'Breakpoint mixin supports: xs, sm, md, lg';
    }
  } @else if $type == number {
    $query: 'all' !default;
    @if $min != 0 and $max != 0 { 
      $query: 'only screen and (min-width: #{$min}) and (max-width: #{$max})'; 
    } @else if $min != 0 and $max == 0 { 
      $query: 'only screen and (min-width: #{$min})'; 
    } @else if $min == 0 and $max != 0 { 
      $query: 'only screen and (max-width: #{$max})'; 
    } @media #{$query} { 
      @content;
    }
  }
}

@mixin vertical-align {
  top: 50%;
  transform: translateY(-50%);
}

@mixin horizontal-align {
  left: 50%;
  transform: translateX(-50%);
}

@mixin total-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin clear {
  display: table;
  clear: both;
  content: '';
}

@mixin angle-double-right {
  position: relative;
  
  &::before,
  &::after {
    display: inline-block;
    position: absolute;
    top: 5px;
    width: 6px;
    height: 6px;
    transform: rotate(45deg);
    border: 1px solid $color-black;
    border-bottom: 0;
    border-left: 0;
    content: '';
  }
}

@mixin details-inner {
  .details {
    display: flex;
    align-items: center;
    margin-top: 20px;
    font-weight: 300;
    
    .fa {
      margin-left: 30px;
      font-weight: 300;
      
      &::before {
        padding-right: 3px;
        font-size: 1.7rem;
      }
    }
  }
}

@mixin modal-slide-from-top {
  transform: translate(-50%, -90%) scale(0);
  transform-origin: 50% 50%;
  transition: transform .5s cubic-bezier(.25, .5, .5, .9), opacity .5s cubic-bezier(.25, .5, .5, .9);
  opacity: 0;
}

@mixin gradient-blue-radial {
  background: $color-blue-bg radial-gradient(ellipse at center, $color-blue-gradient1 0%, $color-blue-gradient2 100%);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#{$color-blue-gradient1}, endColorstr=#{$color-blue-gradient2})';
}

@mixin gradient-blue-radial-header {
  background: radial-gradient(ellipse at center, $color-blue-gradient3 0%, transparent 50%, transparent 99%);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#{$color-blue-gradient3}, endColorstr=#0015487e)';
}

@mixin gradient-shadow-radial {
  background: radial-gradient(ellipse at center, transparentize($color-black, .8) 0%, transparent 55%, transparent 100%);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=1, startColorstr=#33000000, #00000000)';
}

@mixin gradient-grey-vertical-180 {
  background: linear-gradient(to bottom, transparent 0%, transparentize($color-black, .4) 100%);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#00000000, #66000000)';
}

@mixin gradient-grey-vertical {
  background: linear-gradient(to bottom, $color-black 0%, transparent 100%);
  -ms-filter: 'progid:DXImageTransform.Microsoft.gradient (GradientType=0, startColorstr=#FF000000, #00000000)';
}
