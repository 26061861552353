// .header-turnkey-solution{
//     position: absolute;
//     background-color: transparent;
//         display: block;
//     nav{
//         display: none !important; 
//     }
//     .form-search{
//         display: none;
//     }
//     .buttons{
//         display: none;
//     }
//     .menu-lang{
//         text-align: right;
//         li{
//              &:nth-of-type(2){
//                 display: none;
//             }
//             a{
//                 color: #D60100;
//                 font-size: 16px;
//                 line-height: 19px;
//                 font-weight: 400;
//             }
//             &::before{
//                 background: #1C2A4E;
//             }
//         }
//         @media only screen and (max-width:519px){
//             display: block !important;
//         }
//     }
// }
.header-turnkey-solution{
    padding: 20px 0;
   z-index: 100;
    position: absolute;
    background: transparent;
    top: 0;
    left: 0;
    right: 0;
    .header-block{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .header__logo{
            margin-right: 20px;
            a{
                img{
                    width: 230px;
                    height: 42px;
                }
            }
        }
        .leng{
            display: flex;
            li{
                .lang-change1{
                    display: block;
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: #D60100;
                    padding-right: 5px;
                    border-right: 2px solid #000;        
                    }
                .lang-change2{
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 19px;
                    color: #D60100;
                    padding-left: 5px;
                }
            }
        }
    }   
}
.slotegrator-turnkey-solution{ 
    &::before{
        display: none;
    }  
    .header-box{
        padding: 140px 0 100px;
        position: relative;
        // background: url(../images/slotegrator-turnkey-solution/background1.png) center center no-repeat / cover;
        &::before{
            content: '';
            position: absolute;
            background: url(../images/slotegrator-turnkey-solution/bg_top.png) center 70% no-repeat / cover;
            width: 1000px;
            height: 1060px;
            top: -20px;
            left: 0;
            @media only screen and (max-width:767px){
                top:-200px;
                left: -253px;
            }
            @media only screen and (max-width:767px){
                top: -280px;
            }
        }
        .wrap-box{
            margin-top: 50px;
            display: flex;
            flex-wrap: wrap;
        }
        &__left{
            text-align: left;
            color: #fff;
            width: 100%;
            max-width: 500px;
            padding-right: 40px;
            h1{
                margin-bottom: 60px;
                text-align: left;
                color: #fff;
                font-size: 34px;
                line-height: 48px;
                font-weight: 400;
            }
            p{
                color:#fff;
                font-weight: 300;
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 60px;
            } 
        }
        &__right{
            position: relative;
            width: 50%;
            height: 400px;
            .img {
               &::before {
                    content: '';
                    position: absolute;
                    top: -50px;
                    left: 100px;
                    bottom: 0;
                    right: 0;
                    width: 682px;
                    height: 472px;
                    background: url(../images/slotegrator-turnkey-solution/img.png) no-repeat;
                    background-size: cover;
               }
            }
        }
        @media only screen and (max-width: 1056px){
            &__left{
                margin-bottom: 200px;
                padding-left: 20px;
                padding-right: 0;
                h1{
                    font-size: 30px;
                    line-height: 48px;
                }
                p{
                    font-size: 16px;
                    line-height: 19px;
                }
            }
            &__right .img::before{
                left:280px;
            }
        }
        @media only screen and (max-width: 767px){
            &__right{
                width: 100%;
                .img::before{
                    left: 0;
                    background-size: 80%;
                }
            }
            &__left{ 
                margin: 0 auto 150px;
                padding-left: 0; 
                h1{
                    font-size: 24px;
                    line-height: 30px;
                    text-align: center;
                    font-weight: normal;
                }
                p{
                    font-size: 14px;
                    line-height: 19px;
                    text-align: center;
                }
            }
            .header-box__button{
                a{
                    margin: 0 auto;
                }
            }
        }
        @media only screen and (max-width: 469px){
            &__right{
                width: 100%;
                .img::before{
                    left: 0;
                    background-size: 60%;
                }
            }
        }
    }
    .header-box__button{
        a{
            text-align: center;
            padding: 0 10px;
            width: 100%;
            color: #fff;
            height: 57px;
            font-size: 18px;
            font-weight: 300;
            max-width: 267px;
            display: block;
            background-color: #D60100;
            line-height: 57px;
            border-radius: 5px;
            transform: scale(1);
            transition: .2s ease-in;
            &:hover{
                transform: scale(1.05);
                box-shadow: 1px 1px 50px 4px #D60100;
            }
        }
       
    }
    .header-card-container{
        width: 1700px;
        padding: 150px 10px 0;
        @media only screen and (max-width:1700px){
            padding: 150px 0 0;
        }
        @media only screen and (max-width:767px){
            padding: 0;
        }

    }
    .card-items{
        display: flex;
        justify-content: space-between;
        .card{
            height: 483px;
            color: #fff;
            width: 263px;
            text-align: center;
            background: rgba(255, 255, 255, 0.99);
            border: 1px solid #F0F0F0;
            box-sizing: border-box;
            box-shadow: 0px 10px 10px rgba(190, 212, 225, 0.43);
            border-radius: 10px;
            padding: 36px 15px;
            margin: 40px 15px;
            transform: scale(1);
            transition: .15s ease-in-out;
            img{
                margin: 0 auto;
           }
           h3{
            margin-bottom: 20px;
            font-size: 18px;
            height: 63px;
            line-height: 21px;
            font-weight: 300;
            color: #193272;
           }
           p{
            color:#454545;
            text-align: center;
            font-size: 13px;
            line-height: 18px;

           }
           &:hover{
            transform: scale(1.1);
            box-shadow: 0px 0px 5px rgba(220, 220, 220, 1);
        }
        }
        div.slick-list.draggable{
            padding: 0 15px !important;
        }
        .slick-dots li button:before{
            color: #D70F0F;
        }
        .slick-dots li.slick-active button:before{
            color: #D70F0F;
        }
        .slick-dots{
            bottom: -30px;
        }
        .slick-dots li button:before{
            font-size: 33px;
        }
    }
    .inform{
        .block-box{
            display: flex;
        }
        .block-inform h2{
            font-size: 34px;
            line-height: 48px;
            color: #454545; 
            text-transform: none;
            margin-bottom: 85px;
            font-weight: 400;
            @media only screen and (max-width: 1056px){
                font-size: 30px;
            }
            @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 30px;
            }
        }
        padding-top: 0;
        .block-box__left{
            width: 35.35%;
            .item{
                display: flex;
                justify-content: flex-end;
                align-items: center;
                margin-bottom: 50px;
                p{
                    font-size: 16px;
                    font-weight: 300;
                    margin-right: 23px;
                   
                }
            }
        }
        .block-box__middle{
            display: flex;
            align-items: center;
            width: 30%;
            img{
                margin: 0 auto;
            }
        }
        .block-box__right{
            
            .item{
                justify-content: flex-end;
                flex-direction: row-reverse;
                p{
                    margin-right: 0;
                    margin-left: 23px;

                }
            }
        }
        .block-box-mob{
            display: flex;
            justify-content: flex-end;
            .block__right{
                width: 50%;
                position: relative;
                &::before{
                    content:'';
                    position: absolute;
                    top: -5%;
                    left: -107%;
                    width: 343px;
                    height: 599px;
                    background: url(../images/slotegrator-turnkey-solution/shild_bg.png) no-repeat;
                }
                .item{
                    justify-content: flex-end;
                    display: flex;
                    align-items: center;
                    flex-direction: row-reverse;
                    margin-bottom: 45px;
                    p{
                        margin-left: 18px;
                    }
                }
            }
        }
        @media only screen and (min-width:1180px){
            .block-box-mob{
                display: none;
            }
        }
        @media only screen and (max-width:1180px){
            .block-box{
                display: none;
            }
        }
        @media only screen and (max-width:767px){
            .block-inform{
                h2{
                    margin-bottom: 60px;
                }
            }
            .block-box{
                display: flex;
                flex-direction: column;
                align-items: center;
                &__left{
                    width: 270px;
                    order: 1;
                    .item{
                        flex-direction: row-reverse;
                        p{
                            font-size: 14px;
                            margin-right: 0;
                            margin-left: 11px;
                        }
                    }
                }
                &__middle{
                    width:100%;
                    margin-bottom: 15px;
                    img{
                        max-width: 183px;
                    }
                }
                &__right{
                    order: 2;
                    .item{
                        p{
                            font-size: 14px;
                            margin-left: 11px;
                        }
                    }
                }
            }
            .block-box-mob{
                display: none;
            }

        }
    }
    .advantages{
        padding-top: 0;
        padding-bottom: 0;
        h2{
            color: #4D4D4D;
            font-size: 34px;
            line-height: 48px;
            text-align: center;
            text-transform: none;
            margin-bottom: 86px;
            font-weight: 400;
            @media only screen and (max-width: 1056px){
                font-size: 30px;
            }
            @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 30px;
            }
        }
        &__box{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .item_box{
                width: 100%;
                max-width: 207px;
                margin: 0 40px 30px;
                &:nth-child(4){
                    margin-left: 180px;
                }
                &:nth-child(5){
                    margin-right: 180px;
                }
                img{
                    margin: 0 auto;
                    margin-bottom: 45px;
                }
                h3{
                    height: 46px;
                    font-size: 18px;
                    font-weight: 300;
                    color: #193272;
                    text-align: center;
                    margin-bottom: 10px;
                }
                p{
                    text-align: center;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 18px;
                    color: #000;
                }
                @media only screen and (max-width: 917px){
                    &:nth-child(4){
                        margin-left: 40px;
                    }
                    &:nth-child(5){
                        margin-right: 40px;
                    }
                }
            }
            @media only screen and (max-width:767px){
                .item_box{
                    margin-bottom: 60px;
                }
            }
        }
    }

    .contacts{
        padding-top: 200px;
        background: url(../images/slotegrator-turnkey-solution/fot.png) 97% 23% no-repeat / cover;
        text-align: center;
        .block-box{
            color: #fff;
            h2{
                font-size: 34px;
                line-height: 48px;
                text-align: center;
                color: #fff;
                margin-bottom: 50px;
                text-transform: none;
                font-weight: 400;
                @media only screen and (max-width: 1056px){
                    font-size: 30px;
                }
                @media only screen and (max-width: 767px){
                    font-size: 24px;
                    line-height: 30px;
                }
            }
            p{
                font-size: 18px;
                line-height: 21px;
                font-weight: 300;
                margin-bottom: 30px;
                &:nth-of-type(2){
                    font-size: 24px;
                    font-weight: 400;
                    line-height: 48px;
                    margin-bottom: 60px;
                    @media only screen and (max-width: 767px){
                        font-size: 18px;
                    }
                }
                @media only screen and (max-width: 1056px){
                    font-size: 16px;
                }
                @media only screen and (max-width: 767px){
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            .header-box__button a{
                margin: 0 auto;
                text-transform: uppercase;
            }
        }
        @media only screen and (max-width:767px){

        }
    }
}
.slotegrator-turnkey-popup{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    .content{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .bg{
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0.7;
    }
    .block-form {
        margin: 0 15px;
        width: 510px;
        height: 460px;
        padding: 64px 50px;
        background: linear-gradient(198.09deg, #1C294C -2.4%, #163DA0 86.7%);
        border-radius: 43px;
        position: relative;
        @media only screen and (max-width:767px){
            width: 319px;
            background: linear-gradient(205.71deg, #1C294C -2.4%, #163DA0 86.7%);
            border-radius: 21px;
            padding-left: 18px;
            padding-right: 18px; 

        }
        h3{
            font-size: 20px;
            line-height: 26px;
            color: #fff;
            font-weight: 400;
            margin-bottom: 44px;
            text-align: center;
            @media only screen and (max-width:767px){
                font-size: 16px;
                line-height: 21px;
                font-weight: 400;
            }
        }
        input{
            width: 100%;
            font-size: 18px;
            border-radius: 5px;
            padding: 20px 12px;
            background: #fff;
            color: #193272;
            border: none;
            margin-bottom: 36px;
            &:last-of-type{
                margin-bottom: 50px;
            }
            &::placeholder{
                color: #193272;
                font-size: 18px;
                line-height: 21px;
                font-weight: 300;
            }
        }
        .header-box__button{
            a{
                text-align: center;
                margin: 0 auto;
                padding: 0 10px;
                width: 100%;
                color: #fff;
                height: 57px;
                font-size: 18px;
                font-weight: 300;
                max-width: 267px;
                display: block;
                background-color: #D60100;
                line-height: 57px;
                border-radius: 5px;
                transform: scale(1);
                transition: .2s ease-in;
                &:hover{
                    transform: scale(1.05);
                    box-shadow: 1px 1px 50px 4px #D60100;
                }
            }
           
        }
        .amsterdam-close{
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            top: 17px;
            right: 23px;
            cursor: pointer;
          }
          .amsterdam-close::after,
          .amsterdam-close::before{
            content: "";
            display: block;
            margin-top: 15px;
            width: 100%;
            border-bottom: 2px solid #f3eff5;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
          }
          .amsterdam-close::after{
            margin-top: -2px;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
          }
    }
}