.header_ice-london{
    background: url('../images/london_2019/bg1.jpg') no-repeat;
    background-size: cover;
    background-position: 60% 0;
    padding-top: 0;
    @media only screen and (max-width:767px){
        background-position: 40% center;
    }
    .header__wrap{
        width: 310px;
        height: 125px;
        margin: 0 auto;
        position: relative;
       
        // outline: 1px solid red !important;
        &::before{
            content:'';
            background: url('../images/london_2019/counter_bg.png') no-repeat;
            background-size: cover;
            background-position: center center;
            position: absolute;
            top: 0;
            left: -60px;
            bottom: 0;
            right: -60px;
        }
        .header__counter{
            padding: 10px 0px;
            position: relative;
            h3{
                text-align: center;
                color: #ffffff;
                font-size: 24px;
                font-weight: 300;
                width:100%;
            }
           

        }
    }
        
.container-center{
    text-align: center;
}
.main-content{
    display: inline-block;
    padding: 180px 0 250px;
    position: relative;
    &::before{
        content: '';
        position: absolute;
        top:-155px;
        left: -100px;
        right: -100px;
        bottom: 0;
        background: url(../images/london_2019/bg-middle.png) no-repeat;
        background-size: contain;
        background-position: center center;
    }
}
.main-content__ice{
    padding-bottom: 10px;
    span{
         color: #ffffff;
        font-size: 48px;
        &:nth-of-type(2){
            font-weight: 600;
        }
        &:nth-of-type(3){
            font-weight: 600;
            padding: 0 5px;
        }
    }
    img{
        display: inline-block;
    }
   
}
.main-content__place{
    color: #fff;
    font-size: 24px;
    padding-bottom:10px;
    font-weight: 600;
}
.main-content__stend{
    color: #fff;
    font-size: 24px;
    padding-bottom:20px;
    font-weight: 600;
}
.main-content__text span:first-child{
    color: #fff;
    font-size: 36px;
    font-weight: 600;
}
.main-content__box > span{
    color: #ffffff;
    font-size: 24px;
    font-weight: 300;
    display:inline-block;
    padding: 10px 20px;
    background-color: #D51116;
}
.main-content__box{
    position: relative;
}
.button-box{
    .btn__button2,
    .__buttonn,
    .__button{
        position: relative;
        display: inline-block;
        margin:0 5px;
        svg{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        span{
            display: inline-block;
            padding:20px 50px;
            position: relative;
            color: #ffffff;
            font-family: Roboto Condensed, sans-serif;
            font-size: 18px;
            font-weight: 600;
        }
    }
}

}
.__buttonn, .__button{
    background-color: transparent;
}
.button-box-left{
    .__buttonn{
        position: relative;
        display: inline-block;
        margin:0 5px;
        svg{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
        span{
            display: inline-block;
            padding:20px 50px;
            position: relative;
            color: #ffffff;
            font-family: Roboto Condensed, sans-serif;
            font-size: 18px;
            font-weight: 600;
        }
    }
}
.exhibition-ice{
    padding: 100px 0;
}
.exhibition-ice__box{
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 767px){
        display:block;
    }   
}
.clearfixx{
    &::before{
        content: "";
        clear: both;
        display: table;
    }
}
.exhibition-ice__left{
    float: left;
    width: 60%;
    @media only screen and (max-width:1199px){
        width: 100%;
    }
    .exhibition-ice__title{
        position: relative;
        display: inline-block;
        margin-bottom: 45px;
        svg{
            position: absolute;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            width: 100%;
            height: 100%;
        }
        span{
            display: inline-block;
            padding: 30px 50px 20px 20px;
            position: relative;
            font-family: Roboto;
            font-size: 40px;
            color:#ffffff;
            font-weight: 300;
            @media only screen and (max-width:767px){
                font-size: 36px;
            }
        }
    }
    .exhibition-ice__text{
        float: left;
        p{
            padding-bottom: 15px;
            line-height: 1.2;
        }
        font-size: 18px;
        ul{
            font-weight: 300;
            list-style-type: disc;
            padding-left:30px;
            padding-bottom: 15px;
            li{
                font-weight: 300;
               font-size: 18px;
            }
        }
    }
    // @media only screen and (max-width: 1000px){
    //     width: 100%;   
    // }
}

.exhibition-ice__right{
    width: 40%;
    padding-top: 120px;
    .__button{
        position:relative;
        display: inline-block;
        span{
            display: inline-block;
            padding: 20px 50px;
            position: relative;
            font-family: Roboto Condensed, sans-serif;
            color: #ffffff;
            font-size: 18px;
            font-weight: 600;
        }
        svg{
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    @media only screen and (max-width:1199px){
        display: none;
    }
    @media only screen and (max-width:767px){
        display: block;
        margin: 0 auto;
        width:100%;
    }
}
.exhibition-ice__ticket{
    img{
        margin: 0 auto;
    }
}
.exhibition-ice__right__inside{
    float: right;
    padding-top: 0;
    display: none;
    @media only screen and (max-width:1199px){
        display: block;
    }
    @media only screen and (max-width:767px){
        display: none;
    }
}
.exhibition-ice__button{
    text-align: center;
}
.programs-box{
    display: flex;
    justify-content: space-between;
    padding: 50px 0;
}
.programs-box__left{
    width: 260px;
    margin-top: 150px;
    margin-right: 160px;
    margin-left: auto;
    position: relative;
    @media only screen and (max-width: 1200px){
        margin-top: 200px;
    }
    @media only screen and (max-width: 767px){
        display: none;
        margin-top: 200px;
    }
}
.programs-box__bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(transparent,transparent,transparent,transparent, rgba(255, 255, 255, .1), rgba(255, 255, 255, .1), rgba(255, 255, 255, 1), #ffffff);  
}
.programs-box__right{
    padding-top: 60px;
    @media only screen and (max-width: 767px){
        width: 100%;
    }
}
.programs-box__title{
    position: relative;
    display: inline-block;
    margin-bottom: 95px;
    width:100%;
    max-width:680px;
    svg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
    span{
        display: inline-block;
        padding: 20px 30px 20px 50px;
        position: relative;
        font-family: Roboto;
        font-size: 40px;
        color:#ffffff;
        font-weight: 300;
        @media only screen and (max-width:767px){
            font-size: 36px;
        }
    }
}
.programs-day{
    li{
        display: flex;
        align-items: center;
        padding-bottom: 60px;
        @media only screen and (max-width: 767px){
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            text-align: right;
        }
        .description{
            max-width:400px;
            width:100%;
            text-align: right;
            display: inline-block;
            padding-right:40px;
            // padding-bottom: 20px;
            font-size: 18px;
            font-weight: 300;
            @media only screen and (max-width: 767px){
               padding-right: 0;
               order: 1; 
            }
        }
        .time{
            color:#5F2383;
            font-size: 48px;
            font-weight: 600;
        }
    }    
}

.map-ice__title{
    position: relative;
    display: inline-block;
    margin-bottom: 45px;
    width:100%;
    max-width:680px;
    svg{
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        padding: 0 15px 0 10px;
    }
    span{
        display: inline-block;
        padding: 20px 30px 20px 50px;
        position: relative;
        font-family: Roboto;
        font-size: 40px;
        color:#ffffff;
        font-weight: 300;
        @media only screen and (max-width: 767px){
            font-size: 36px;
         }
    }
}

.map-ice__box{
    padding: 50px 0 100px;
    .map-ice__title{
        margin-bottom: 100px;
    }
}
.card-map__title{
    margin-bottom: -95px;
    max-width:680px;
        width: 100%;
}
.card-map__wrap-title{
    position: relative;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
    text-align: right;
    z-index: 1;
}
#map{
    z-index: 0;
    overflow-y: hidden;
    position: relative;
    width: 100%;
    height: 500px;
}
.map-ice__map{
    position: relative;
    padding-right: 100px;
    button{
        &::after{
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -45px;
        bottom: 0;
        right: 0;
        background: url('../images/london_2019/button.png') no-repeat;
        background-size: cover;
        cursor: pointer;
        width: 90px;
        height: 90px;
        }
    } 
}
.bg-map{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,.8);
    z-index: 999;
    display: none;
        .bg-map__img{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100vh;
            img{            
                max-width: 1200px;
                margin: 0 40px;
                width: 100%;
                max-height: 700px;
                text-align: center;
            }
        }
}
.bg-form{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
    display: none;
    .bg-form-wrap{
        background-color: rgba(0,0,0,.8);
        justify-content: center;
        align-items: center;
        display: flex;
        width: 100%;
        height: 100%;
    }
    .registration-ice__right{
        max-width: 490px;
        width:100%;
        display: flex;
        justify-content: center;
        form{
            width: 340px;
            text-align: center;
        }
    }
}

.registration-ice{
    padding: 130px 0 12px;
}
.registration-ice__wrap{
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1199px){
        flex-direction: column;
        padding-top: 120px;
    }
    // @media only screen and (max-width: 767px){
        
    // }
    .registration-ice__right{
        max-width: 440px;
        width:100%;
        display: flex;
        justify-content: center;
        @media only screen and (max-width: 1199px){
            order:1;
            margin: 0 auto;
            margin-top: 65px;
        }
        form{
            width: 340px;
            text-align: center;
        }
    }
}
.registration-ice__left{
    max-width: 680px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 1199px){
        // order:11;
        margin: 0 auto;
        height: 615px;
        // overflow: hidden;
    }
    @media only screen and (max-width: 767px){
        
    }
    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('../images/london_2019/bgg_left.png') 
        no-repeat;
        background-size: 100% 100%;
        @media only screen and (max-width: 1199px){
            top: -40px;
            background-color: #5f2383;
        }
    }
    .registration__title{
        position: absolute;
        top: -45px;
        left: -45px;
        padding-right: 50px;
        max-width: 680px;
        width:100%;
        @media only screen and (max-width: 1300px){
            left: 10px;
        }
        @media only screen and (max-width: 1199px){
            top:-190px;
        }
        // @media only screen and (max-width: 767px){
        //     top:-800px;
        // }

    }
    .registration-ice__info{
        position: relative;
        padding-left: 280px;
        max-width: 680px;
        padding-right: 20px;
        @media only screen and (max-width: 1199px){
           padding-top: 80px;
        }
        @media only screen and (max-width: 767px){
            margin: 0 auto;
            padding-left: 0;
            max-width:400px;
            padding: 0 15px;
        }
        &::before{
            content: '';
            position: absolute;
            top: 70px;
            left: -70px;
            right: 0;
            bottom: 0;
            background: url('../images/london_2019/Niko.png') 
            no-repeat;
            background-size: 100% 100%;
            width: 427px;
            height: 620px;
            z-index: 0;
            @media only screen and (max-width: 1199px){
                background-size: 100% auto;
                top: 96px;
                left: -40px;
                width: 358px;
                height: 540px;
            }
            @media only screen and (max-width: 767px){
                margin: 0 auto;
                top: 273px;
                left:-45px;
                width:285px;
                height: 410px;
                // padding-left: 0;
                // max-width:400px;
            }
            @media only screen and (max-width: 440px){
                margin: 0 auto;
                top: 300px;
                left:0px;
                // padding-left: 0;
                // max-width:400px;
            }
        }
        h3{
            font-weight: 900;
            font-size: 60px;
            color: #ffffff;
            padding-bottom: 20px;
            line-height: 1.2;
            padding-top: 70px;
            @media only screen and (max-width: 1199px){
                margin: 0 auto;
                font-size: 48px;
                padding-top: 0;
                padding-bottom: 50px;
                top: 200px;
            }
            @media only screen and (max-width: 767px){
                margin: 0 auto;
                padding-top: 0;
                top: 200px;
                font-size: 36px;
                // padding-left: 0;
                // max-width:400px;
            }
        }
        p{
            position: relative;
            z-index: 1;
            font-size: 18px;
            max-width:440px;
            color: #ffffff;
            padding-left: 45px;
            @media only screen and (max-width: 767px){
                padding-left: 0;
            }
        }    
    }
}
.container-registr-ice{
    @media only screen and (max-width: 1179px){
        padding: 0;
    }
}
.registration-ice__middle.registration-ice__middle.registration-ice__middle{
    @media only screen and (max-width: 690px){
        background: none;
    }
}
.registration-ice__right{
    position: relative;
    @media only screen and (max-width: 690px){
        background: #5f2383;
    }
    &::before{
        content:'';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: url('../images/london_2019/form-bg.png') no-repeat;
        background-size: 100% 100%;
    }
    .mfp-close{
        display: block;
        font-size: 60px;
        position: absolute;
        top: 40px;
        right: 20px;
        @media only screen and (max-width: 325px){
            top: 55px;
        }
    }
    form{
        position: relative;
        h3{
            color: #fff;
            font-size: 34px;
            padding-bottom: 10px;
            @media only screen and (max-width: 330px){
                font-size: 30px;
            }
        }
        input{
            border: none;
            border-bottom: 1px solid #ffffff;
            border-radius: 0px;
        }
        .button-box{
            text-align: center;
            .__button{
                position: relative;
                display: inline-block;
                margin:0 5px;
                svg{
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                }
                span{
                    display: inline-block;
                    padding:20px 50px;
                    position: relative;
                    color: #ffffff;
                    font-family: Roboto Condensed, sans-serif;
                    font-size: 18px;
                    font-weight: 600;
                }
            }
        }
    }
    .form {
        display: inline-block;
        padding: 25px;
        width: 340px;
        padding: 100px 20px 80px;
    }
    
    
    .field-form-text{
        margin-top: 40px;
        textarea{
            font-size: 18px;
            resize: none;
            height: 200px;
        }
    }
    .form__error {
        color: red;
        text-align: left;
        font-size: 12px;
        display: block;
        margin-top: 3px;
        display: none;
    }
    
    .form input {
        outline: none;
        display: block;
        width: 100%;
        border: none;
        background: none;
        font-size: 18px;
        font-weight: 300;
        font-family: 'Roboto', sans-serif;
        border-bottom: 1px solid #fff;
        padding: 10px 20px 10px 20px;
        color: #fff;
        box-sizing: border-box;
        &::placeholder{
            color: #fff;
        }
    }
    
    // .form button {
    //     width: 100%;
    //     padding: 10px;
    //     border-radius: 2px;
    //     border: 0;
    //     background-color: #ccc;
    //     color: #fff;
    // }
    
    input:invalid:not(:placeholder-shown) {
        border-color: red;
    }
    input:invalid:not(:placeholder-shown) + .form__error {
        display: block;
    }
}


body{
    .footer_ice{
        // background: url('../images/london_2019/bg_footer.jpg') no-repeat;
        background-color: #2C58AD;
        background-size: cover;
        background-position: center center;
        .footer_transparency{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background: rgba(255,255,255, .15);
            // background: rgba(255,255,, .15);
        }
        // @media only screen and (max-width:799px){
        //     padding-top: 140px;
        // }
    }
    
}
   