// Nav

nav {
  transition: height .3s ease;
  font-size: 1.6rem;
  text-align: center;

  ul {



    >li {
      display: inline-block;
      position: relative;
      margin: 10px;
      @media only screen and (max-width: 1150px) {
        margin: 7.5px;
      }
      a {
        transition: color .2s ease;
        color: $color-white-80;
        font-weight: 300;

        &:hover {
          color: $color-red;
        }
      }

      &:hover {
        ul {
          top: 69px;
          opacity: 1;
          visibility: visible;

          &::after {
            height: 49px;
          }
        }
      }
    }

    li:nth-child(2) {
      ul:not(.doubleList) {
        li:nth-child(1) a:hover {
          color: #2489ff;
        }
        li:nth-child(2) a:hover {
          color: #12b59c;
        }
      }
    }




    ul {
      @include horizontal-align;
      position: absolute;
      top: 30px;
      width: 200px;
      padding: 5px 25px;
      transition: opacity .3s ease, top .3s ease;
      border-radius: 10px;
      background-color: $color-black-95;
      font-size: 1.4rem;
      opacity: 0;
      visibility: hidden;
      z-index: 50;

      &.doubleList{
        display: flex;
        width:440px;
        justify-content: space-between;

        li{
          &:first-child{

            padding-right: 40px;
          }
          min-width: 160px;
          flex-shrink: 1;

          &:last-child{

            &.doubleList-providers{
              padding-left: 19px;
              //min-width: 161px;
              opacity: .8;
              border-left: 1px solid #333;
              font-size: 13px;
              div p:last-child{
                font-size: 13px;
                position: relative;
                margin-top: 10px;
                padding-top: 10px;
                &:after{
                  content: '';
                  position: absolute;
                  right:0;
                  top:0;
                  width:100%;
                  height:1px;
                  background: #333;
                }
              }

              @media screen and (max-width:1130px ) {
                border-left: none;
                padding-left:0;
                div p:last-child{
                  &:after{
                    display: none;
                  }
                }
              }
            }

          }

          div :nth-child(1) a{
            color: #2489ff;
            font-weight: 400;
            font-size: 14px;
          }
        }



        @media only screen and (max-width: 1130px){
          flex-wrap: wrap;
          li:first-child, li.doubleList-providers{
            width:100%;
            min-width: 100%;
            padding:0;
            border: none;
            opacity: 1;
          }
        }
      }

      &::before {
        position: absolute;
        top: -10px;
        right: 0;
        left: 0;
        width: 0;
        height: 0;
        margin: auto;
        border-width: 0 10px 10px;
        border-style: solid;
        border-color: transparent transparent $color-black-90;
        content: '';
      }

      &::after {
        position: absolute;
        bottom: 100%;
        left: 0;
        width: 100%;
        height: 10px;
        content: '';
        z-index: -1;
      }

      li {
        display: block;
        margin: 5px 0;
        padding: 0;
        text-align: left;
        @media only screen and (min-width: 990px) {
          a[href="https://slotegrator.com/uslugi/platezhnye-sistemy.html"]:hover,
          a[href="https://slotegrator.com/servicios/sistemas-de-pago.html"]:hover,
          a[href="https://slotegrator.com/services/payment-systems.html"]:hover {
            color: #12b59c;
          }
          a[href="https://slotegrator.com/uslugi/protokol_dlya_casino.html"]:hover,
          a[href="https://slotegrator.com/servicios/protocolo_para_casino_online.html"]:hover,
          a[href="https://slotegrator.com/services/protocol_casino.html"]:hover {
            color: #2489ff;
          }

        }
      }

      a {
        display: block;
        padding: 5px 0;
        color: $color-white;
        font-weight: 300;

        &:hover {
          color: $color-red-bright;
          font-weight: 400;

          &.doubleList-apigrator{
            color: #2489ff;
          }

          &.doubleList-moneygrator{
            color: #12b59c;
          }
        }
      }
    }

    a {
      color: $color-white;
      text-decoration: none;
    }

    .active {
      a {
        color: $color-red;
      }
    }
  }

  @media only screen and (max-width: 1099px) {
    ul {
      >li {
        margin: 10px 6px;
      }
    }
  }

  @media only screen and (max-width: 1130px) {
    position: fixed;
    top: 0px;
    right: -430px;
    width: 430px;
    max-width: 100%;
    height: 2500px;
    padding: 20px;
    transition: all .7s ease;
    background-color: $color-white;
    text-align: left;
    z-index: 1001;

    >ul {
      height: 100%;
      overflow-y: auto;
      
      display: flex;
      flex-wrap: wrap;
      >li {
        margin-bottom: 10px;
        padding-bottom: 10px;
        padding-left: 9px;
        border-bottom: 1px solid transparentize($color-grey-dark, .8);

        flex-basis: 100%;
        &:last-of-type:not(.igbLink),&.contactsIgbLive {
          margin-bottom: 0;
          border-bottom: 0;
        }

        &.igbLink{
          order: -1;
        }
      }
    }

    ul {
      >li {
        display: block;

        a {
          color: $color-grey-darker;
          font-size: 22px;
          font-weight: 400;
          text-transform: uppercase;
        }

        ul {
          position: relative;
          top: 0 !important;
          left: 0;
          width: 100%;
          padding: 0;
          transform: none;
          border-radius: 0;
          background-color: transparent;
          opacity: 1;
          visibility: visible;


          &::before,
          &::after {
            display: none;
          }

          li {
            a {
              color: $color-grey-darker;
              font-size: 16px;
              font-weight: 300;
              text-transform: none;
            }
          }
        }
      }
    }

    &.open {
      right: 0;
    }
    ul:nth-child(1) li:nth-child(2) ul li:nth-child(1) a {
      //color: #2489ff;
    }
    ul:nth-child(1) li:nth-child(2) ul li:nth-child(2) a {
      //color: #12b59c;
    }
  }

  @media only screen and (max-width: 879px) {
    top: 37px;
    padding-top: 10px;

    &.open {
      + {
        .form-search {
          right: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 619px) {
    >ul {
      >li {
        margin-right: 0;
        margin-left: 0;
      }
    }
  }
}


