// Close button
%close-before-after {
  position: absolute;
  top: calc(50% - 2px);
  left: -5%;
  width: 110%;
  height: 4px;
  background-color: $color-white;
  content: '';
}

.close {
  position: absolute;
  top: 0;
  left: 0;
  width: 40px;
  height: 40px;
  transition: transform .2s ease;
  background-color: transparent;

  &::before {
    @extend %close-before-after;
    transform: rotate(45deg);
  }

  &::after {
    @extend %close-before-after;
    transform: rotate(-45deg);
  }

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(1);
    transition: transform 0s;
  }
}

// Popups
.modal-wrapper {
  position: fixed;
  display: block;
  padding-bottom: 70px;
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  transition: opacity .5s ease;
  background-color: $color-blue-dark;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
  perspective: 1300px;
  backface-visibility: hidden;
  .modal-consultation {
    &::-webkit-scrollbar {
      width:0px;
    }
    h2 {
      text-align: center;
    }
  }

  &.displayed {
    opacity: 1;
    visibility: visible;
    display: block;
    overflow-y: auto;
	  -webkit-overflow-scrolling:touch;
  }
}

.modal {
  @include modal-slide-from-top;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 750px;
  max-height: calc(50% - 20px);
  padding: 50px 55px;
  color: $color-white-80;
  text-align: center;
  visibility: hidden;
  overflow-y: auto;
  z-index: 1111;
  .modal-consultation-title{
    margin-bottom: 1rem;
    color: rgba(255,255,255,.8);
    font-size: 2.2rem;
    font-weight: 300;
    display: block;
    max-width: 770px;
    margin: 0 auto 50px;
    text-transform: uppercase;
  }
  h2 {
    margin-bottom: 1rem;
    color: $color-white-80;
  }

  form {
    margin-top: 3rem;

    .col-01 {
      display: block;
      width: 56%;
      float: left;
    }

    .col-02 {
      display: block;
      width: 9%;
      padding-top: 76px;
      float: left;
      text-align: center;

      %before-after2 {
        position: absolute;
        right: 0;
        left: 0;
        width: 1px;
        height: 30px;
        margin: auto;
        background-color: $color-white-80;
        content: '';
      }

      span {
        position: relative;
        font-size: 1.6rem;

        &::before {
          @extend %before-after2;
          bottom: 120%;
        }

        &::after {
          @extend %before-after2;
          top: 120%;
        }
      }
    }

    .col-03 {
      display: block;
      width: 35%;
      float: right;

      .btn {
        width: 100%;
        margin-bottom: 1rem;
      }
    }

    .input {
      &-textarea {
        clear: both;
      }

      &-recaptcha {
        width: 220px;
        float: left;

        >div {
          >div {
            >div {
              width: 302px;
            }
          }
        }

        &.has-error {
          .error-message {
            display: none;
          }
        }
      }

      .error-message {
        color: $color-red2;
      }
    }

    .btn-primary {
      width: auto;
      padding: 0 2.5rem;
      height: 5rem;
      line-height: 4.9rem;
      float: right;
      text-overflow: ellipsis;
      overflow: hidden;
      min-width: 200px;
    }
  }

  &.displayed {
    transform: translateY(-50%) translateX(-50%);
    opacity: 1;
    visibility: visible;
    display: block;
  }

  &.modal-video {
    max-width: 1250px;

    &::before {
      display: block;
      position: relative;
      padding-top: 56.14%;
      content: '';
    }

    iframe {
      display: block;
      position: absolute;
      top: 50px;
      left: 55px;
      width: calc(100% - 110px);
      height: calc(100% - 100px);
    }
  }

  @media only screen and (max-width: 659px) {
    padding: 65px 10px 30px;

    .close {
      left: 8px;
    }

    &.modal-video {
      iframe {
        top: 65px;
        left: 10px;
        width: calc(100% - 20px);
        height: calc(100% - 95px);
      }
    }
  }

  @media only screen and (max-width: 569px) {
    form {
      .btn-primary {
        padding: 0 1.2rem;
      }
    }
  }

  @media only screen and (max-width: 600px) {
    form {
      .input {
        &.input-recaptcha {
          display: block;
          width: 203px;
          margin: 0 auto;
          float: none;
          transition: max-height .5s ease, margin .5s ease;

          >div {
            >div {
              >div {
                width: 302px;
                height: 0;
              }
            }
          }

          &.error,
          &.has-success {
            width: 203px;

            .error-message {
              display: none;
              width: 100%;
              text-align: center;
              white-space: normal;
            }

            >div {
              >div {
                >div {
                  width: 302px;
                  height: 0;
                }
              }
            }
          }
        }
      }

      .btn {
        width: 100%;
        margin: 0 auto;
        float: none;
        clear: both;
      }

      &.focused {
        .input {
          width: 100%;

          &.input-recaptcha {
            width: 203px;
            max-height: 200px;
            margin: 0 auto 1rem;

            >div {
              >div {
                >div {
                  width: 304px;
                  height: 77px;
                }
              }
            }

            &.error,
            &.has-success {
              .error-message {
                display: block;
              }
              
              >div {
                >div {
                  >div {
                    height: 77px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.wrapper-modal-dynamic {
  button {
    border: 0;
    outline: none;
    cursor: pointer;

    &.btn {
      width: 100%;
      padding: 12px 20px;
      border-radius: .6rem;
      outline: none;
      font-size: inherit;
      line-height: 1.2;
      white-space: nowrap;
    }
  }

  .container {
    position: relative;
    width: 90%;
    margin: auto;
  }

  .modal {
    position: absolute;
    max-width: 860px;
    background: linear-gradient(180deg, transparent, transparent 10%, $color-white 20%, $color-white);
    color: $color-grey-darker;
    overflow: auto;

    &.displayed {
      padding: 0;
    }
  }

  .close {
    top: 10px;
    right: 10px;
    width: 25px;
    height: 25px;
    z-index: 10;

    &::before,
    &::after {
      left: -4%;
      height: 3px;
    }
  }

  .banner {
    max-height: 350px;
    overflow: hidden;

    img {
      display: block;
      position: relative;
      left: 0%;
      width: 100%;
      height: auto;
    }
  }

  .form {
    padding-top: 20px;
    background-color: $color-white;

    p {
      width: 90%;
      margin: 0 auto 10px;
      font-family: 'Roboto', sans-serif;
      font-size: 21px;
      text-align: center;
    }

    input {
      width: 100%;
      padding: 12.5px;
      border: 1px solid $color-grey4;
      border-radius: .6rem;
      outline: none;
      font: inherit;
      font-style: italic;
      line-height: 1.3;
    }

    .btn-primary {
      height: auto;
      font-weight: 500;
    }
  }

  .form-control {
    width: 90%;
    margin: auto;
    margin-bottom: 10px;
    transition: width .5s ease, margin .5s ease;
  }

  .has-error {
    input {
      border-color: transparentize($color-red, .2);
    }
  }

  .help-block {
    margin-top: .4rem;
    color: $color-red-dark;
    font-size: 12px;
    text-align: left;
  }

  .captcha {
    width: 182px;

    .has-error {
      .help-block {
        transition: none;
        overflow: hidden;
      }
    }
  }

  .focused {
    .captcha {
      .field-form-recaptcha {
        max-height: 44px;
      }

      .has-error {
        max-height: 200px;

        .help-block {
          margin-top: 0;
          transition: .3s all .3s;
          opacity: 1;
        }
      }
    }
  }

  form {
    margin-top: 0;

    .captcha {
      .field-form-recaptcha {
        display: block;
        width: 100%;
        max-height: 0;
        transition: max-height .3s;
        opacity: 1;
      }

      >div {
        >div {
          >div {
            height: 75px;
            transform: scale(.6);
            transform-origin: 0 0;
            transition: all .5s ease;
            border-radius: .8rem;
            overflow: hidden;
          }
        }
      }
    }
  }

  &.modal-wrapper {
    background: $color-blue-dark;
    font-size: 15px;

    &.displayed {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  &.displayed {
    ~ div {
      &#jivo-iframe-container {
        &.jivo-iframe-container-bottom {
          bottom: inherit !important;
        }
      }
    }

    ~ jdiv {
      display: none !important;
    }
  }

  @media only screen and (min-width: 480px) {
    .modal {
      overflow: visible;
    }

    .container {
      width: 460px;
    }

    .close {
      top: -45px;
      left: 0;
      width: 40px;
      height: 40px;

      &::before {
        left: -5%;
        height: 4px;
      }

      &::after {
        left: -5%;
        height: 4px;
      }
    }

    .banner {
      img {
        left: 0;
        width: 100%;
      }
    }

    form {
      display: flex;
      flex-flow: row wrap;
      align-items: center;
      justify-content: space-between;
      margin: auto;
      margin-top: 1rem;

      .captcha {
        width: 44%;
        margin-left: 5%;

        .field-form-recaptcha {
          max-height: 44px;

          >div {
            >div {
              >div {
                width: auto;
                height: auto;
              }
            }
          }
        }
      }
    }


    .submit {
      width: 44%;
      margin: 0 5% auto auto;
    }
  }

  @media only screen and (min-width: 600px) {
    .close {
      top: -70px;
    }
  }

  @media only screen and (min-width: 850px) {
    .container {
      width: 750px;
    }

    .close {
      left: -50px;
    }

    .form {
      padding-top: 35px;
      padding-bottom: 35px;

      p {
        margin: 0 auto 35px;
      }
    }

    form {
      display: block;
      width: 95%;

      .captcha {
        position: relative;
        width: 0;
        height: 50px;
        margin: 0 0 20px;
        padding: 5px 0;

        .field-form-recaptcha {
          >div {
            >div {
              >div {
                width: 0;
                overflow: hidden;
              }
            }
          }
        }

        .has-error {
          .help-block {
            display: block;
            opacity: 0;
          }
        }
      }
    }

    .form-control {
      display: inline-block;
      width: 30%;
      padding: 5px;
      vertical-align: top;
    }

    .focused {
      .field-form-name,
      .field-form-email {
        width: 20%;
      }

      .captcha {
        width: 25.5%;
        margin: 0 auto 20px;

        .field-form-recaptcha {
          >div {
            >div {
              >div {
                width: 304px;
              }
            }
          }
        }
      }
    }

    .submit {
      margin: auto;
      margin-bottom: 20px;
    }
  }

  @media only screen and (orientation: landscape) and (max-height: 640px) {
    .modal {
      overflow: auto;
    }

    .close {
      top: 10px;
      left: 10px;
    }
  }
}

div {
  &#jivo-iframe-container {
    @media only screen and (max-width: 659px), only screen and (orientation: landscape) and (max-height: 640px) {
      &.modal-displayed {
        bottom: inherit !important;

        ~ jdiv {
          display: none !important;
        }
      }
    }
  }
}
._show_1e.wrap_mW {
  z-index: 3 !important;
}