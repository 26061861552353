//--------------------------------
//RGW 2019
//--------------------------------

.rgw-inner-page {
  .rgw-header {
      background: url('../images/rgw_2019/header.png') no-repeat;
      background-size: cover;
      position: relative;
      padding-top: 0;
      padding-bottom: 100px;
      background-position: 50% 80%;
    .rgw-container{
      padding-top: 178px;
      @media only screen and (max-width: 768px){
        padding-top: 100px;
      }
      // padding-bottom: 165px;
      &::after{
        content: '';
        background: url('../images/rgw_2019/header_img.png') no-repeat;
        z-index: 0;
        background-size: cover;
        width: 574px;
        height: 1021px;
        position: absolute;
        top: 0px;
        right: -80px;
        @media only screen and (max-width:1140px){
          right: 50%;
          margin-right: -300px; 
          top: -150px;
          opacity: 0.6;
        }
        @media only screen and (max-width:768px){
          width: 480px;
          height: 800px;
          opacity: 0.4;
          margin-right: -240px;
        }
      }
      .rgw-offer {
        color: #ffffff;
        width: 100%;
        max-width: 870px;
        text-align: left;
        z-index: 1;
        position: relative;
        @media only screen and (max-width:1140px){
          // padding-top:400px;
          margin: 0 auto;
        }
        @media only screen and (max-width:768px){
          font-size: 50px;
          // padding-top: 270px;
        }
        &__title {
          font-size: 60px;
          font-weight: 400;
          color: #ffffff;
          text-align: left;
          max-width: 870px;
          margin: 0;
          text-transform: none;
          margin-bottom: 20px;
          @media only screen and (max-width:768px){
            font-size: 50px;
          }
          @media only screen and (max-width:500px){
            font-size: 33px;
            text-align: center;
          }
          span{
            font-weight: 700;
          }
        }
        &__info {
          font-size: 32px;
          font-weight: 500;
          margin-bottom: 20px;
          @media only screen and (max-width:768px){
            font-size: 21px;
          }
          @media only screen and (max-width:500px){
            font-size: 18px;
            text-align: center;
          }
          span{
            font-weight: 700;
            font-size: 42px;
            @media only screen and (max-width:768px){
              font-size: 28px;
            }
            @media only screen and (max-width:500px){
              font-size: 21px;
            }
          }
        }
        &__stend{
          font-size: 50px;
          font-weight: 700;
          margin-bottom: 20px;
            @media only screen and (max-width:768px){
            font-size: 40px;
          }
          @media only screen and (max-width:500px){
            font-size: 33px;
            text-align: center;
          }
        }
        .rgw-counter{
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          max-width:460px;
          p{
            font-size: 31px;
            font-weight: 700;
            @media only screen and (max-width:768px){
              font-size: 33px;
            }
            @media only screen and (max-width:500px){
              font-size: 22px;
              text-align: center;
            }
          }
          .clock{
            width: 100%;
            margin-right: 0;
            .flip-clock-divider{
              width: 20px;
              height: 136px;
              &:first-child{
                width: 0;
              }
            }
            ul{
              width: 50px;
              height: 70px;
              @media only screen and (max-width:500px){
                width: 35px;
                height: 50px;
              }
              li{
                line-height: 70px;
                @media only screen and (max-width:500px){
                  line-height: 50px;
                }
                a div div.inn{
                  font-size: 60px;
                  @media only screen and (max-width:500px){
                    font-size: 40px;
                  }
                }
              }
            }
          }
          .rgw__button{
            width: 100%;
            max-width: 270px;
          }
        }
      }
      .arrow{
        padding: 50px 0;
        text-align: center;
        display: flex;
        justify-content: center;
        cursor: pointer;
      }
    }
  }

.rgw-info{
  background: url('../images/rgw_2019/bg_middle.png') no-repeat;
  background-size: cover;
  background-position: center center;
  @media only screen and (max-width:659px){
    padding: 100px 0;
  }
  &__title{
    h2{
      color: #ffffff;
      font-weight: 700;
      font-size: 50px;
      max-width: 100%;
      text-transform: none;
      @media only screen and (max-width:768px){
        font-size: 40px;
      }
      @media only screen and (max-width:500px){
        font-size: 30px;
        text-align: center;
      }
    }
  }
  .rgw-info__block-items{
    margin-bottom: 60px;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    .item{
      width:100%;
      margin: 20px 5px;
      max-width: 370px;
      display: flex;
      flex-direction: column;
      text-align: center;
      background-image: linear-gradient(27deg, #d00146 0%, #2a0756 100%);
      padding: 30px 15px;
      border-radius: 5px;
      opacity: .9;
      &__img{
        width: 120px;
        height: 120px; 
        display: block;
        align-self: center;
        margin-bottom: 20px;

      }
      &__title{
        color: #ffffff;
        font-size: 35px;
        font-weight: 700;
      }
      &__descr{
        color: #ffffff;
        font-size: 15px;
        span{
          font-weight: 700;
        }
      }
    }
  }
  .text-info{
    color: #ffffff;
    text-align: center;
    font-size: 21px;
    margin-bottom: 80px;
    @media only screen and (max-width:500px){
      font-size: 18px;
    }
  }
  .us-programs{
    h2{
      color: #ffffff;
      font-size: 50px;
      font-weight: 700;
      text-transform: none;
      @media only screen and (max-width:768px){
        font-size: 40px;
      }
      @media only screen and (max-width:500px){
        font-size: 30px;
      }
    }
    &__date{
      width: 100%;
      max-width: 520px;
      display: flex;
      margin: 0 auto;
      justify-content: space-between;
      margin-bottom: 50px;
      flex-wrap: wrap;
      @media only screen and (max-width: 460px){
          justify-content: center;
      }
      .rgw__button{
        text-transform: none;
        margin: 10px;
        max-width: 188px;
        margin-top: 0;
      }
    }
  }
  .start-programs{
    width: 100%;
    max-width: 850px;
    margin: 0 auto;
    &__item{
        color: #ffffff;
        display: flex;
        justify-content: space-around;
        .item-left{
          color: #c91c1c;
          font-size: 32px;
          font-weight: 700;
          width: 49%;
          text-align: right;
          @media only screen and (max-width:768px){
            font-size: 20px;
          }
          span{
            display: block;
            line-height: 0.7;
          }
        }
        .order-left{
          order:3;
          text-align: left;
        }
        .item-middle{
          width: 2%;
          margin: 0 13px;
          height: 135px; 
          position: relative;
          display: flex;
          &::before{
            content:'';
            position: absolute;
            top: -1px;
            left: 50%;
            margin-left: -10px;
            border: 5px solid #fff;
            border-radius: 100px;
            height:20px;
            width:20px;
          }
          &::after{
            content:'';
            position: absolute;
            top: 15px;
            left: 50%;
            bottom: 0;
            margin-left: -4px;
            background-color: #fff;
            height:auto;
            width:8px;
          }
        }
        .order-middle{
          order:2;
        }
        .item-right{
          width: 49%;
          font-size: 23px;
          @media only screen and (max-width:768px){
            font-size: 18px;
          }
          @media only screen and (max-width:500px){
            font-size: 14px;
          }
          span{
            display: block;
            line-height: 1;
          }
        }
      .order-right{
        order:1;
        text-align: right;
      } 
    }
  }
  .us-programs-button{
    display: flex;
    justify-content: center;
    margin-bottom: 150px;
    .rgw__button{
      max-width:270px;
    }
  }
  .rgw-conference-hall{
    &__title{
      font-size: 50px;
      color: #ffffff;
      text-transform: none;
      font-weight: 700;
      margin-bottom: 0;
      @media only screen and (max-width:768px){
        font-size: 40px;
      }
      @media only screen and (max-width:500px){
        font-size: 30px;
      }
    }   
    &__map img{
      cursor: pointer;  
    }
    &__button{
      display: flex;
      justify-content: center;
      margin-top: 20px;
      .rgw__button{
        margin-top: 0;
        width:100%;
        max-width: 270px;
      }
    }
  }
}
.rgw__button{
  font-size: 18px;
  line-height: 50px;
  border-radius: 5px;
  font-weight: 400;
  width: 100%;
  margin-top: 100px;
  color: #ffffff;
  max-width: 226px;
  height: 50px;
  background-color: #ff0106;
  -webkit-transition: 0.2s ease-in-out;
  transition: 0.2s ease-in-out;
  &:hover{
    background-color: #ff191d;
    -webkit-box-shadow: 0 0 80px 1px #ff0106;
    box-shadow: 0 0 80px 1px #ff0106;
  }
}
.rgw-block-form{
  display:none;
  background-color: #241652;
  @media only screen and (max-width:1140px){
    display: block;
  }
  .rgw-new-form {
    margin: 0 auto;
    position: relative;
    padding: 20px 20px;
    width: 100%;
    max-width: 560px;
    background-color: #241652;
    border-radius: 10px;
    &__title{
      h2{
        color: #ffffff;
        font-weight: 700;
        font-size: 54px;
        margin-bottom: 20px;
        text-transform: none;
        @media only screen and (max-width:768px){
          font-size: 40px;
        }
        @media only screen and (max-width:500px){
          font-size: 30px;
        }
      }
    }
    .rgw-page-form{
      display: flex;
      flex-direction: column;
      align-items: center;
      .input {
        width: 100%;
        max-width: 400px;
        input{
          width: 100%;
          border-radius: 10px;
          font-size: 18px;
        }
    }
      .rgw-buttons{
        button{
          border-radius: 10px;
          text-transform: none;
          font-size: 18px;
        }
      }
    }
  }
}
.rgw-map {
  padding: 0;
  height: 500px;
  position: relative; 
    .rgw-form {
    position: relative;
    z-index: 4;
    padding: 20px 20px;
    width: 560px ;
    height: auto;
    background-color: #241652;
    border-radius: 10px;
    &__title{
      h2{
        color: #ffffff;
        font-weight: 700;
        font-size: 54px;
        margin-bottom: 20px;
        text-transform: none;
      }
    }
    @media only screen and (max-width:1140px){
      display: none;
    }
  }
    .rgw-page-form{
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      .input input{
        width: 400px;
        border-radius: 10px;
        font-size: 18px;
      }
      .rgw-buttons{
        button{
          border-radius: 10px;
          text-transform: none;
          font-size: 18px;
        }
      }
    }
  #map{
    position: absolute;
    top: 0;
    z-index: 3;
    left: 0;
  }
  .rgw-container-map{
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }  
}
  .rgw-partners{
    padding: 60px 0;
    background: url("../images/rgw_2019/Bg_bottom.png") no-repeat;
    background-size: cover;
    &__title{
      h2{
      color: #ffffff;
      font-size: 50px;
      font-weight: 700;
      text-transform: none;
      @media only screen and (max-width:768px){
        font-size: 40px;
      }
      @media only screen and (max-width:500px){
        font-size: 30px;
      }
      } 
    }
    &__logo{
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;
      .logo{
        padding: 30px 10px;
      }
    }
  }
  .flip-clock-divider .flip-clock-label{
    top: 90px;
    right: -80px;
    font-size: 18px;
    @media only screen and (max-width:500px){
      top: 70px;
      right: -65px;
    }
  }
  .flip-clock-divider.minutes .flip-clock-label{
    right: -80px;
    top: 90px;
    @media only screen and (max-width:500px){
      top: 70px;
      right: -68px;
    }
  }
  .flip-clock-wrapper ul li a div div.inn{
    // background-color: #ffffff;
    background-color: #ffffff;
    color: #000;
    font-weight: 500;
  }
  .flip-clock-wrapper ul li a div.up:after{
    height: 0;
  }
}
.flip-clock-wrapper ul li a div div.inn{
 border-radius: 5px;
}
.flip-clock-wrapper ul{
 margin: 5px;
 background: none;
}
.rgw-popup{
  position: fixed;
  opacity: 0;
  z-index: -1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,0,0,.5);
  &__img{
    padding: 0 45px;
    width: 100%;
    max-width:1100px;
    position: relative;
    .rgw-close-details{
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 0px;
      right: 10px;
      cursor: pointer;
      &::before, &::after{
        content: "";
        display: block;
        margin-top: 15px;
        width: 100%;
        border-bottom: 2px solid #f3eff5;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
      }
      &::after{
        margin-top: -2px;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }
    }
    img{
      width:100%;
      margin: auto;

     
    }
  }
}
// .flip-clock-wrapper ul li a div.up:after{
//   z-index: 0;
//   background-color: transparent;
// }

// .flip-clock-wrapper ul li a div{
//   z-index: 3;
// }
// .flip-clock-wrapper ul li{
//   z-index: -1;
// }
// .flip-clock-wrapper ul{
//   background: transparent;
// }

// .flip-clock-wrapper ul li.flip-clock-active{
//   z-index: -1;
// }
// .flip-clock-wrapper ul.play li.flip-clock-before .down .shadow{
//   background: none;
// }
// .flip-clock-wrapper ul.play li.flip-clock-before .up .shadow{
//   background: transparent;
// }
