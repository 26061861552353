body > footer {
  display: block;
  position: relative;
  padding: 80px 0;
  background-color: $color-black;
  color: $color-white;
  font-size: 0;

  ul {
    &:not(.socials) {
      li {
        margin-bottom: .5rem;
      }
    }
  }

  .socials {
    display: block;
    width: 180px;
    margin: 2rem 0 -.7rem;
    text-align: left;
  }

  .col {
    display: inline-block;
    width: calc(50% - 190px);
    font-size: 1.4rem;
    vertical-align: top;

    a {
      transition: color .2s ease;
      color: $color-white-50;
      font-weight: 300;

      &:hover {
        color: $color-red;
      }
    }

    form {
      padding-top: .4rem;

      .btn {
        width: 165px;
        padding: 0;
        float: right;
      }

      .input-recaptcha {
        >div {
          >div {
            >div {
              height: 78px;
            }
          }
        }
      }
    }

    &:nth-of-type(3) {
      width: 380px;
    }
  }

  h3 {
    display: block;
    margin-bottom: 1rem;
    font-size: 2.2rem;
    font-weight: 100;
    text-transform: uppercase;
  }

  .copyrights {
    display: block;
    margin-top: 2rem;
    color: $color-white-50;
    font-size: 1.3rem;
    font-weight: 100;
    text-align: center;
  }

  @media only screen and (max-width: 989px) {
    padding: 60px 0;

    .copyrights {
      margin-top: 5rem;
    }
  }

  @media only screen and (max-width: 799px) {
    .col {
      width: 50%;
      text-align: center;

      &:nth-of-type(3) {
        display: block;
        margin: 30px auto 0;
      }
    }

    form {
      text-align: left;
    }

    .socials {
      margin: 2rem auto 0;
      text-align: center;
    }
  }

  @media only screen and (max-width: 659px) {
    padding: 30px 0 55px;

    // h3 {
    //   margin-bottom: 1.2rem;
    //   font-size: 2.6rem;
    // }

    .copyrights {
      margin-top: 3rem;
    }
  }

  @media only screen and (max-width: 519px) {
    .col {
      width: 100%;
      margin-bottom: 20px;
      text-align: center;

      &:nth-of-type(3) {
        display: block;
        width: 320px;
        max-width: 100%;
        margin: 0 auto;
      }

      form {
        .input-recaptcha {
          display: block;
          width: 203px;
          margin: 0 auto;
          float: none;
          transition: max-height .5s ease, margin .5s ease;

          >div {
            >div {
              >div {
                width: 302px;
                height: 0;
              }
            }
          }

          &.error,
          &.has-success {
            width: 203px;

            .error-message {
              width: 100%;
              text-align: center;
              white-space: normal;
            }

            >div {
              >div {
                >div {
                  width: 302px;
                  height: 0;
                }
              }
            }
          }
        }

        .btn {
          width: 100%;
          margin: 0 auto;
          float: none;
          clear: both;
        }

        &.focused {
          .input {
            width: 100%;

            &.input-recaptcha {
              width: 203px;
              max-height: 200px;
              margin: 0 auto 1rem;

              >div {
                >div {
                  >div {
                    width: 302px;
                    height: 77px;
                  }
                }
              }

              &.error,
              &.has-success {
                >div {
                  >div {
                    >div {
                      height: 77px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 399px) {
    padding-bottom: 65px;
  }
}

.container-indent{
  margin-bottom: 40px;
  @media only screen and (max-width: 519px){
    margin-bottom: 20px;
  }
  .footer-title{
    margin-bottom: 10px;
    font-size: 2.2rem;
    text-transform: uppercase;
    font-weight: 100;
    @media only screen and (max-width: 659px){
      margin-bottom: 1.2rem;
      font-size: 2.6rem;
    }
  }
  .menu-primary li{
    &:last-child{
      margin-bottom: 0;
    }
  }
}