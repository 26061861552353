.subscriber {
  form {
    .input {
      display: inline-block;
      width: calc(50% - 130px);
      float: left;
      transition: all .5s ease;
      vertical-align: top;

      &:first-of-type {
        margin-right: 30px;
      }

      &.input-recaptcha {
        width: 0;
        margin: 0;
        transition: width .5s ease, height .5s ease, margin-left .5s ease;
      }
    }

    .btn {
      width: 200px;
      margin-left: 30px;
      padding: 0;
      float: right;
    }

    &.focused {
      .input {
        // width: calc(50% - 247.5px);

        &.input-recaptcha {
          width: 203px;
          margin: 0 0 0 30px;
        }
      }
    }
    
    @media only screen and (max-width: 989px) {
      .input {
        width: calc(50% - 15px);

        &.input-recaptcha {
          margin-left: 0;
          clear: both;
        }
      }

      &.focused {
        .input {
          // width: calc(50% - 15px);

          &.input-recaptcha {
            margin-left: 0;
          }
        }
      }
    }

    @media only screen and (max-width: 519px) {
      display: block;
      width: 320px;
      max-width: 100%;
      margin: 0 auto;

      .input {
        width: 100%;

        &.input-recaptcha {
          display: block;
          width: 203px;
          margin: 0 auto;
          float: none;
          transition: max-height .5s ease, margin .5s ease;

          >div {
            >div {
              >div {
                width: 302px;
                height: 0;
              }
            }
          }

          &.error,
          &.has-success {
            width: 203px;

            .error-message {
              width: 100%;
              text-align: center;
              white-space: normal;
            }

            >div {
              >div {
                >div {
                  width: 302px;
                  height: 0;
                }
              }
            }
          }
        }
      }

      .btn {
        width: 100%;
        margin: 0 auto;
        float: none;
        clear: both;
      }

      &.focused {
        .input {
          width: 100%;

          &.input-recaptcha {
            width: 203px;
            max-height: 200px;
            margin: 0 auto 1rem;

            >div {
              >div {
                >div {
                  width: 302px;
                  height: 77px;
                }
              }
            }

            &.error {
              max-height: 95px;
            }
          }
        }
      }
    }
  }
}
