body > header {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  transition: padding .3s ease;
  background-color: $color-black;
  color: $color-white;
  z-index: 5;

  .container {
    display: table;

    >* {
      display: table-cell;
      vertical-align: middle;
    }
  }

  .logo-main {
    a {
      display: inline-block;
      width: 230px;
      transition: width .2s ease, margin-top .2s ease;
    }

    img {
      width: 100%;
    }
  }
  
  .logo-academy {
    img {
      margin-top: -6px;
    }
  }

  .form-search {
    position: relative;
    width: auto;

    .input {
      position: relative;
      margin: 0;
      margin-right: 13px;
    }

    [type='text'] {
      position: absolute;
      top: 50%;
      right: 0;
      width: 0;
      height: 30px;
      padding: 0 3.5rem 0 1.6rem;
      transform: translateY(-50%);
      transition: width .5s ease, opacity .5s ease;
      border: 1px solid $color-white;
      border-radius: 20px;
      color: $color-white;
      line-height: 30px;
      opacity: 0;
      visibility: hidden;
    }

    button {
      position: relative;
      width: 2.2rem;
      background-color: transparent;
      color: $color-white;
      text-align: center;
      opacity: .7;
      z-index: 10;

      &::before {
        font-size: 1.8rem;
        font-weight: 300;
      }

      &:hover {
        opacity: .8;
      }
    }
  }

  .menu-lang {
    text-align: center;

    li {
      display: inline-block;
      position: relative;
      margin-left: 15px;

      &::before {
        display: block;
        position: absolute;
        top: 50%;
        left: -8px;
        width: 2px;
        height: 11px;
        transform: translateY(-50%);
        background: $color-white;
        content: '';
        opacity: .7;
      }

      &:first-child {
        margin-left: 0;

        &::before {
          display: none;
        }
      }

      &.active {
        a {
          opacity: 1;
        }
      }
    }

    a {
      color: $color-white;
      font-weight: 300;
      opacity: .7;

      &:hover {
        opacity: .8;
      }
    }
  }

  .buttons {
    text-align: right;
  }

  .btn-default {
    height: 4rem;
    line-height: 3.8rem;
  }

  %open-menu-pseudo {
    position: absolute;
    left: 0;
    width: 100%;
    height: 3px;
    transition: top .4s ease, transform .4s ease;
    background-color: $color-white;
    content: '';
  }

  %open-pseudo-red {
    left: -2px;
    width: 107%;
    background-color: $color-red;
  }

  .open-menu {
    display: none;
    position: relative;
    width: 37px;
    height: 27px;
    margin: 7px 0 0 25px;
    float: right;
    transition: opacity .2s ease, transform .7s ease;
    background-color: transparent;
    cursor: pointer;
    opacity: .7;
    z-index: 1001;

    &::before {
      @extend %open-menu-pseudo;
      top: 0;
    }

    &::after {
      @extend %open-menu-pseudo;
      bottom: 0;
    }

    >span {
      display: block;
      width: 100%;
      height: 100%;

      &::after {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 3px;
        margin: auto;
        transition: opacity .4s ease;
        background-color: $color-white;
        content: '';
      }
    }

    &:hover {
      opacity: 1;
    }

    &.open {
      transform: translateX(-430px);

      &::before {
        @extend %open-pseudo-red;
        top: 12.5px;
        transform: rotate(-45deg);
      }

      &::after {
        @extend %open-pseudo-red;
        bottom: 11.5px;
        transform: rotate(45deg);
      }

      >span {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &.search {
    nav {
      opacity: 0;
      visibility: hidden;
    }

    .form-search {
      [type='text'] {
        width: 300px;
        opacity: 1;
        visibility: visible;
      }

      button {
        opacity: 1;
      }
    }
  }

  &.fixed {
    padding: 8px 0;

    nav {
      ul {
        li {
          &:hover {
            ul {
              top: 52px;

              &::after {
                height: 32px;
              }
            }
          }

          ul {
            top: 22px;

            &::after {
              height: 0;
            }
          }
        }
      }
    }

    + main {
      &::before {
        height: 71px;
      }
    }
  }

  @media only screen and (max-width: 1130px) {
    .open-menu {
      display: inline-block;
    }
    .menu-lang {
      width: 127px;
    }
    .form-search {
      width: 42px;
    }
    .buttons {
      width: 181px;
    }
    &.search {
      nav {
        opacity: 1;
        visibility: visible;
      }
    }
    &.fixed {
      nav {
        top: -8px;
      }
    }
  }

  @media only screen and (max-width: 1099px) {
    .menu-lang {
      padding: 5px;
    }
  }

  @media only screen and (max-width: 879px) {
    .form-search {
      position: fixed;
      top: -18px;
      right: -430px;
      width: 430px;
      max-width: 100%;
      height: 65px;
      padding: 20px 30px 10px;
      transition: right .7s ease;
      background-color: $color-white;
      text-align: left;
      z-index: 1001;

      .input {
        margin: 0;
      }

      [type='text'] {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 35px;
        padding: 0 3.7rem 0 1.6rem;
        transform: none;
        background-color: $color-black-90;
        opacity: 1;
        visibility: visible;
      }

      button {
        position: absolute;
        top: auto;
        right: 15px;
        bottom: 9px;
        margin: 0;
        padding: 0;
      }
    }

    &.fixed {
      nav {
        top: 56px;
      }

      .form-search {
        top: -8px;
      }
    }

    &.search {
      .form-search {
        [type='text'] {
          width: 100%;
        }
      }
    }
  }

  @media only screen and (max-width: 799px) {
    position: relative !important;
    padding: 10px 0 !important;

    + main {
      &::before {
        height: 0 !important;
      }
    }

    nav {
      top: 50px;
    }

    .form-search {
      top: -15px;
    }

    &.fixed {
      nav {
        top: 39px;
      }

      .form-search {
        top: -15px;
      }
    }
  }

  @media only screen and (max-width: 750px) {
    .open-menu {
      &.open {
        position: fixed;
        top: 4px;
        transform: translateX(-490px);
      }
    }
  }

  @media only screen and (max-width: 569px) {
    .logo-main {
      a {
        width: 175px;
        margin-top: 4px;
      }
    }
  }

  @media only screen and (max-width: 519px) {
    .open-menu {
      position: absolute;
      top: 6px;
      right: 10px;
      margin: 0 0 0 25px;
      transition: opacity .2s ease, right .7s ease, top .2s ease;

      &.open {
        top: 15px;
        right: calc(100% - 75px);
        transform: translateX(0);
      }
    }

    nav {
      top: 132px;
      right: -100%;
      width: 100%;
      padding: 10px 40px 20px;

      &.open {
        right: 0;

        + .form-search {
          right: 0;

          + .mobile-menu-top {
            right: 0;
          }
        }
      }
    }

    .form-search {
      top: 65px;
      right: -100%;
      width: 100%;
      height: 75px;
      padding: 20px 40px;

      [type='text'] {
        padding: 0 4.3rem 0 1.6rem;
      }

      button {
        bottom: 8px;
      }
    }

    .mobile-menu-top {
      position: fixed;
      top: -10px;
      right: -100%;
      width: 100%;
      max-width: 100%;
      height: 76px;
      padding: 20px 40px 0;
      transition: all .7s ease;
      background-color: $color-white;
      text-align: right;
      z-index: 1001;
    }

    .menu-lang {
      display: inline-block;
      margin-right: 20px;
      padding-top: 12px;

      li {
        &::before {
          background: $color-black;
        }
      }

      a {
        color: $color-black;

        &.active {
          font-weight: 600;
        }
      }
    }

    .buttons {
      .btn-default {
        opacity: 0;
      }
    }

    &.fixed {
      nav {
        top: 119px;
      }

      .form-search {
        top: 65px;
      }
    }
  }

  @media only screen and (max-width: 479px) {
    .form-search {
      button {
        &::before {
          font-size: 2.2rem;
        }
      }
    }

    .menu-lang {
      padding-top: 7px;

      li {
        &::before {
          height: 15px;
        }
      }

      a {
        font-size: 2rem;
      }
    }
  }

  @media only screen and (max-width: 399px) {
    .open-menu {
      &.open {
        right: calc(100% - 55px);
      }
    }

    .form-search {
      padding: 20px;

      [type='text'] {
        padding-right: 4.4rem;
      }

      button {
        right: 20px;
        bottom: 6px;
        height: 23px;
      }
    }

    .mobile-menu-top {
      padding: 20px 20px 0;
    }

    nav {
      padding: 20px;
    }
  }
}

.menu-overlay {
  position: fixed;
  top: -50%;
  left: 0;
  width: 100%;
  height: 200%;
  transition: opacity .5s ease;
  background: transparentize($color-black, .4);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;

  &.displayed {
    opacity: 1;
    visibility: visible;
  }
}
