.header-amsterdam{
  .menu-primary{
    text-align: left;
  }
  .form-search{
    input{
      display: none;
    }
    .input{
      display: none;
    }
  }
  .buttons a{
    display: none;
  }
  .buttons{
    width: 45px;
  }
  .amsterdam-lang-sp{
    display: none;
  }
}


.amsterdam-inner-page {
  .amsterdam-header {
    background: url("../images/amster_IGB/Component.png") 50% 50% / cover no-repeat;
    padding: 140px 0 185px;
    position: relative;
    @media only screen and (max-width: 1139px){
      background: url("../images/amster_IGB/bg2!.png") 50% 50% / cover no-repeat;
    }
    @media only screen and (max-width: 767px){
      background: url("../images/amster_IGB/Component_mobile.png") 50% 50% / cover no-repeat;
      padding: 45px 0 95px;
    }
    .amsterdam-container-img {
      position: relative;
      .amsterdam-offer {
        position: relative;
        z-index: 2;
        width: 100%;
        max-width: 655px;
        &__title {
          h1 {
            margin-bottom: 15px;
            text-align: left;
            font-size: 48px;
            color: #ffffff;
            font-weight: normal;
            text-transform: none;
            line-height: 54px;
            span{
              font-size: 36px;
              font-weight: 300;
            }
          }
          img{
            margin-bottom: 40px;
            display: block;
          }
          @media only screen and (max-width: 767px) {
            img {
              width: 100%;
              max-width:280px;
            }
          }
        }
        &__info {
          p {
            color: #ffffff;
            font-size: 36px;
            font-weight: 300;
            line-height: 60px;
            margin-bottom: 24px;
            span {
              text-transform: uppercase;
            }
          }
        }
        &__booth{
          display: flex;
          position: relative;
          width: 100%;
          max-width: 408px;
          padding-top: 8px;
          border-top: 1px solid #ffffff;
          justify-content: center;
          margin-bottom: 60px;
          p{
            font-size: 48px;
            text-transform: uppercase;
            color: #fff;
          }
          &::before{
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            background: #fff;
            -moz-border-radius: 50px;
            -webkit-border-radius: 50px;
            border-radius: 50px;
          }
          &::after{
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            width: 6px;
            height: 6px;
            margin-top: -3px;
            background: #fff;
            -moz-border-radius: 50px;
            -webkit-border-radius: 50px;
            border-radius: 50px;
          }
          @media only screen and (max-width: 767px){
            max-width: 220px;
            margin-bottom: 115px;
            p{
              font-size: 30px;
            }
          }
        }
        &__button {
          @media only screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
          }
          button {
            background-color: #c91c1c;
            padding: 20px;
            color: #fff;
            width: 100%;
            max-width: 410px;
            border-radius: 10px;
            font-size: 24px;
            font-weight: 300;
            border-bottom: 0;
            transition: 0.2s ease-in-out;
            &:hover {
              box-shadow: 0 0 80px 1px #c91c1c;
            }
          }
        }
        @media only screen and (max-width: 767px) {
          max-width: 100%;
          text-align: left;
          &__button button {
            max-width: 288px;
            font-size: 18px;
          }
          &__title h1 {
            font-size: 30px;
            text-align: left;
            line-height: 50px;
            margin-bottom: 0;
            span{
              font-size: 20px;
              font-weight: 300;
            }
          }
          &__info p {
            font-size: 20px;
            margin-bottom: 30px;
            line-height: 30px;
          }
        }
      }
    }
    .amsterdam-animation{
      position: absolute;
      top: -405px;
      right: 35px;
      width: 300px;
      height: 500px;
      border-radius: 40%;
      canvas{
        background: transparent;
        border-radius: 40%;
        width: 100%;
        height: 100%;
    }
      @media only screen and (max-width: 1139px){
        display: none;
      }
    }
  }
  .amsterdam-about{
    @media only screen and (max-width: 767px){
      padding-bottom: 0;
   }
    &__wrap{
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
        .block-left{
          width: 100%;
          max-width: 476px;
          text-align: left;
          @media only screen and (max-width: 1139px){
            margin-bottom: 80px;
         }
          @media only screen and (max-width: 767px){
           padding: 0 10px;
           margin-bottom: 45px;
        }
          h2{
             font-size: 36px;
             max-width: 100%;
             line-height: 42px;
             text-transform: uppercase;
             margin-bottom: 44px;
             text-align: left;
             font-weight: 300;
             color: #D60100;
          }
          p{
            font-size: 20px;
            line-height: 23px;
            margin-bottom: 24px;
            font-weight: 300;
            color: #000;
          }
        }
        .block-right{
          width: 50%;
        }
      }
      @media only screen and (max-width: 1139px) {
        .block-right{
          width: 100%;
          height: 364px;
          
        }
      }
    }
        .amsderdam-no-padding{
          @media only screen and (max-width: 767px){
          padding-left: 0;
          padding-right: 0;
      }
    }
    .amsterdam-container-img {
      position: relative;
      @media only screen and (max-width: 1139px){
        width: 100%;
        max-width: 768px; 
      }
      @media only screen and (max-width: 767px){
        width: 100%;
        max-width: 350px; 
      }
    }
    .amsterdam-program{
      background: url(../images/amster_IGB/bg2_desktop.png) 50% 50% / cover no-repeat;
      padding: 60px 0;
      &__wrap{
          width: 100%;
          max-width:720px;
          margin: 0 auto;
        h2{
          color: #fff;
          font-size: 36px;
          line-height: 42px;
          font-weight: 400;
          text-transform: none;
        }
        .descr{
          color: #fff;
          font-size: 20px;
          line-height: 30px;
          margin-bottom: 45px;
        }
        .amsterdam_program__btn{
          display: flex;
          justify-content: center;
            button {
              background-color: #c91c1c;
              padding: 20px;
              color: #fff;
              width: 100%;
              max-width: 410px;
              border-radius: 10px;
              font-size: 24px;
              font-weight: 300;
              border-bottom: 0;
              transition: 0.1s ease-in-out;
              &:hover {
                box-shadow: 0 0 80px 1px #c91c1c;
              }
              @media only screen and (max-width: 767px){
                font-size: 18px;
              } 
            }
        }
      }
      &__button{
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        .btn-left{
          margin: 0 10px 0 0;
          max-width: 330px;
          width: 100%;
          button{
          width: 100%;
          max-width:330px;
          border-radius: 10px 0px 0px 10px;
          background: transparent;
          border: 1px solid #fff;
          font-size: 20px;
          color: #fff;
          padding: 18px 10px;
          transition: .1s ease-in;
          &:hover{
            border: 1px solid #FA0000;
            color: #FA0000;
            }
            &:focus{
              border: 1px solid #FA0000;
              color: #FA0000;
            }
          }
        }
        .btn-right{
          width: 100%;
          max-width: 330px;
          margin: 0 0 10px 15px;
          button{
          width: 100%;
          max-width:330px;
          border-radius: 0px 10px 10px 0px;
          background: transparent;
          border: 1px solid #fff;
          font-size: 20px;
          color: #fff;
          padding: 18px 10px;
          transition: .2s ease-in;
          &:hover{
            border: 1px solid #FA0000;
            color: #FA0000;
          }
          &:focus{
            border: 1px solid #FA0000;
            color: #FA0000;
          }
          }
        }
      }
      @media only screen and (max-width: 767px){
        &__wrap h2{
          font-size: 30px;
        }
        &__button{
          display: flex;
          flex-direction: column;
          margin: auto;
          align-items: center;
          margin-bottom: 39px;
          .btn-left{
            width: 100%;
            max-width: 290px;
            margin: 0 0 20px;
            button{
              border-radius: 10px 10px 0 0;
            }
          }
          .btn-right{
            width: 100%;
            max-width: 290px;
            margin: 0;
            button{
              border-radius: 0 0 10px 10px;
            }
          }
        }
       

      }
      .timetable{
        padding-bottom: 20px;
        &__block{
          color: #fff;
          margin-bottom: 60px;
          padding-left: 30px;
          border-left: 4px solid #B71016;
          .title{
            line-height: 28px;
            font-size: 24px ;
            margin: 0;
            text-align: left;
          }
          .clock{
            line-height: 28px;
            font-size: 18px;
          }
        }
        @media only screen and (max-width:767px){
          &__block{
             padding-left: 15px;
             .title{
               font-size: 18px;
             }
             .clock{
               font-size: 16px;
             }
          }
        }
      }
    }
    .amsterdam-find-us{
      padding: 60px 0 0;
      h2{
        font-size: 36px;
        line-height: 42px;
        font-weight: 400;
        text-transform: none;
        color: #D60100;
      }
      .block-wrap{
      display: flex;
      flex-wrap: wrap;
      .map{
        width: 100%;
        max-width:700px;

      }
      .block-form{
        width: 100%;
        max-width: 439px;
        background: #18104D;
        border-radius: 10px 10px 0 0;
        padding: 45px 20px;
        &__title{
          h2{
            font-size: 22px;
            line-height: 26px;
            font-weight: 400;
            text-align: left;
            color: #fff;
            margin-bottom: 23px;
          }
        }
        .amsterdam-button{
          text-align: right;
          button{
            margin-top: 10px;
            text-transform: none;
            border-radius: 10px;
          }
        }
        input,textarea{
          border: 1px solid rgb(255, 255, 255);
          &::placeholder{
            color: #fff;
          }
        }
        textarea{
          min-width: 100%;
          max-width: 100%;
          height: 11rem;
          min-height: 11rem;
          max-height: 20rem;
        }
      }
    }
    @media only screen and (max-width: 1179px){
      .map{
        margin: auto;
        // margin-bottom: 70px;
        iframe{
          width: 100%;
        }
      }
      .block-form{
        
        margin: auto;
      }
      .amsterdam-container-img{
        padding: 0;
      }
    }
    @media only screen and (max-width: 1155px){
      .map{
        margin-bottom: 70px;
      }
    }
    @media only screen and (max-width: 767px){
      .map{
        margin-bottom: 0;
      }
    }
  }  
}

.footer-amsterdam{
  .container-indent:first-child{
    display: none;
  }
    .col:nth-of-type(3){
      display: none;
    }
    .col{
      width: 35%;
      text-align: center;
      @media only screen and (max-width:767px){
        width:100%;
      }
    }
    .socials{
      margin: auto;
      text-align: center;
      padding-bottom: 20px;
      margin-top: 20px;
    }
    .container-indent:last-child{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    .copyrights{
      width: 100%;
    }
    .contact-form-footer{
      display: none;
    }
}

.amsterdam-block-popup{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width:100%;
  height: 100%;
  z-index: 100;
  display: none;
  .block-form{
    position: relative;
    width: 100%;
    max-width: 439px;
    background: #18104D;
    border-radius: 10px 10px 0 0;
    padding: 45px 20px;
    &__title{
      h2{
        font-size: 22px;
        line-height: 26px;
        font-weight: 400;
        text-align: left;
        text-transform: none;
        color: #fff;
        margin-bottom: 23px;
      }
    }
    .amsterdam-button{
      text-align: right;
      button{
        margin-top: 10px;
        border-radius: 10px;
        text-transform: none;
      }
    }
    input,textarea{
      border: 1px solid rgb(255, 255, 255);
      &::placeholder{
        color: #fff;
      }
    }
    textarea{
      min-width: 100%;
      max-width: 100%;
      height: 11rem;
      min-height: 11rem;
      max-height: 20rem;
    }
    .amsterdam-close{
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 10px;
      right: 10px;
      cursor: pointer;
    }
    .amsterdam-close::after,
    .amsterdam-close::before{
      content: "";
      display: block;
      margin-top: 15px;
      width: 100%;
      border-bottom: 2px solid #f3eff5;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
    .amsterdam-close::after{
      margin-top: -2px;
      -webkit-transform: rotate(-45deg);
      transform: rotate(-45deg);
    }
  }
  .bg{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
    background: rgba(0,0,0, 0.7);
    opacity: 0.7;
  }
  .content{
    width: 100%;
    height: 100%;
   display: flex;
   justify-content: center;
   align-items: center; 
  }
}

