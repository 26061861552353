$container-width: 1140px;
$container-padding: 0;


// Colors
$color-white: #fff;
$color-grey-lightest: #f2f2f2;
$color-grey-light: #cbcdd0;
$color-grey: #aeb0b3;
$color-grey2: #707070;
$color-grey3: #dedede;
$color-grey4: #999;
$color-grey-dark: #8e9093;
$color-grey-darker: #343434;
$color-black: #000;
$color-black2: #040305;
$color-black3: #1d212a;
$color-black4: #020a1e;
$color-black-95: transparentize($color-black, .05);
$color-black-90: transparentize($color-black, .1);
$color-black-70: transparentize($color-black, .3);
$color-black-12: transparentize($color-black, .88);
$color-white-50: transparentize($color-white, .5);
$color-white-70: transparentize($color-white, .3);
$color-white-80: transparentize($color-white, .2);
$color-white-90: transparentize($color-white, .1);
$color-white-95: transparentize($color-white, .05);

$color-blue-darker: rgba(27, 37, 52, .85);
$color-blue-dark: rgba(7, 36, 64, .95);
$color-blue-light: #27d2fe;
$color-blue-bg: #113d6b;
$color-blue: #304ffe;
$color-blue2: #2e6da4;
$color-blue3: #036;
$color-blue-hover: #004d99;
$color-blue-shadow: #002d59;
$color-blue-gradient1: #134478;
$color-blue-gradient2: #072440;
$color-blue-gradient3: #15487e;
$color-blue-gradient4: #06184a;
$color-blue-gradient5: #256690;
$color-red: #d50000;
$color-red2: #f50101;
$color-red3: #c00;
$color-red-bright: lighten($color-red, 8%);
$color-red-dark: #9c0000;
$color-green: #3b9c18;
$color-yellow: #ff0;

$color-academy-gradient1: #01082f;
$color-academy-gradient2: #d50f16;

$color-star-rating: #ffd941;

// Social colors
$color-socials-facebook: #3b5998;
$color-socials-facebook2: #4d6a95;
$color-socials-vk: #507299;
$color-socials-linkedin: #007bb6;
$color-socials-linkedin2: #11779e;
$color-socials-telegram: #35ace6;
$color-socials-twitter: #00bae9;
$color-socials-twitter2: #47a4c3;
$color-socials-youtube: #b00;
$color-socials-google-plus: #dd4b39;
$color-socials-blogger: #fb8f3d;
$color-socials-instagram: #e4405f;

$color-socials-instagram-gradient1: #fed373;
$color-socials-instagram-gradient2: #f15245;
$color-socials-instagram-gradient3: #d92e7f;
$color-socials-instagram-gradient4: #9b36b7;
$color-socials-instagram-gradient5: #515ecf;

$socials-instagram-gradient: radial-gradient(circle at 33% 100%, $color-socials-instagram-gradient1 4%, $color-socials-instagram-gradient2 30%, $color-socials-instagram-gradient3 62%, $color-socials-instagram-gradient4 85%, $color-socials-instagram-gradient5);

// Infographics colors
$color-infographic-1: #d51116;
$color-infographic-2: #e46b65;
$color-infographic-3: #771f87;
$color-infographic-4: #a06aa9;
$color-infographic-5: #1789e2;
$color-infographic-6: #54adf3;
$color-infographic-7: #294290;
$color-infographic-8: #2b65a9;

$colors-infographic: $color-infographic-1, $color-infographic-2, $color-infographic-3, $color-infographic-4, $color-infographic-5, $color-infographic-6, $color-infographic-7, $color-infographic-8
