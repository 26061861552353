.header {
  position: relative;
  padding: 0;
  background-color: $color-black2;
  overflow: hidden;
  
  &::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../images/bg/bg-point.png') repeat center bottom;
    content: '';
    z-index: 1;
  }

  &::after {
    display: block;
    position: relative;
    padding-top: 50%;
    content: '';
  }

  video {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: initial;
  }

  .container {
    @include vertical-align;
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    text-align: center;
    z-index: 2;
  }

  .logo {
    display: inline-block;
    margin-bottom: 2px;
    color: $color-white;
    font-size: 3.5rem;

    img {
      width: 810px;
      max-width: 100%;
    }
  }

  p {
    display: block;
    max-width: 820px;
    margin: 0 auto 50px;
    color: $color-white-90;
    font-size: 1.8rem;
    text-align: center;
  }

  h1 {
    display: block;
    max-width: 820px;
    margin: 0 auto 3rem;
    color: $color-white-90;
    font-size: 3.4rem;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 989px) {
    h1 {
      margin-bottom: 1rem;
    }
  }

  @media only screen and (max-width: 879px) {
    .logo {
      margin-bottom: 1rem;
      font-size: 3rem;
    }

    p {
      &:not(.logo) {
        font-size: 1.6rem;
      }
    }

    h1 {
      font-size: 2.8rem;
    }
  }

  @media only screen and (max-width: 799px) {
    &::after {
      display: none;
    }

    .container {
      position: relative;
      top: auto;
      left: auto;
      height: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      transform: none;
    }

    .video {
      height: 100%;
    }

    .logo {
      max-width: 65%;
    }

    p {
      &:not(.logo) {
        font-size: 1.4rem;
      }
    }

    h1 {
      font-size: 2.4rem;
    }
  }

  @media only screen and (max-width: 770px) {
    video {
      display: none;
    }
  }

  @media only screen and (max-width: 619px) {
    min-height: 320px;
  }

  @media only screen and (max-width: 349px) {
    .btn {
      width: 100%;
      padding: 0 5px;
      font-size: 1.6rem;
    }
  }
}

.header-secondary {
  background-color: $color-black;
  font-size: 0;

  &::before {
    display: none;
  }

  .container {
    height: 100%;
  }

  .img {
    @include vertical-align;
    position: absolute;
    right: 50%;
    width: 65%;
    max-height: 80%;
    padding: 0 30px 0 20px;

    img {
      width: 90%;
      max-height: 90%;
    }
  }

  .info {
    @include vertical-align;
    position: absolute;
    right: 0;
    width: 50%;
    padding: 0 0 0 10px;
    font-size: 1.8rem;
    text-align: left;
    z-index: 2;

    h1 {
      margin-bottom: 2.5rem;
      color: $color-white;
      font-weight: 300;
      text-align: left;
    }

    p {
      margin-bottom: 2.5rem;
      text-align: left;
    }

    .btn-secondary {
      margin-right: 20px;
    }
  }

  &.header-blue {
    .img {
      z-index: 1;

      &::before {
        position: absolute;
        top: -12vw;
        left: -15%;
        width: 110%;
        height: 600px;
        background: url('../images/unified-protocol/bg-header.png') no-repeat center top;
        background-size: 100% auto;
        content: '';
        z-index: -1;
      }

      &::after {
        @include gradient-blue-radial-header;
        position: absolute;
        top: -140%;
        left: -150%;
        width: 400%;
        height: 380%;
        content: '';
        z-index: -1;
      }
    }
  }
  
  &-academy {
    position: relative;
    background-image: linear-gradient(to right, transparentize($color-academy-gradient1, .2) 0%, transparentize($color-academy-gradient2 , .2) 100%);
    color: $color-white;
    font-size: 1.4rem;
    text-align: center;
    
    &::before {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: url('../images/bg/academy-bg.jpg') 50% 0 / cover no-repeat;
      content: '';
      opacity: .7;
    }
    
    .container {
      max-width: 900px;
      z-index: 2;
      
      @media only screen and (max-width: 799px) {
        padding-top: 0;
        padding-bottom: 0;
      }
    }
    
    h1 {
      color: inherit;
    }
  }
  
  &-about {
    padding: 0;
    background: none;
    
    img {
      width: 100%;
      height: calc(100vh - 95px);
      min-height: 290px;
      object-fit: cover;
    }
  }

  @media only screen and (max-width: 1450px) {
    .img {
      left: 0;
      max-width: calc(100% - 562px);
      padding-left: 20px;
    }

    .info {
      width: 562px;
      padding-right: 20px;
    }
    
    &-about {
      img {
        max-height: 850px;
      }
    }
  }

  @media only screen and (max-width: 799px) {
    &::after {
      display: none;
    }

    .container {
      position: relative;
      top: auto;
      left: auto;
      height: 100%;
      padding-top: 50px;
      padding-bottom: 50px;
      transform: none;
    }

    .img {
      right: -20%;
      left: auto;
      width: 300px;
      max-width: 100%;
      padding: 0;
    }

    .info {
      position: relative;
      top: auto;
      width: 100%;
      max-width: 532px;
      padding: 0;
      float: left;
      transform: none;

      p {
        font-size: 1.6rem;
      }
    }
    
    &-about {
      img {
        height: calc(100vh - 66px);
        max-height: 680px;
      }
    }
  }

  @media only screen and (max-width: 659px) {
    .img {
      opacity: .35;
    }
  }

  @media only screen and (max-width: 619px) {
    .btn {
      width: 100%;

      &.btn-secondary {
        margin: 0 0 10px;
      }
    }
  }

  @media only screen and (max-width: 569px) {
    .info {
      max-width: 420px;

      p {
        margin-bottom: 2.5rem;
      }
    }
    
    &-about {
      img {
        height: calc(100vh - 60px);
        max-height: 360px;
      }
    }
  }

  @media only screen and (max-width: 399px) {
    .info {
      h1 {
        margin-bottom: 1.8rem;
      }
    }
  }
}
