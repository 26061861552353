//--------------------------------------------
// Happy Birthday Slotegrator
//--------------------------------------------
.hb-inner-page {
  .hb-header {
    padding-top: 250px;
    padding-bottom: 80px;
    &--bg {
      background: url("../images/HB_Slotegrator/bg1.png") no-repeat;
      background-size: cover;
    }
    .container {
      &::after {
        content: "";
        position: absolute;
        top: -225px;
        z-index: 0;
        right: -170px;
        width: 857px;
        height: 858px;
        background: url("../images/HB_Slotegrator/seven_bg.png") no-repeat;
        background-size: cover;
        @media only screen and (max-width: 1140px) {
          right: 50%;
          margin-right: -400px;
          opacity: 0.7;
        }
      }
    }
  }

  .hb-offer {
    text-align: left;
    // font-family: "SegoeUIRegular";
    width: 100%;
    max-width: 600px;
    z-index: 1;
    position: relative;
    margin-right: auto;
    color: #ffffff;
    @media only screen and (max-width: 1140px) {
      padding-top: 360px;
      margin: 0 auto;
    }
    &__subtitle {
      color: #ffffff;
      margin-bottom: 40px;
      font-size: 65px;
      font-weight: 600;
      text-transform: none;
      text-align: left;
      text-shadow: 4px 2px 10px #000000;
    }
    &__text {
      color: #ffffff;
      font-size: 21px;
      text-align: left;
      font-weight: 300;
      line-height: 1.2;
      text-shadow: 2px 2px 4px #000000;
      color: #ffffff;
      margin-bottom: 50px;
      span {
        color: #cd0048;
      }
    }
    .hb-offer__button {
      font-size: 18px;
      // font-family: "SegoeUILight";
      line-height: 50px;
      border-radius: 5px;
      font-weight: 400;
      width: 100%;
      color: #ffffff;
      max-width: 226px;
      height: 50px;
      background-color: #ff0106;
      transition: 0.2s ease-in-out;
      &:hover {
        background-color: #ff191d;
        box-shadow: 0 0 80px 1px #ff0106;
      }
    }
  }
  .hb-to-win {
    width: 100%;
    max-width: 600px;
    margin: 100px auto;
    position: relative;
    z-index: 1;
    h2 {
      color: #ffffff;
      font-size: 48px;
      font-weight: 600;
      width: 100%;
      text-transform: none;
    }
    &__wins {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    &__first {
      width: 160px;
      text-align: center;
      margin: 10px;
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      color: #ffffff;
      img {
        text-align: center;
        display: block;
        margin: auto;
      }
      p {
        // margin-top: auto;
        // padding: 35px 0;
        font-size: 18px;
        font-weight: 400;
        margin: auto;
      }

      .img-box {
        height: 100px;
        display: flex;
      }
      .text-box {
        height: 160px;
        display: flex;
      }
    }
  }
  .hb-block-info1 {
    position: relative;
    padding: 0;
    &--bg {
      background: url("../images/HB_Slotegrator/bg3.png") no-repeat;
      background-size: cover;
      background-position: center 80%;
    }
    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 0;
      z-index: 1;
      right: 0;
      height: 40px;
      opacity: 0.5;
      background: rgb(110, 1, 39);
      background: linear-gradient(
        180deg,
        rgba(110, 1, 39, 0) 0%,
        rgba(205, 2, 73, 1) 14%,
        rgba(44, 12, 86, 1) 40%,
        rgba(28, 8, 54, 0) 100%
      );
    }
    &__text {
      .wow {
        margin-bottom: 10px;
        color: #ffffff;
        text-align: center;
        position: relative;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 300;
        text-shadow: 4px 4px 10px #2e2d2d;
        z-index: 1;
        border-radius: 5px;
        background-image: linear-gradient(43deg, #d20147 0%, #290955 100%);
        z-index: 3;
        position: relative;
        top: -40px;
        padding: 115px 40px 67px 40px;
        margin-bottom: 70px;
        opacity: 0.95;
        &::before {
          content: "";
          position: absolute;
          top: -100px;
          left: 50%;
          background: url("../images/HB_Slotegrator/wow.png") no-repeat;
          background-size: cover;
          width: 368px;
          height: 217px;
          margin-left: -184px;
        }
        &::after {
          content: "";
          position: absolute;
          right: -120px;
          top: -150px;
          background: url("../images/HB_Slotegrator/fireworks.png") no-repeat;
          background-size: cover;
          width: 270px;
          height: 256px;
        }

       
        // &:nth-of-type(3){
        //     margin-bottom: 60px;
        // }
      }
      .title_logo {
        text-align: center;
        color: #ffffff;
        margin-bottom: 40px;
        font-size: 24px;
        line-height: 1.2;
        font-weight: 300;
        text-shadow: 4px 4px 10px #2e2d2d;
        z-index: 1;
      }
    }
    &__project {
      width: 100%;
      max-width: 880px;
      margin: 0 auto;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      padding: 20px 0;
      .project-block {
        align-items: center;
        width: 293px;
        padding: 10px 5px;
        color: #ffffff;
        display: flex;
        justify-content: flex-start;
        &__text {
          font-size: 30px;
          padding-left: 10px;
        }
      }
    }
    &__logo {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      z-index: 1;
      position: relative;
      margin-bottom: 80px;
      img {
        height: 55px;
        display: block;
        padding: 3px;
      }
    }
    &__partners {
      border-radius: 5px;
      background-image: linear-gradient(43deg, #d20147 0%, #290955 100%);
      z-index: 3;
      color: #ffffff;
      position: relative;
      bottom: -50px;
      padding: 75px 40px 67px 40px;
      // margin-bottom: 110px;
      text-align: center;
      opacity: 0.95;
      p {
        font-size: 24px;
        margin-bottom: 60px;
      }
      &::after {
        content: "";
        position: absolute;
        left: -175px;
        top: -100px;
        background: url("../images/HB_Slotegrator/fireworks2.png") no-repeat;
        background-size: cover;
        width: 270px;
        height: 256px;
      }
    }
    &__logo-partners {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      z-index: 1;
      position: relative;
      img {
        height: 39px;
        display: block;
        margin: 10px;
      }
    }
    &__line {
      height: 2px;
      margin-bottom: 50px;
      opacity: 0.55;
      background: rgb(255, 255, 255);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.3113620448179272) 0%,
        rgba(255, 255, 255, 1) 10%,
        rgba(255, 255, 255, 1) 90%,
        rgba(255, 255, 255, 0.30015756302521013) 100%
      );
    }
  }
  .hb-block-info2 {
    position: relative;
    padding: 180px 0;
    z-index: 1;
    &--bg {
      background: url("../images/HB_Slotegrator/bg4.png") no-repeat;
      background-size: cover;
      background-position: center;
    }
    &::before {
      content: "";
      position: absolute;
      top: -6px;
      left: 0;
      z-index: 2;
      right: 0;
      height: 40px;
      opacity: 0.5;
      background: rgb(110, 1, 39);
      background: linear-gradient(
        180deg,
        rgba(110, 1, 39, 0) 0%,
        rgba(205, 2, 73, 1) 14%,
        rgba(44, 12, 86, 1) 40%,
        rgba(28, 8, 54, 0) 100%
      );
    }
    &::after {
      content: "";
      position: absolute;
      right: 100px;
      bottom: -120px;
      background: url("../images/HB_Slotegrator/fireworks.png") no-repeat;
      background-size: cover;
      width: 270px;
      height: 256px;
    }
    &__title {
      z-index: 1;
      position: relative;
      h2 {
        color: #ffffff;
        font-weight: 600;
        font-size: 48px;
        text-transform: none;
        margin-bottom: 120px;
      }
    }
    &__wrap {
      position: relative;
      z-index: 1;
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
      .block {
        padding: 58px 20px;
        border-radius: 5px;
        margin: 10px 10px 50px;
        opacity: 0.9;
        background-image: linear-gradient(27deg, #d00146 0%, #2a0756 100%);
        height: 374px;
        width: 100%;
        max-width: 340px;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: -55px;
          left: 50%;
          width: 94px;
          margin-left: -47px;
          height: 106px;
          background: url("../images/HB_Slotegrator/hb_img1.png") no-repeat;
          background-size: cover;
        }
        .top-box {
          height: 70px;
          h3 {
            line-height: 1.2;
            color: #ffffff;
            text-align: center;
            font-size: 18px;
            margin-bottom: 36px;
            font-weight: 400;
          }
        }
        .bottom-box {
          font-size: 16px;
          p {
            line-height: 1.2;
            color: #ffffff;
            text-align: center;
            &:nth-of-type(1) {
              margin-bottom: 20px;
            }
            &:nth-of-type(2) {
              margin-bottom: 20px;
            }
          }
        }
      }
      .block2 {
        &::before {
          background: url("../images/HB_Slotegrator/hb_img2.png") no-repeat;
          background-size: cover;
          width: 82px;
          height: 84px;
          top: -45px;
          margin-left: -41px;
        }
      }
      .block3 {
        &::before {
          background: url("../images/HB_Slotegrator/hb_img3.png") no-repeat;
          background-size: cover;
          width: 100px;
          height: 87px;
          top: -50px;
          margin-left: -50px;
        }
      }
    }

    .container {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 50px;
        left: -180px;
        background: url("../images/HB_Slotegrator/serpantin1.png") no-repeat;
        background-size: cover;
        width: 586px;
        height: 798px;
        z-index: 0;
      }
      &::after {
        content: "";
        position: absolute;
        top: -140px;
        right: -250px;
        background: url("../images/HB_Slotegrator/tort.png") no-repeat;
        background-size: cover;
        width: 586px;
        height: 798px;
        z-index: 0;
      }
    }
  }
  .hb-block-form {
    padding-top: 200px;
    padding-bottom: 270px;
    position: relative;
    &--bg {
      background: url("../images/HB_Slotegrator/BG_form.png") no-repeat;
      background-size: cover;
      background-position: bottom center;
      // position: relative;
      margin-top: -40px;
    }
    &::before {
      content: "";
      position: absolute;
      top: 150px;
      left: -200px;
      background: url("../images/HB_Slotegrator/form_img1.png") no-repeat;
      background-size: cover;
      width: 550px;
      height: 863px;
      z-index: 1;
    }
    &::after {
      content: "";
      position: absolute;
      top: 150px;
      right: -200px;
      background: url("../images/HB_Slotegrator/form_img2.png") no-repeat;
      background-size: cover;
      width: 550px;
      height: 863px;
      z-index: 2;
    }
    .hb-container {
      z-index: 2;
      h2 {
        padding-top: 90px;
        text-align: center;
        padding-bottom: 50px;
        font-size: 48px;
        color: #ffffff;
        font-weight: 600;
        text-transform: none;
        padding-bottom: 0;
      }
      .hb-form-block {
        width: 100%;
        max-width: 620px;
        margin: 0 auto;
        position: relative;
        .hb-form {
          position: relative;
          z-index: 1;
          opacity: 1;
          transition: 0.5s ease-in-out;
          .fa-caret-down {
            color: #ffffff;
          }
          .questions {
            padding-top: 0;
          }
          .buttons {
            text-align: center;

            .btn {
              width: 266px;
              @media only screen and (max-width: 625px) {
                width: 100%;
              }
            }
          }
          .selected {
            margin-bottom: 80px;
            height: auto;
            padding-right: 40px;
            ul {
              background: #24005a;
              li {
                color: #ffffff;
              }
            }
          }
        }
        &__socials {
          padding: 40px;
          border-radius: 5px;
          position: absolute;
          top: 0;
          left: 0;
          transition: 0.5s ease-in-out;
          width: 100%;
          max-width: 620px;
          font-size: 18px;
          // height: 304px;
          background-image: linear-gradient(43deg, #d20147 0%, #290955 100%);
          text-align: center;
          color: #ffffff;
          h3 {
            font-size: 36px;
            text-align: center;
            color: #ffffff;
            margin-bottom: 20px;
          }
          .text1 {
            margin-bottom: 20px;
          }
          .text2 {
            margin-bottom: 20px;
          }
          .socials-items {
            margin: 0 auto;
            width: 200px;
            display: flex;
            align-items: center;
            justify-content: space-around;
            li a {
              width: 50px;
              display: block;
            }
          }
          .close-details {
            display: block;
            position: absolute;
            width: 30px;
            height: 30px;
            top: 15px;
            right: 15px;
            cursor: pointer;
            &::before,
            &::after {
              content: "";
              display: block;
              margin-top: 15px;
              width: 100%;
              border-bottom: 2px solid #f3eff5;
              transform: rotate(45deg);
            }
            &::after {
              margin-top: -2px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1380px) {
    .hb-block-form::before {
      display: none;
    }
    .hb-block-form::after {
      display: none;
    }
  }
  @media only screen and (max-width: 1140px) {
    .hb-block-info1__text p:nth-of-type(1) {
      font-size: 24px;
    }
    .hb-block-info2 .container::after {
      opacity: 0.7;
    }
    .hb-block-info2 .container::before {
      opacity: 0.7;
    }
  }
  @media only screen and (max-width: 768px) {
    .hb-header {
      padding-top: 150px;
    }
    .hb-offer__subtitle {
      font-size: 50px;
      text-align: center;
    }
    .hb-offer__text {
      text-align: center;
    }
    .hb-offer {
      text-align: center;
    }
    .hb-to-win h2 {
      font-size: 35px;
    }
    .hb-block-info1__text p:nth-of-type(1) {
      font-size: 20px;
    }
    .hb-block-info1__text p:nth-of-type(2) {
      font-size: 20px;
    }
    .hb-block-info1__partners p {
      font-size: 20px;
    }
    .hb-block-form .hb-container h2 {
      font-size: 35px;
    }
    .hb-block-info2__title h2 {
      font-size: 35px;
    }
  }
  @media only screen and (max-width: 500px) {
    .hb-offer__subtitle {
      font-size: 33px;
    }
    // .hb-header{
    //   padding-top: 80px;
    // }
    .hb-to-win h2 {
      font-size: 22px;
    }
    .hb-block-info1__text p:nth-of-type(1) {
      font-size: 18px;
    }
    .hb-block-info1__text p:nth-of-type(2) {
      font-size: 18px;
    }
    .hb-block-info1__partners p {
      font-size: 18px;
    }
    .hb-block-info2__title h2 {
      font-size: 32px;
    }
    .hb-block-info2 .container::after {
      opacity: 0.5;
    }
    .hb-block-info2 .container::before {
      opacity: 0;
    }
    .hb-block-form .hb-container h2 {
      font-size: 32px;
    }
    // .hb-block-form{
    //   padding-top: 40px;
    //   padding-bottom: 50px;
    // }
  }
}

.popup {
  position: fixed;
  opacity: 0;
  z-index: -1;
  display: flex;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);

  .hb-form-block__socials {
    margin: auto;
    padding: 40px;
    border-radius: 5px;
    transition: 0.5s ease-in-out;
    width: 100%;
    max-width: 620px;
    font-size: 18px;
    position: relative;
    // height: 304px;
    background-image: linear-gradient(43deg, #d20147 0%, #290955 100%);
    text-align: center;
    color: #ffffff;
    h3 {
      font-size: 36px;
      text-align: center;
      color: #ffffff;
      margin-bottom: 20px;
    }
    .text1 {
      margin-bottom: 20px;
    }
    .text2 {
      margin-bottom: 20px;
    }
    .socials-items {
      margin: 0 auto;
      width: 200px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      li a {
        width: 50px;
        display: block;
      }
    }
    .close-details {
      display: block;
      position: absolute;
      width: 30px;
      height: 30px;
      top: 15px;
      right: 15px;
      cursor: pointer;
      &::before,
      &::after {
        content: "";
        display: block;
        margin-top: 15px;
        width: 100%;
        border-bottom: 2px solid #f3eff5;
        transform: rotate(45deg);
      }
      &::after {
        margin-top: -2px;
        transform: rotate(-45deg);
      }
    }
  }
}
