form {
  display: inline-block;
  width: 100%;

  .input {
    display: block;
    position: relative;
    margin-bottom: 1rem;

    %input-textarea {
      @include font-main;
      display: block;
      width: 100%;
      height: 5rem;
      padding: 1.2rem 2rem;
      transition: border-color .2s ease;
      border: 1px solid $color-white-70;
      border-radius: .5rem;
      background-color: transparent;
      color: $color-white;
      font-size: 1.4rem;

      &::placeholder {
        color: $color-white-80;
        opacity: 1;
        font-weight: 300;
      }

      &:focus {
        border-color: $color-white-95;
      }
    }

    input {
      @extend %input-textarea;
    }

    textarea {
      @extend %input-textarea;
      min-width: 100%;
      max-width: 100%;
      height: 11rem;
      min-height: 11rem;
      max-height: 20rem;
    }

    .select {
      @extend %input-textarea;
      padding-top: 1.5rem;
      color: $color-white-80;
      text-align: left;
      font-weight: 300;
      cursor: pointer;

      &.selected {
        color: $color-white;
      }

      div {
        &.form-group {
          height: auto;
        }
      }

      ul {
        display: none;
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        padding: 7px 0;
        border-radius: 10px;
        background: $color-white;
        color: $color-grey-darker;
        z-index: 10;

        > li {
          display: block;
          padding: 7px 20px;
          font-weight: 300;

          &:hover {
            color: $color-red;
            cursor: pointer;
          }
        }
      }
    }

    .fa-caret-down {
      position: absolute;
      top: 16px;
      right: 20px;
      z-index: -1;

      &::before {
        font-size: 16px;
      }
    }

    .icon {
      position: absolute;
      top: 1.2rem;
      right: 2rem;
      color: $color-white;
      opacity: .6;
      pointer-events: none;

      i {
        &::before {
          font-size: 2rem;
        }
      }
    }

    .error-message,
    .help-block {
      display: none;
      padding: .4rem 2rem 0;
      transition: padding .3s;
      color: $color-red-dark;
      font-size: 1.2rem;
      text-align: left;
    }
    .error-news-message{
      color: #ffffff;
    }

    .help-block {
      display: block;

      &:empty {
        padding: 0;
      }
    }

    &.error,
    &.has-error,
    .has-error {

      %input-textarea {
        border-color: transparentize($color-red, .2) !important;

        &:focus {
          border-color: $color-red;
        }
      }

      input {
        @extend %input-textarea;
      }

      textarea {
        @extend %input-textarea;
      }

      .error-message,
      .help-block {
        display: block;
        text-align: left;
      }
    }
  }

  %error-success {
    .error-message,
    .help-block {
      display: block;
    }

    >div {
      >div {
        >div {
          width: 304px;
          height: 78px;
        }
      }
    }
  }

  [class*='-recaptcha'] {
    display: inline-block;
    width: 201px;
    max-height: 0;
    margin: 0;
    float: none;
    text-align: left;
    opacity: 0;

    .error-message,
    .help-block {
      display: none;
      height: auto;
      float: none;
      transition: .3s opacity .3s;
      opacity: 0;
      vertical-align: top;
    }

    >div {
      height: 5rem;
      @media (max-width: 519px) {
        max-width: 200px;
        margin: 0 auto;
      }

      >div {
        transform: scale(.669);
        transform-origin: 0 0;

        >div {
          width: 0;
          height: 0;
          transition: width .5s ease, height .5s ease;
          border-radius: 10px;
          overflow: hidden;
        }
      }
    }
  }
  
  // Checkboxes styling
  input {
    &[type='checkbox'] {
      width: 0;
      height: 0;
      appearance: none;
      
      +label {
        display: flex;
        position: relative;
        align-items: center;
        text-align: left;
        transition: color 250ms cubic-bezier(.4, 0, .23, 1);
        color: $color-grey2;
        font-weight: 300;
        
        &::before {
          display: flex;
          position: relative;
          top: -1px;
          align-items: center;
          justify-content: center;
          width: 16px;
          height: 16px;
          margin-right: 6px;
          transition: border 250ms cubic-bezier(.4, 0, .23, 1), background-color 250ms cubic-bezier(.4, 0, .23, 1);
          border: 2px solid $color-grey;
          border-radius: 3px;
          background: none;
          content: '';
          cursor: pointer;
        }
        
        >span {
          display: block;
          position: absolute;
          bottom: 0;
          left: 22px;
          width: 90%;
          height: 0;
          transition: height 300ms cubic-bezier(.4, 0, .23, 1);
          text-decoration: none;
          overflow: hidden;
          
          &::before {
            position: absolute;
            bottom: 0;
            left: 0;
            color: $color-black;
            font-weight: 300;
            content: attr(data-label);
          }
        }
        
        &:hover {
          color: $color-black;
        }
      }
      
      &:focus {
        +label {
          color: $color-black;
        }
      }
      
      &:checked {
        +label {
          >span {
            height: 100%;  
          }
          
          &::before {
            border: 8px solid $color-red;
            background-color: $color-red;
            animation: shrink-bounce 200ms cubic-bezier(.4, 0, .23, 1);
          }
          
          &::after {
            position: absolute;
            top: 50%;
            left: 2px;
            width: 11px;
            height: 8px;
            transform: rotate(45deg);
            transform-origin: 0% 100%;
            border-right: 2px solid transparent;
            border-bottom: 2px solid transparent;
            content: '';
            animation: checkbox-check 125ms 250ms cubic-bezier(.4, 0, .23, 1) forwards;
          }
        }
      }
    }
  }

  @keyframes shrink-bounce {
    0% {
      transform: scale(1);
    }
    33% {    
      transform: scale(.85);
    }
    100% {
      transform: scale(1);    
    }
  }
  
  @keyframes checkbox-check {
    0% {
      width: 0;
      height: 0;
      transform: translate3d(0, 0, 0) rotate(45deg);
      border-color: $color-white;
    }
    33% {
      width: 6px;
      height: 0;
      transform: translate3d(0, 0, 0) rotate(45deg);
    }
    100% {    
      width: 6px;
      height: 10px;
      transform: translate3d(0, -10px, 0) rotate(45deg);
      border-color: $color-white;
    }
  }

  &.focused {
    [class*='-recaptcha'] {
      opacity: 1;
      >div {
        >div {
          >div {
            width: 304px;
            height: 78px;
          }
        }
      }

      &.error,
      &.has-error,
      &.has-success {
        @extend %error-success;
        opacity: 1;
        display: inline-block;
        .error-message,
        .help-block {
          opacity: 1;
          @media (max-width: 519px) {
            text-align: center;
          }
        }
      }
    }
  }

  &.form-light {
    .input {
      %input-textarea {
        background-color: $color-white-80;
        color: darken($color-grey-darker, 20%);

        &::placeholder {
          color: $color-grey2;
          opacity: 1;
        }

        &:focus {
          background-color: $color-white-95;
        }
      }

      input {
        @extend %input-textarea;
      }

      textarea {
        @extend %input-textarea;
      }
    }
  }

  &.form-popup {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 670px;
    @media only screen and (max-width: 600px) {
      max-width: 320px;
      margin-left: auto;
      margin-right: auto;
    }
    >div {
      width: 100%;
    }
    .field-popup-accordion {
      .accordion {
        >li {
          border-radius: 0.5rem;
          &.show {
            .toggle {
              background-image: none;
              .fa-caret-down {
                transform: rotate(-180deg);
              }
            }
          }
        }
        .toggle {
          background-color: transparent;
          background-image: none;
          color: $color-white;
          border-radius: 0.5rem;
          text-align: left;
          .fa-caret-down {
            transition: transform 0.5s;
          }
        }
        .checkboxes-wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          padding-bottom: 1rem;
          background-color: transparent;
          overflow: auto;
          overflow-x: hidden;
          @media only screen and (max-width: 600px) {
            max-height: 300px;
            overflow-x: hidden;
          }
          .input-checkbox {
            width: 50%;
            @media only screen and (max-width: 600px) {
              width: 100%;
              overflow: hidden;
            }
            label {
              color: $color-white-80;
              margin-top: -6px;
              cursor: pointer;
              &:hover {
                color: $color-white;
              }
              >span {
                &::before {
                  color: $color-white;
                }
              }
            }
            input[type=checkbox] {
              background-color: transparent;
              border: 0;
              width: 0;
              height: 0;
              &:focus {
                +label {
                  color: $color-white;
                }
              }
            }
          }
        }
      }
    }
    .btn-primary {
      @media only screen and (max-width: 600px) {
        width: 100% !important;
      }
    }
  }

  &.form-with-borders {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 750px;
    margin: auto;
    
    .input {
      width: 33.33333%;
      padding: 5px;
      transition: width .5s ease, padding .5s ease;
      
      input,
      textarea {
        border-color: $color-grey;
      }
      
      &-textarea {
        width: 100%;
      }

      &.error,
      &.has-error,
      .has-error {
        input,
        textarea {
          border-color: transparentize($color-red, .2);
          
          &:focus {
            border-color: $color-red;
          }
        }
      }
    }
    
    .captcha {
      width: 0;
      height: 6rem;
      padding: 0;
    }
    
    .btn-primary {
      width: 100%;
    }
    
    &.focused {
      .input {
        width: 24%;
        
        &-textarea {
          width: 100%;
        }
      }
      
      .captcha {
        width: 28%;
        padding: 5px;
      }
    }
  }

  &.form-moscow {

    [class*='-recaptcha'] {
      display: none;
    }
    &.focused {
      [class*='-recaptcha'] {
        display: inline-block;
      }
    }
  }
  
  &.form-with-borders-vertical {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: auto;

    .accordion {
      >li {
        border: 1px solid $color-grey;
        .toggle {
          font-weight: 300;
          color: $color-grey2;
          border: 0 !important;
          background-image: none;
          .fa-caret-down {
            position: absolute;
            top: 18px;
            right: 20px;
            transition: transform 0.5s;
          }
        }
      }
      .show {
        .toggle {
          .fa-caret-down {
            transform: rotate(-180deg);
          }
        }
      }
      .checkboxes-wrap {
        .input-checkbox {
          input[type=checkbox] {
            background-color: transparent;
            border: 0;
          }
        }
      }
    }
    
    .input {
      width: 100%;
      
      input,
      textarea {
        border-color: $color-grey;
      }
    }
    
    &.focused {
      [class*='-recaptcha'] {        
        max-height: none;
      }
    }
  }
  
  &.form-search-main {
    max-width: 488px;
    
    input {
      border: solid 1px $color-grey;
      color: $color-black;
      
      &:focus {
        border: solid 1px $color-grey;
      }
      
      &::placeholder {
        color: $color-grey;
      }
    }
    
    .search-btn {
      @include vertical-align;
      position: absolute;
      right: 21px;
      background: none;
      
      &::before {
        color: $color-grey;
        font-size: 16px;
      }
    }
  }

  @media only screen and (min-width: 767px) {
    &.form-popup {
      .field-popup-name,
      .field-popup-email,
      .field-form-name,
      .field-form-email {
        width: 310px;
    }
    }
  }

  @media only screen and (max-width: 879px) {
    &.form-with-borders,
    &.form-with-borders.focused {
      .input {
        width: 50%;
        
        &-textarea {
          width: 100%;
        }
      }
    }
    
    &.form-with-borders {
      .input-btn {
        text-align: right;
      }

      .btn-primary {
        width: 201px;
      }
    }
    
    &.form-search-main {
      max-width: none;
    }
  }

  @media only screen and (max-width: 519px) {
    &[class*='-recaptcha'] {
      &.error,
      &.has-error {
        .error-message,
        .help-block {
          margin: .4rem 0 0;
        }
      }
    }

    &.form-with-borders,
    &.form-with-borders.focused,
    &.form-with-borders-vertical {
      .input {
        width: 100%;
        padding: 0;
        text-align: center;
      }
    }
    
    &.form-with-borders,
    &.form-with-borders-vertical {
      .captcha {
        height: auto;
        max-height: 0;
        margin-bottom: 0;
        transition: max-height .5s ease, margin-bottom .5s ease;
      }

      .btn-primary {
        width: 100%;
      }
      
      &.focused {
        .captcha {
          max-height: 20rem;
          margin-bottom: 1rem;
        }

        [class*='-recaptcha'] {
          max-height: 20rem;
          margin-bottom: 1rem;
          
          &.has-error {
            padding-bottom: 3rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    &[class*='-recaptcha'] {
      >div {
        height: 5.5rem;
      }
    }
  }
  &.fullAccessWebinar {
    @media only screen and (min-width: 575px) {
      .input-recaptcha {
        width: 65%;
      }
    }
    @media (min-width: 520px) and (max-width: 574px) {
      .input-recaptcha {
        width: 60%;
      }
    }
  }
  &.fullAccessAnalytical {
    @media only screen and (min-width: 575px) {
      .input-recaptcha {
        width: 65%;
      }
    }
    @media (min-width: 520px) and (max-width: 574px) {
      .input-recaptcha {
        width: 60%;
      }
    }
  }

  &.form-media-partners {
    max-width: 810px;
    .field-form-text {
      width: 100%;
    }
    .field-form-name {
      width: 38%
    }
    .field-form-email {
      width: 38%;
    }
    .field-form-recaptcha {
      width: 0;
    }
    .error-message {
      margin: 0 0 -15px 0px;
    }
    .btn-primary {
      width: 21%;
      margin-top: 4px;
    }
    &.focused {
      .field-form-text {
        width: 100%;
      }
      .field-form-name {
        width: 26%;
      } 
      .field-form-email {
        width: 26%;
      } 
      .field-form-recaptcha {
        width: 27%;
      }
    }
  }
}
