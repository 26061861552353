.page-licensed-casino{
    .header-secondary .img img {
        width:100%;
    }
    .header-secondary .img {
        top: 48%;
       
    }
    .header-secondary .info h1 {
        margin-bottom: 50px;
        text-transform: none;
        line-height: 40px;
        font-size: 34px;
    }
    .header-secondary .info p {
        font-size: 18px;
        line-height: 21px;
        &:nth-of-type(2){
            margin-bottom: 40px;
        }
    }
    @media only screen and (max-width: 799px){
        .header-secondary{
            overflow: visible;
        }
        .header-secondary .img {
           z-index: 2;
            top:85%; 
            width: 427px;
        }
        .licensed-container{
            padding-bottom: 280px;
        } 
        img{
            max-width:427px;
        }  
    }
    @media only screen and (max-width: 1139px){
        .header-secondary .info h1{
            font-size: 30px;
            line-height: 35px;
        }
    }
    @media only screen and (max-width: 767px){
        .header-secondary .info h1{
            font-size: 24px;
            line-height: 28px;
        }
    }
    @media only screen and (max-width: 659px){
        .header-secondary .img {
            opacity: 1;
            width: 427px; 
            top: 88%;
            max-width:427px;   
         }
    }
    @media only screen and (max-width: 619px){
        .header-secondary .info {
            max-width:100%;
            display: flex;
            flex-direction: column;
            h1{
                text-align: center;
                font-size: 24px;
                line-height: 28px;
                font-weight: 300;
            }
            p{
                text-align: center;
            }
            .btn{
                &:nth-of-type(1){
                    order: 2;
                    margin-bottom: 0;
                }
                &:nth-of-type(2){
                    margin-bottom: 20px;
                }
            }
        }
    }
    @media only screen and (max-width: 420px){
        .container{
            padding-left: 20px;
            padding-right: 20px;
        } 
        .header-secondary .info {
            h1{
                max-width: 230px;
                margin-bottom: 40px;
            }
            p{
                font-size: 14px;
                line-height: 16px;
            }
        }
        .header-secondary .img {
            right: -40%;    
         }
    }
    
    .product-info{
        h2{
            color:#00175F;
            font-size: 34px;
            max-width: 100%;
            text-transform: none;
            line-height: 40px;
            @media only screen and (max-width: 1139px){
                font-size: 30px;
                line-height: 35px;
        }
        @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 28px;
            }
        }
        @media only screen and (max-width: 919px){
            padding-bottom: 0;
        }
        @media only screen and (max-width: 419px){
           h2{
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 40px;
           }
        }
    }
    .licensed-info{
        @media only screen and (max-width: 1139px){
            padding-bottom: 0;
        }
        @media only screen and (max-width: 659px){
           padding-top: 70px; 
        }

    }
    .requirements{
        display: flex;
        font-size: 18px;
        .licensed-block{
            font-size: 18px;
            color:#2D2D2D;
            width:100%;
            max-width: 653px;
            p{
                font-size: 18px;
                line-height: 21px;
                font-weight: 300;
                margin-bottom: 24px;
                @media only screen and (max-width: 1139px){
                    font-size: 16px;
                    line-height: 19px;
                }
                @media only screen and (max-width: 767px){
                    font-size: 14px;
                    line-height: 16px;
                }
            }
            ul{
               list-style-type: disc;
               padding-left: 65px;
               display: flex;
               flex-direction: column;
               justify-content: center;
               @media only screen and (max-width: 419px){
                padding-left: 20px;
             } 
                li{
                    font-size: 18px;
                    color: red;
                    font-weight: 300;
                    line-height: 21px;
                    margin-bottom: 20px;
                    @media only screen and (max-width: 1139px){
                        font-size: 16px;
                        line-height: 19px;
                    }
                    @media only screen and (max-width: 767px){
                        font-size: 14px;
                        line-height: 16px;
                    }
                    span{
                        color: #2D2D2D;
                    }
                    strong{
                        font-weight: 400 !important;
                    }
                }
            } 
        }
        .img{
            margin-left: 125px;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 362px;
            img{
                margin-bottom: 44px;
            }
            .picker2{
                margin-bottom: 0;
            }
        }
        // @media only screen and (max-width: 1139px){
        //     .img{
        //         text-align: center;
        //         margin-left: 0;
        //         position: relative;
        //         z-index: 1;
                
        //         img{
        //             width:100%;
        //         }
        //     }
        // }
        @media only screen and (max-width: 1139px){
            flex-wrap: wrap;
            .licensed-block{
                max-width: 100%;
            }
            .img{
                width:100%;
                flex-direction: row;
                justify-content: space-around;
                text-align: center;
                margin-left: 0;
                position: relative;
                z-index: 1;
                top: 95px;
                img{
                    width:100%;
                    margin-bottom: 0;
                }
                .picker2{
                    margin-bottom: 0;
                }
            }
        }
        
        @media only screen and (max-width: 767px){
            .img{
                flex-direction: column;
                top: 60px;
                img{
                    margin-bottom: 44px;
                }
            }
        }
        @media only screen and (max-width: 419px){
            // .licensed-block{
            //     p{
            //         font-size: 14px;
            //     }
            //     ul>li{
            //         font-size: 14px;
            //     }  
            // }
            .img{
                width:60%;
                margin: 0 auto;
                img{
                    margin-bottom: 20px;
                }
            }
        }
    }
   .gambling-licenses{
       padding-top: 130px;
       padding-bottom: 60px;
       h2{
        color: #00175F;
        text-transform: none;
        margin-bottom: 60px;
        @media only screen and (max-width: 1139px){
            font-size: 30px;
            line-height: 35px;
        }
        @media only screen and (max-width: 767px){
                font-size: 24px;
                line-height: 28px;
        }
       }
       p{
           font-size: 18px;
           line-height: 21px;
           @media only screen and (max-width: 1139px){
            font-size: 16px;
            line-height: 19px;
        }
        @media only screen and (max-width: 767px){
            text-align: center;
             font-size: 14px;
             line-height: 16px;
        }
       }
   }
   .country-licenses{
        background: rgba(242, 242, 242, 0.6);
        padding-top: 48px;
        padding-bottom: 89px;
        .flags{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            margin-bottom: 44px;
            @media only screen and (max-width:1250px){
                width: 690px;
                margin: 0 auto;
            }
            @media only screen and (max-width:799px){
                width: 100%;
            }
            .wrap-box{
                position: relative;
                flex-basis: 170px;
                margin-bottom: 32px;
                img{
                    cursor: pointer;
                    filter: grayscale(2);
                    opacity: .5;
                    margin: 0 auto 18px;
                    transition: .1s ease-in;
                    &:hover{
                        filter: none;
                        opacity: 1;
                    }
                }
                p{
                    text-align: center;
                    font-size: 14px;
                    font-weight: 300;
                    line-height: 16px;
                }
                .wrap-box__img{
                    width: 125px;
                    margin: 0 auto;
                    position: relative;
                    @media only screen and (max-width: 879px){
                        margin: 0 auto 45px;
                        width:100%;
                    }
                } 
                .wrap-box__popup{
                    font-size: 14px;
                    border-radius: 4px;
                    background: #fff;
                    padding: 10px 10px;
                    width:250px;
                    position: absolute;
                    top:120px;
                    left: 50%;
                    z-index: 3;
                    display: none;
                    transition: .1s ease-in;
                    transform: translateX(-50%);
                    box-shadow: 1px 1px 10px 1px #000;
                    &::before{
                        background-color: #fff;
                        content: '';
                        display: block;
                        width: 15px;
                        height: 15px;
                        position: absolute;
                        top: 0px;
                        left: 50%;
                        transform: rotateZ(45deg) translateX(-50%);
                    }
                    span{
                        display: block;
                        font-weight: 600;
                        font-size: 14px;
                        margin-bottom: 5px;
                    }
                // @media only screen and (max-width:879px){
                //         display: none !important;
                //     }  
                }
                &:hover .wrap-box__popup{
                    display: block;
                }
                @media only screen and (min-width:880px){
                    flex-basis: 170px;
                    
                }
                @media only screen and (max-width:879px){
                    flex-basis: 100%;
                    
                }
            } 
            .slick-dots li button:before {
                font-size: 30px;
                color: #D70F0F;
            }
            .slick-dots li.slick-active button:before{
                color: #D70F0F;
            }
            // .slick-dots{
            //     bottom: 190px;
            // }
   
        }
        .comission{
            margin: 0 auto;
            width:100%;
            max-width:1050px;
            font-size: 18px;
            h3{
                font-size: 18px;
                font-weight: 500;
                margin-bottom: 20px;
                line-height: 21px;
                @media only screen and (max-width: 1139px){
                    font-size: 16px;
                    line-height: 19px;
                }
                @media only screen and (max-width: 767px){
                    text-align: center;
                     font-size: 14px;
                     line-height: 16px;
                }
            }
            p{
                line-height: 21px;
                font-size: 18px;
                @media only screen and (max-width: 1139px){
                    font-size: 16px;
                    line-height: 19px;
                    text-align: left !important;
                }
                @media only screen and (max-width: 767px){
                    text-align: center !important;
                     font-size: 14px;
                     line-height: 16px;
                }
            }
            @media only screen and (min-width: 880px){
               display: none;
            }
        }
    }
    .cost-licenses{
        padding-top: 60px;
        padding-bottom: 100px;
        .block-box{
            h3{
                font-size: 18px;
                line-height: 21px;
                font-weight: 500;
                margin-bottom: 25px;
                @media only screen and (max-width: 1139px){
                    font-size: 16px;
                    line-height: 19px;
                }
                @media only screen and (max-width: 767px){
                    text-align: center;
                     font-size: 14px;
                     line-height: 16px;
                }
            }
            p{
                font-size: 18px;
                line-height: 21px;
                margin-bottom: 60px;
                @media only screen and (max-width: 1139px){
                   font-size: 16px;
                   line-height: 19px;
               }
               @media only screen and (max-width: 767px){
                   text-align: center;
                    font-size: 14px;
                    line-height: 16px;
               }
            }
            &__button{
                text-align: center;
                a{
                    text-align: center;
                }
            }    
        }
    }
    //Button
    .licenses-btn{
        font-size: 16px;
        padding: 0 13px;
        font-weight: 300;
        height: 5rem;
    }
    .obtaining-license{
        background: radial-gradient(1479.74px at 59.79% 46.15%, #002583 0%, #000428 100%);
        h2{
            color: #fff;
            font-size: 34px;
            line-height: 40px;
            font-weight: 300;
            text-transform: none;
            margin-bottom: 60px;
            @media only screen and (max-width: 1139px){
                font-size: 30px;
                line-height: 35px;
            }
            @media only screen and (max-width: 767px){
                    font-size: 24px;
                    line-height: 28px;
            }
        }
        p{
            color:#fff;
            text-align: left;
            max-width: 100%;
             @media only screen and (max-width: 1139px){
                 text-align: center;
                font-size: 16px;
                line-height: 19px;
            }
            @media only screen and (max-width: 767px){
                    font-size: 18px;
                    line-height: 21px;
            }
        }
        &__items{
            display: flex;
            justify-content: space-around;
            position: relative;
            margin-bottom: 60px;
            &::before{
                content: '';
                position: absolute;
                top: 73px;
                left: 0;
                width:100%;
                border: 2px solid rgba(255, 255, 255, 0.25);
                // @media only screen and (max-width: 1139px){
                //     top: 350px;
                //     left: -353px;
                //     transform: rotate(90deg);
                // }
            }
            .items-left, .items-middle, .items-right{
                width: 263px;
                margin: 0 5px;
                display: flex;
                flex-direction: column;
                align-items: center;
                &__top{
                    // color: #D60100;
                    color: rgba(255, 255, 255, 0.25);
                    font-size: 24px;
                    line-height: 28px;
                    font-weight: 900;
                    padding: 17px 0;
                }
                [data-aos="new-animation"] {
                    &.aos-animate {
                    color: red;  
                    }
                }
                &__middle{
                    // width:32px;
                    // height: 32px;
                    position: relative;
                    width:24px;
                    height: 24px;
                    border-radius: 50%;
                    z-index: 0;
                    // border: 3px solid #d50000;
                    background: #001860;
                    border: 2px solid rgba(255, 255, 255, 0.25);
                    box-sizing: border-box;
                    margin-bottom: 20px;
                }
                &__title{
                    color: #fff;
                     font-size: 18px;
                     font-weight: 500;
                     line-height: 21px;
                }
                &__bottom{
                    position: relative;
                    top: -20px;
                    left: 0;
                    width: 263px;
                    background: #fff;
                    height: 320px;
                    border-radius: 10px;
                    padding: 30px 12px 20px;
                    h3 {
                        height: 49px;
                        text-align: left;
                        color: #001454;
                        font-size: 18px;
                        line-height: 21px;
                        font-weight: 500;
                        margin-bottom: 20px;
                        span{
                            font-size: 24px;
                            line-height: 28px;
                            font-weight: 900;
                            color: #D60100;
                        }
                    }    
                    p{
                        margin-bottom: 0;
                        font-size: 16px;
                        line-height: 20px;
                        font-weight: 300;
                        color: #1B1B1B;
                    }
                    &::before{
                        position: absolute;
                        z-index: 1;
                        top: -49px;
                        left: 116px;
                        content: '';
                        width:32px;
                        height: 32px;
                        border: 3px solid #d50000;
                        border-radius: 50%;
                        background: #001860;    
                    }
                }
            }
        }
        @media only screen and (max-width: 1139px){
            &__items{
                flex-direction: column;
                padding-left: 100px;
                &::before{
                    content: '';
                    position: absolute;
                    top: -20px;
                    bottom: 0;
                    left: 28px;
                    width:4px;
                    
                }
                .items-left{
                    position: relative;
                    flex-direction: column;
                    &__middle{
                        display: none;
                    }
                    &__top{
                        padding: 14px 0;
                    }
                    &__top, &__title{
                        width:100%;
                        text-align: left;
                    }
                }
                .items-left__bottom{
                    top: -66px;
                }
                .item-right-card{
                    top: -87px !important;
                }
                .items-left__bottom::before{
                    left: -91px;
                    top: 0;
                }
                .items-left, .items-middle, .items-right{
                    position: relative;
                    &::after{
                    content:'';
                    position: absolute;
                    left: -87px;
                    top: 14px;
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    z-index: 0;
                    background: #001860;
                    border: 2px solid rgba(255, 255, 255, 0.25);
                    box-sizing: border-box;
                    }
                }
            }
        }
        @media only screen and (max-width: 430px){
            &__items{
                padding-left: 30px;
                &::before{
                    left: 0;
                }
            }
            .items-left__bottom{
               width: 245px;
               left: -12px;
                p{
                    font-size: 14px;
                }
                &::before{
                    left: -45px;
                }
            }
            .items-left, .items-middle, .items-right{
                &::after{
                    left: -45px; 
                }
            }
        }
    }
    .licensed-products-list{
        h2{
            color: #00175F;
            text-transform: none;
            @media only screen and (max-width: 1139px){
                font-size: 30px;
                line-height: 35px;
            }
            @media only screen and (max-width: 767px){
                    font-size: 24px;
                    line-height: 28px;
            }
        }
        .products{
            display: flex;
            justify-content: space-around;
            flex-wrap: wrap;
            li{
                width: 205px;
                @media only screen and (max-width: 419px){
                    width: 50%;
                }
            }
        }
    }
}
