@keyframes bounce {
  0% {
    transform: translateY(0%);
  }

  15% {
    transform: translateY(-20%);
  }

  40% {
    transform: translateY(0%);
  }

  65% {
    transform: translateY(-10%);
  }

  90% {
    transform: translateY(0%);
  }

  100% {
    transform: translateY(0%);
  }
}

@keyframes bounceShadow {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  15% {
    transform: scale(.9);
    opacity: .7;
  }

  40% {
    transform: scale(1);
    opacity: 1;
  }

  65% {
    transform: scale(.95);
    opacity: .85;
  }

  90% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}
