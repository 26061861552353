.header-solutions {
    padding-bottom: 0;
    background: url('../images/new_solutions-compared/1.png') no-repeat;
    background-size: cover;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    &__title {
        font-size: 48px;
        font-weight: 300;
        line-height: 56px;
        color: #ffffff;
        position: relative;
        text-transform: none;
        margin-bottom: 34px;
        max-width:100%;
        z-index: 1;
        text-shadow: 1px 0px 2px rgb(0, 0, 0), -1px 0px 2px rgb(0, 0, 0),0px 2px 10px rgb(0, 0, 0);
        @media only screen and (max-width: 1179px){
            font-size: 28px;
         }
        @media only screen and (max-width: 767px){
           font-size: 22px;
        }
  }
  .title-ru{
      @media only screen and (max-width:430px){
      width:100%;
      max-width:230px;
      margin-left:auto;
      margin-right:auto;
      text-align: center;

      }
  }
    &__info{
        max-width: 648px;
        text-align: center;
        margin: 0 auto;
        color: #ffffff;
        position: relative;
        z-index: 1;
        p{
            padding-bottom: 20px;
            font-size: 16px;
            line-height: 19px;
            font-weight: 300;
            text-shadow: 1px 0px 2px rgb(0, 0, 0), -1px 0px 2px rgb(0, 0, 0),0px 2px 10px rgb(0, 0, 0);
        }
        .btn_casino{
            max-width: 200px;
            width: 100%;
            text-transform: none;
        }
        @media only screen and (max-width: 1179px){
            padding-bottom: 0;
            max-width: 571px;
                p{
                padding-bottom: 60px;
                }
            }
        @media only screen and (max-width: 767px){
            padding-bottom: 0;
        }
    }
    &__logo-casino{
        // background-color: #000000;
        width: 100%;
        padding: 0;
        background-size: cover;
        background-position: 50% 64%;
        z-index: 1;
        position: absolute;
        bottom: 0;
        left: 0;
        .fa-angle-left{
            left: 5px;
            display: none;
            &:active {
                outline: none;
                outline-offset: 0;
                background-color: transparent;
            }
        }
        .fa-angle-right{
            right: 0;
            display: none;
        }
        .slick-arr::before{
            line-height: 7rem;
            color: #ff0000;
            cursor: pointer;
        }
        ul{
            display: flex;
            justify-content: space-around;
            align-items: center;
            flex-wrap: wrap;
            padding-left: 58px;
            padding-right: 58px;
            @media only screen and (max-width: 1179px){
                padding-left: 20px;
                padding-right: 20px;
            }
            li{
                // padding: 10px;
                margin: 0 auto;
            }
            a{
                width:80%;
                display: block;
                margin: 0 auto;
            }
        }
        .shadow{
            padding: 60px 0 20px;
            background: linear-gradient(180deg, rgba(3, 0, 0, 0) 0%, rgba(3, 0, 0, 0.29) 49.48%, #030000 100%);
            @media only screen and (max-width: 767px){
                padding: 0 0 20px;
            }
        }
            ul{
                max-width: 100%;
                margin: 0 auto;
                @media only screen and (max-width: 1439px){
                    width:767px;
                }
            }
            .logo-casino__slider{
                img{
                    margin: 0 auto;
                }
            }
    }
    @media only screen and (max-width: 1179px){
        // padding-top: 46px;
       
       
    }
    @media only screen and (max-width: 767px){
        // padding-top: 104px;
        &__title{
            line-height: 26px;
        }
    }
}

.page-solutions-compared{

    .offer-solutions-compared{
        background: #ffffff;
        padding-top: 55px;
        padding-bottom: 50px;
        .container-offer{
            width: 1260px;
        }
        .offer-card-wrap{
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            .offer-card{
                margin: 25px 10px 43px;
                width:230px;
                padding: 29px 5px 33px;
                height:auto;
                box-shadow: 0px 4px 10px rgba(220, 220, 220, 0.8);
                display: flex;
                align-items: center;
                flex-direction: column;
                border-radius: 10px;    
                transform: scale(1);
                transition: .2s ease-in-out;
                &:hover .btn{
                    opacity: 1;
                }
                &:hover{
                    transform: scale(1.1);
                    box-shadow: 0px 0px 5px rgba(220, 220, 220, 1);
                }
                .btn{
                    width:100%;
                    opacity: 0;
                    max-width:155px;
                    height: 4rem;
                    line-height: 40px;
                    margin-top: auto;  
                    text-transform: none;
                    transition: .1s ease-in-out;
                    @media only screen and (max-width:767px){
                        opacity: 1;
                    }
                }
                .offer-logo{
                    margin-bottom: 34px;
                    img{
                        width:81px;
                        height: 81px;
                    }
                }
                h3{
                    text-transform: uppercase;
                    margin-bottom: 19px;
                    font-size: 20px;
                    line-height: 23px;
                    font-weight: normal;
                }
                p{
                    height:140px;
                    text-align: center;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 19px;
                    margin-bottom: 10px;
                    @media only screen and (max-width:1270px){
                        height: 110px;
                    }
                    @media only screen and (max-width:1179px){
                        height: 110px;
                    }
                    @media only screen and (max-width:767px){
                        height: 95px;
                    }
                   
                }
                @media only screen and (max-width:1179px){
                        width: 187px;
                        .offer-logo{
                            margin-bottom: 22px;
                        }
                        .btn{
                            width: 130px;
                            height: 34px;
                            line-height: 32px;
                            text-transform: none;
                        }
                    h3{
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 11px;
                    }
                    p{
                        font-size: 12px;
                        padding-left: 8px;
                        padding-right: 8px;
                    }
                }
            }
            div.slick-list.draggable{
                padding: 0 15px !important;
            }
            .slick-dots li button:before{
                color: #D70F0F;
            }
            .slick-dots li.slick-active button:before{
                color: #D70F0F;
            }
            .slick-dots{
                bottom: -12px;
            }
            .slick-dots li button:before{
                font-size: 33px;
            }
           
        }
        ul.slick-dots{
                    @media only screen and (min-width:1281px){
                        display: none;
                    } 
                }
    }

    .gaming-software{
        padding-top: 50px;
        padding-bottom: 185px;
        @media only screen and (max-width:767px){
            padding-bottom: 115px;
        }
        .container-gaming-software{
            width: 1380px;
            padding: 0 10px;
        }
        .wrap{
            margin: 0 auto;
            text-align: center;
            width: 100%;
            max-width: 861px;
            color: #000;
            h2{
                margin-bottom: 34px;
                font-weight: 300;
                font-size: 30px;
                line-height: 35px;
                color: #000;
            }
            p{
                font-weight: 300;
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 70px;
            }
        }
        .cross-function{
            margin-bottom: 75px;
            .block{
                width:460px;
            }
            display: flex;
            .block-left{
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    li{
                        font-size: 18px;
                        line-height: 21px;
                        color: #110367;
                        margin-bottom: 16px;
                        text-align: center;
                        padding:8px 20px;
                        border: 0.5px solid #2F227A;
                        box-sizing: border-box;
                        border-radius: 20px;
                        cursor: pointer;
                        transition: .2s ease-in-out;
                        &:nth-of-type(2){
                            margin-right: 80px;
                        }
                        &:nth-of-type(3){
                            margin-right: 100px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(4){
                            margin-right: 160px;
                        }
                        &:nth-of-type(5){
                            margin-right: 180px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(6){
                            margin-right: 160px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(7){
                            margin-right: 140px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(8){
                            margin-right: 80px;
                            padding:8px 15px;
                        }
                        &:hover{
                            background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                            color: #fff;
                            font-weight: normal;
                        }
                    }
                }
            }
            .block-left-ru{
                ul{
                    li{
                        &:nth-of-type(4){
                            margin-right: 103px;
                        }
                        &:nth-of-type(5){
                            margin-right: 60px;
                        }
                        &:nth-of-type(6){
                            margin-right: 103px;
                        }
                        &:nth-of-type(7){
                            margin-right: 33px;
                        }
                        &:nth-of-type(8){
                            margin-right: 60px;
                        }
                    }
                }
            }
            .block-middle{
                img{
                   width: 430px; 
                }
                
            }
            .block-right{
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    li{
                        font-size: 18px;
                        line-height: 21px;
                        color: #110367;
                        text-align: center;
                        margin-bottom: 20px;
                        padding:8px 20px;
                        border: 0.5px solid #2F227A;
                        box-sizing: border-box;
                        border-radius: 20px;
                        cursor: pointer;
                        transition: .2s ease-in-out;
                        &:nth-of-type(2){
                            margin-left: 100px;
                        }
                        &:nth-of-type(3){
                            margin-left: 140px;
                        }
                        &:nth-of-type(4){
                            margin-left: 160px;
                        }
                        &:nth-of-type(5){
                            padding:8px 15px;
                        }
                        &:nth-of-type(6){
                            margin-left: 160px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(7){
                            margin-left: 140px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(8){
                            margin-left: 80px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(9){
                            margin-left: 0px;
                            padding:8px 15px;
                        }
                        &:hover{
                            background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                            color: #fff;
                            font-weight: normal;
                        }
                    }
                }
            }
            .block-right-ru{
                ul{
                    li{
                        &:nth-of-type(4){
                            margin-left: 45px;
                        }
                        &:nth-of-type(6){
                            margin-left: 100px;
                        }
                        &:nth-of-type(7){
                            margin-left: 80px;
                        }
                        &:nth-of-type(8){
                            margin-left: 60px;
                        }
                    }
                }
            }
        }
        .cross-function__btn{
            text-align: center;
            a{
                width: 200px;
                text-transform: none;
            }
        }
        .cross-function-mb{

            .img img{
                margin: 0 auto;
                width: 100%;
                max-width: 258px;
            }
            .cross-function-mb__list{
                 width: 100%;
                //  max-width: 767px;
                span{
                    display: inline-block;
                    margin:0 3px 10px;
                    padding: 8px;
                    border: 0.5px solid #2F227A;
                    border-radius: 20px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 16px;
                    text-align: center;
                    color: #110367;
                    cursor: pointer;
                    transition: .2s ease-in-out;
                    &:hover{
                        background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                        color: #fff;
                    }
                    // @media only screen and (max-width:480px){
                    //     &:nth-of-type(16){
                    //         margin-right: 130px;
                    //     }
                    //     &:nth-of-type(17){
                    //         margin-left: 130px;
                    //     }
                    //     &:nth-of-type(18){
                    //         margin-right: 175px;
                    //     }
                    // }
                }
            }
        }
        @media only screen and (min-width: 768px){
             .cross-function-mb{
               display: none;
           } 
        }

        @media only screen and (max-width: 1179px){
           padding-top: 0;
           .wrap h2{
               text-align: center;

           }
           .wrap p{
               text-align: center;
               margin-bottom: 60px;
           }
           .cross-function{
               display: none;
           }
           .cross-function__btn{
            a{
               display: none;
            }
        }
           .cross-function-mb{
               display: block;
           }
           .img {
                margin-bottom: 28px;
            }
            .cross-function-mb{
                .cross-function__btn{
                    a{
                        display: block;
                        margin: 0 auto;
                        text-transform: none;
                    }
                }
                .cross-function-mb__list{
                    margin-bottom: 60px;
                    text-align: center;
                }
            }
        }
        @media only screen and (max-width: 767px){
            .wrap h2{
                margin-left: 0;
                line-height: 26px;
                font-size: 22px;
            }
            .wrap p{
                font-size: 14px;
                line-height: 20px;
            }
          }
    }

    .personalized{
        background: url('../images/new_solutions-compared/2.png') no-repeat 50% 50%;
        background-size: cover;
        padding: 120px 0 120px;
        position: relative;
        @media only screen and (max-width: 767px){
            padding: 160px 0 50px
        }
        .container-personalized{
            width: 1260px;
            padding:0 20px;
        }
        .custom-bg{
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            background: linear-gradient(transparent,rgba(0,0,0, .1));
            @media only screen and (max-width: 1179px){
                z-index: 1;
            background: linear-gradient(transparent, rgba(0,0,0, .4),rgba(0,0,0, .9), rgba(0,0,0, .9));
            }
            // @media only screen and (max-width: 767px){
            //     z-index: 1;
            // background: linear-gradient(transparent,rgba(0,0,0, .9), rgba(0,0,0, 1));
            // }
            @media only screen and (max-width: 379px){
                z-index: 1;
                background: linear-gradient(transparent, rgba(0, 0, 0 ,0.7 ), rgba(0, 0, 0, 0.9));
            }
        }
        .custom-wrap{
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1179px){
                flex-direction: column;
            }
        
            .custom-info {
                font-size: 16px;
                margin-right: 20px;
                width: 47%;
                z-index: 1;
                color: #ffffff;
                h2{
                    font-size: 36px;
                    text-align: left;
                    line-height: 42px;
                    color: #ffffff;
                    margin-left: 0;
                    margin-bottom: 25px;
                    @media only screen and (max-width: 767px){
                        font-size: 22px;
                        line-height: 26px;
                        text-align: center;
                    }
                }
                p{
                    max-width: 584px;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: normal;
                    margin-bottom: 43px;
                }
                &__btn{
                    text-align: left;
                    a{
                        width: 200px;
                        text-transform: none;
                    }
                    @media only screen and (max-width: 767px){
                       text-align: center;
                    }
                }
    
                @media only screen and (max-width: 1179px){
                    width: 100%;
                    margin-top: 0;
                    order: 1;
                    z-index: 1;
                    padding: 0 40px;
                }
                @media only screen and (max-width: 767px){
                    padding: 0;
                    margin-right: 0;
                    p{
                        text-align: center;
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }
            .custom-img {
                position: relative;
                width: 600px;
                 @media only screen and (max-width: 1179px){
                   order:0;
                }
                // @media only screen and (max-width: 767px){
                //     width: 100%;
                //  }
                img{
                    width: 100%;
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: -321px;
                    right: -63px;
                    background: url('../images/new_solutions-compared/Screens.png') no-repeat;
                    background-size: contain;
                    width: 100%;
                    height: 605px; 
                    order:0; 
                    @media only screen and (max-width: 1179px){
                        right: 0;
                        top: -244px;
                     }
                     @media only screen and (max-width: 767px){
                        right: 110px;
                        top: - 212px;
                        width: 373px;
                     }
                    
                }
               
            }
            .custom-img-ru{
                &::before{
                    top: -312px;
                    @media only screen and (max-width: 1179px){
                        right: 0;
                        top: -244px;
                     }
                     @media only screen and (max-width: 767px){
                        right: 110px;
                        top: - 212px;
                        width: 373px;
                     }
                }
            }
            .games-img{
                position: relative;
                width: 550px;
                 @media only screen and (max-width: 1179px){
                   order:0;
                }
                // @media only screen and (max-width: 767px){
                //     width: 100%;
                //  }
                img{
                    width: 100%;
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: -275px;
                    right: 0;
                    background: url('../images/slotegrator-casino/Group.png') no-repeat;
                    background-size: contain;
                    width: 100%;
                    height: 550px; 
                    order:0; 
                    @media only screen and (max-width: 1179px){
                        top: -190px;
                     }
                     @media only screen and (max-width: 767px){
                        top: -150px;
                     }
                     @media only screen and (max-width: 379px){
                        top: -160px;
                     }
                }
            }
        }
    }

    .online-casino-games{
        padding-top: 100px;
        padding-bottom: 120px;
        .container-gaming-software{
            width: 1380px;
        }
        .wrap{
            margin: 0 auto;
            text-align: center;
            width: 100%;
            max-width: 861px;
            color: #000;
            h2{
                margin-bottom: 34px;
                font-weight: 300;
                font-size: 30px;
                line-height: 35px;
                color: #000;
            }
            p{
                font-weight: 300;
                font-size: 18px;
                line-height: 30px;
                margin-bottom: 65px;
            }
            @media only screen and (max-width: 767px){
                h2{
                    font-size: 22px;
                    line-height: 26px;
                }
                p{
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }
        .cross-function{
            margin-bottom: 50px;
            display: flex;
            align-items: center;
            .block{
                width:460px;
            }
            .block-left{
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    li{
                        font-size: 18px;
                        line-height: 21px;
                        color: #110367;
                        margin-bottom: 16px;
                        padding:8px 20px;
                        border: 0.5px solid #2F227A;
                        box-sizing: border-box;
                        border-radius: 20px;
                        cursor: pointer;
                        transition: .2s ease-in-out;
                        &:nth-of-type(2){
                            margin-right: 40px;
                        }
                        &:nth-of-type(3){
                            margin-right: 100px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(4){
                            margin-right: 160px;
                        }
                        &:nth-of-type(5){
                            margin-right: 180px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(6){
                            margin-right: 160px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(7){
                            margin-right: 140px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(8){
                            margin-right: 100px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(9){
                            margin-right: 60px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(10){
                            margin-right: 0;
                            padding:8px 15px;
                        }
                        &:hover{
                            background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                            color: #fff;
                            font-weight: normal;
                        }
                    }
                }
                .fix.fix.fix.fix{
                    margin-right: 60px;
                }
            }
            .block-middle{
                img{
                   width: 460px; 
                }
                
            }
            .block-right{
                ul{
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    li{
                        font-size: 18px;
                        line-height: 21px;
                        color: #110367;
                        margin-bottom: 16px;
                        padding:8px 20px;
                        border: 0.5px solid #2F227A;
                        box-sizing: border-box;
                        border-radius: 20px;
                        cursor: pointer;
                        transition: .2s ease-in-out;
                        &:nth-of-type(2){
                            margin-left: 60px;
                        }
                        &:nth-of-type(3){
                            margin-left: 100px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(4){
                            margin-left: 160px;
                        }
                        &:nth-of-type(5){
                            margin-left: 180px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(6){
                            margin-left: 160px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(7){
                            margin-left: 140px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(8){
                            margin-left: 100px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(9){
                            margin-left: 60px;
                            padding:8px 15px;
                        }
                        &:nth-of-type(10){
                            margin-left: 30px;
                            padding:8px 15px;
                        }
                        &:hover{
                            background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                            color: #fff;
                            font-weight: normal;
                        }
                    }
                }
            }
            @media only screen and (max-width:1179px){
                display: none;
            }
        }
        .cross-function__btn{
            text-align: center;
            a{
                width: 200px;
                text-transform: none;
            }
        }
        @media only screen and (min-width:767px){
            .cross-function-mb{
                display: none;
            }
        }
        @media only screen and (max-width:1179px){
            padding: 41px 10px 50px;
            .wrap p{
                text-align: center;
            }
            .cross-function__btn{
                a{
                    display: none;
                }
            }
            .img { 
                margin-bottom: 30px;
                img{
                margin: 0 auto;
                width: 284px;
                } 
            }
            .cross-function-mb{
                display: block;
                @media only screen and (max-width: 1179px){
                   .cross-function__btn{
                       a{
                           display: block;
                           margin: 0 auto;
                       }
                   }
                }
            }
            .cross-function-mb__list{
                width: 100%;
                // max-width: 767px;
                text-align: center;
               span{
                   display: inline-block;
                   margin:0 3px 10px;
                   padding: 8px;
                   border: 0.5px solid #2F227A;
                   border-radius: 20px;
                   font-weight: 400;
                   font-size: 14px;
                   line-height: 16px;
                   text-align: center;
                   color: #110367;
                   cursor: pointer;
                   transition: .2s ease-in-out;
                   &:hover{
                       background: linear-gradient(270deg, #6733AA 0%, #281171 100%);
                       color: #fff;
                   }
               }
               @media only screen and (max-width: 1179px){
                  margin-bottom: 50px; 
               }
           }
        }
        @media only screen and (max-width: 767px){
            .wrap h2{
                text-align: center;
            }
            .wrap p{
                text-align: center;
            }
         }
    }

    .exstra-services{
        background: url('../images/new_solutions-compared/3.png') no-repeat 50% 50%;
        background-size: cover;
        padding: 120px 0 120px;
        position: relative;
        @media only screen and (max-width: 1179px){
            padding: 177px 0 60px;
        }
        .container-personalized{
            width: 1260px;
            padding:0 20px;
        }
        .custom-bg{
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            position: absolute;
            z-index: 1;
            background: linear-gradient(transparent,rgba(0,0,0, .1));
            @media only screen and (max-width: 1179px){
                z-index: 1;
            background: linear-gradient(transparent, transparent,rgba(0,0,0, .9), rgba(0,0,0, .9));
            }
            // @media only screen and (max-width: 767px){
            //     z-index: 1;
            // background: linear-gradient(transparent,rgba(0,0,0, .9), rgba(0,0,0, 1));
            // }
            @media only screen and (max-width: 379px){
                z-index: 1;
            background: linear-gradient(transparent,transparent, rgba(0,0,0, 09), rgba(0,0,0, .9), rgba(0,0,0, .9));
            }
        }
        .custom-wrap{
            display: flex;
            align-items: center;
            @media only screen and (max-width: 1179px){
                flex-direction: column;
            }
        
            .custom-info {
                font-size: 16px;
                padding-right: 20px;
                width: 52%;
                z-index: 1;
                color: #ffffff;
                h2{
                    font-size: 36px;
                    text-align: left;
                    color: #ffffff;
                    margin-left: 0;
                    margin-bottom: 30px;
                    @media only screen and (max-width: 767px){
                        font-size: 22px;
                        text-align: center;
                        line-height: 26px;
                    }
                }
                p {
                    max-width: 584px;
                    font-size: 16px;
                    line-height: 19px;
                    font-weight: normal;
                    margin-bottom: 43px;
                    @media only screen and (max-width: 767px){
                        max-width: 100%;
                        text-align: center;
                    }
                }
                .custom-info__btn{
                    a{
                        width: 200px;
                        text-transform: none;
                    }
                    @media only screen and (max-width: 767px){
                        text-align: center;
                    }
                }
    
                @media only screen and (max-width: 1179px){
                    width: 100%;
                    margin-top: 120px;
                    order: 1;
                    z-index: 1;
                    padding: 0 40px;
                }
                @media only screen and (max-width: 767px){
                    padding: 0;
                }
                @media only screen and (max-width: 379px){
                    margin-top: 130px;
                 }
            }
            .custom-img {
                position: relative;
                width: 550px;
                 @media only screen and (max-width: 1179px){
                   order:0;
                }
                // @media only screen and (max-width: 767px){
                //     width: 100%;
                //  }
                img{
                    width: 100%;
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: -255px;
                    right: 0;
                    background: url('../images/slotegrator-casino/Customization.png') no-repeat;
                    background-size: contain;
                    width: 100%;
                    height: 550px; 
                    order:0; 
                    @media only screen and (max-width: 1179px){
                        top: -140px;
                     }
                     @media only screen and (max-width: 767px){
                        top: -95px;
                     }
                     @media only screen and (max-width: 379px){
                        top: -100px;
                     }
                }
               
            }
            .exstra-services-img{
                position: relative;
                width: 586px;
                 @media only screen and (max-width: 1179px){
                   order:0;
                   display: none;
                }
                img{
                    width: 100%;
                }
                &::before{
                    content: "";
                    position: absolute;
                    top: -125px;
                    left: 0;
                    background: url('../images/new_solutions-compared/mga-services.png') no-repeat;
                    background-position-x: right;
                    background-size: contain;
                    width: 100%;
                    height: 232px; 
                    order:0; 
                    @media only screen and (max-width: 1179px){
                        top: -190px;
                     }
                     @media only screen and (max-width: 767px){
                        top: -150px;
                     }
                     @media only screen and (max-width: 379px){
                        top: -160px;
                     }
                }
            }
            .exstra-services-img-wrap{
                    @media only screen and (max-width: 767px){
                        width:100%;
                        display: flex;
                        justify-content: center;
                    }
                .exstra-services-img-group{
                    width: 100%;
                    display: none;
                    position: absolute;
                    top: 40px;
                    left: 20px;
                    div:nth-of-type(1){
                        margin-bottom: 20px;
                    }
                    @media only screen and (max-width: 1179px){
                        width: 100%;
                        position: absolute;
                        top: -185px;
                        padding: 0 60px;
                        left: 0;
                        display: flex;
                        justify-content: space-between;
                    }
                    @media only screen and (max-width: 767px){
                        width:264px;
                        flex-direction: column;
                        left:50%;
                        margin-left: -132px;
                        
                    }
                }
            }    
        }
    }

    .solution-compared-table{
        padding-top: 105px;
        padding-bottom: 100px;
        position: relative;
        .line{
            position:absolute;
            left: 0;
            right: 0;
            top: 221px;
            bottom: 0;
            height: 49px;
            width:100%;
            background: rgba(229, 229, 229, .25);
            z-index: 1;
        }
        .container-table{
            width: 1510px;
            padding:0 20px;
            @media only screen and (max-width:767px){
                width: 667px;
            }
        }
        &__wrap{
            .line-box{
                li:nth-of-type(odd){
                    position: relative;
                    &::before{
                        // z-index: 1 !important;
                        content:'';
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        left: -100vw;
                        right: -100vw;
                        background: rgba(0, 0, 0, 0.006);
                        @media only screen and ( max-width:1240px){   
                            left: 0;
                            right: 0;
                            display: none;
                        }
                    }
                }
                li:nth-of-type(even){
                   position: relative;
                   &::before{
                    // z-index: 1 !important;
                    content:'';
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    left: -100vw;
                    right: -100vw;
                    background: rgba(255, 255, 255, 0.04);
                    @media only screen and ( max-width:1240px){ 
                        left: 0;
                        right: 0;
                        display: none;
                    }
                }
                }
               
            }
            display: flex;
            // flex-wrap: wrap;
            margin-bottom: 70px;
            
            .block-first{  
                // flex-basis: 15%;
                width:15%;
                margin-right: 20px;
                &__title{
                    padding: 30px 0 31px;
                        div{
                            font-size: 24px;
                            line-height: 28px;
                            font-weight: 500;
                            text-transform: uppercase;
                            &:nth-of-type(1){
                                color: #D70F0F;   
                                 
                            }
                            &:nth-of-type(2){
                                color: #110367;
                                
                        }
                        @media only screen and (max-width:767px){
                            font-size: 14px;
                        }
                    }    
                }
                ul{
                    color:#000;
                    li{
                       display: block;
                       padding: 15px 0; 
                       font-size: 16px;
                       line-height: 19px;
                       font-weight: 400;
                    }
                }
                @media only screen and (max-width:767px){
                    width:27%;
                    ul{
                       li{
                        font-size: 12px;
                        } 
                    }
                    
                }
            }
            .solution-compared-block{
                width: 85%;
                display: flex;
                flex-wrap: wrap;
                .block{
                    padding: 0 17px 0;
                    width: 20%;
                    .block-box{
                    background: rgba(205, 4, 0, 0.85);
                    color: #fff;
                    text-align: center;
                    border-top-left-radius: 40px;
                    border-bottom-right-radius: 40px;

                    &__title{   
                        div{
                            text-align: center;
                            padding: 48px 0;
                            font-size: 18px;
                            line-height: 21px;
                            font-weight: 600;
                            @media only screen and (max-width:767px){
                                font-size: 14px;
                            }
                        }
                    }
                    ul{
                        & span, & img{
                            position: relative;
                            z-index: 2;
                        }
                        li{
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            // text-align: center;
                            // padding:13px 0; 
                            // font-size: 16px;
                            line-height: 49px;
                            // font-weight: 500;
                            img{
                                // position: relative;
                                // z-index: 10;
                                display: block;
                                margin: 0 auto;
                                padding: 13px 0;
                            } 
                            @media only screen and (max-width:1240px){
                                &:nth-of-type(odd){
                                    background: rgba(0, 0, 0, 0.1);
                                }
                                &:nth-of-type(even){
                                    background: rgba(255, 255, 255, 0.25);
                                }
                            }                                
                        }                      
                    }
                    li{
                    &:nth-of-type(odd){
                        background: #CD0400;
                    }
                } 
                } 
                .blue{
                    background: rgba(17, 3, 103, 0.85);
                    li{
                        &:nth-of-type(odd){
                            background: #110367;
                        }
                    }
                    }  
                }
                div.slick-list.draggable{
                    padding: 0 15px !important;
                    margin-bottom: 30px;
                }
                .slick-dots li button:before{
                    color: #D70F0F;
                }
                .slick-dots li.slick-active button:before{
                    color: #D70F0F;
                }
                .slick-dots{
                    bottom: -30px;
                }
                .slick-dots li button:before{
                    font-size: 33px;
                }
                @media only screen and (max-width:767px){
                    width: 360px;
                }
                @media only screen and (max-width:520px){
                    width: 220px;
                }

            }
          
        }
        &__btn{
            text-align: center;
            a{
                width:200px;
                text-transform: none;
            }
        }
    }
}