.products {
  display: block;
  width: calc(100% + 30px);
  margin-right: -15px;
  margin-left: -15px;
  font-size: 0;
  text-align: center;

  li {
    display: inline-block;
    width: 33.333%;
    padding: 15px;
    font-size: 1.8rem;
    vertical-align: top;

    &.no-hover {
      h3,
      p {
        opacity: .3;
      }
    }

    &.hover {
      .icon {
        a {
          .ico-dark {
            opacity: 1;
          }

          .ico-light {
            opacity: 0;
          }
        }
      }

      h3 {
        a {
          color: $color-red;
        }
      }
    }
  }

  .icon {
    display: block;
    position: relative;
    height: 130px;
    margin-bottom: 1rem;

    a {
      &:active {
        img {
          transform: scale(.98);
          transition: all 0s;
        }
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-height: 72%;
    margin: auto;
    transition: all .4s ease;

    &.ico-dark {
      opacity: 0;
    }

    &.ico-light {
      opacity: 1;
    }
  }

  h3 {
    margin-bottom: 1.5rem;
    transition: all .6s ease;
    font-size: 1.6rem;
    text-transform: uppercase;

    a {
      transition: all .4s ease;
      color: $color-grey-darker;
    }
  }

  p {
    transition: all .6s ease;

    a {
      color: $color-grey-darker;
    }
  }

  &.slider {
    width: calc(100% - 120px);
    margin-right: auto;
    margin-left: auto;

    img {
      max-width: 55%;
      max-height: 70%;
    }

    .slick-track {
      margin: 0 auto;
    }

    // .slick-arrow {
    //   position: absolute;
    //   top: 0;
    //   bottom: 0;
    //   width: 2.5rem;
    //   height: 4rem;
    //   margin: auto;
    //   transition: transform .2s ease, color .2s ease;
    //   background-color: transparent;
    //   color: $color-grey;

    //   &::before {
    //     height: 4rem;
    //     font-size: 5.5rem;
    //     line-height: 4rem;
    //   }

    //   &:hover {
    //     transform: scale(1.1);
    //     color: $color-red;
    //   }

    //   &:active {
    //     transform: scale(1);
    //     transition: transform 0s;
    //   }

    //   &.fa-angle-left {
    //     left: -50px;
    //   }

    //   &.fa-angle-right {
    //     right: -50px;
    //   }
    // }
  }

  @media only screen and (max-width: 879px) {
    &.slider {
      img {
        max-width: 61%;
        max-height: 63%;
      }
    }
  }

  @media only screen and (max-width: 799px) {
    h3 {
      margin-bottom: 1rem;
      font-size: 1.4rem;
    }

    p {
      font-size: 1.4rem;
    }

    img {
      max-width: 35%;
      max-height: 62%;
    }
  }

  @media only screen and (max-width: 699px) {
    img {
      max-height: 70%;
    }

    &.slider {
      img {
        max-height: 57%;
      }
    }
  }

  @media only screen and (max-width: 619px) {
    li {
      width: 50%;
    }
  }

  @media only screen and (max-width: 519px) {
    img {
      max-height: 60%;
    }

    &.slider {
      width: calc(100% - 60px);

      .icon {
        height: 100px;
      }

      img {
        max-width: 47%;
        max-height: 72%;
      }

      .slick-arrow {
        &.fa-angle-left {
          left: -25px;
        }

        &.fa-angle-right {
          right: -25px;
        }
      }
    }
  }

  @media only screen and (max-width: 479px) {
    img {
      max-width: 38%;
      max-height: 62%;
    }
  }

  @media only screen and (max-width: 449px) {
    img {
      max-width: 50%;
    }

    &.slider {
      img {
        max-width: 50%;
        max-height: 60%;
      }
    }
  }

  @media only screen and (max-width: 399px) {
    li {
      padding: 5px 15px;
    }

    p {
      display: none;
    }

    img {
      max-width: 55%;
      max-height: 55%;
    }

    &.slider {
      width: calc(100% - 45px);

      .icon {
        height: 75px;
      }

      img {
        max-width: 70%;
        max-height: 75%;
      }

      .slick-arrow {
        &::before {
          height: 3.6rem;
          font-size: 5.2rem;
          line-height: 3.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: 349px) {
    .icon {
      height: 100px;
    }

    img {
      max-width: 65%;
      max-height: 67%;
    }

    h3 {
      font-size: 1.1rem;
    }
  }
}
