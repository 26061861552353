.photo-galery {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 90px;
}

.photo {
  position: relative;
  margin-bottom: 15px;
  
  &:hover {
    .photo-inner {
      opacity: 1;
    }
  }
  
  &-wide {
    width: 65.79%;
  }
  
  &-square {
    width: 32.89%;
    
    &-small {
      width: 31.58%;
    }
  }
  
  &-inner {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: opacity .5s;
    background-color: $color-blue-darker;
    opacity: 0;
    
    .plus {
      @include total-center;
      position: absolute;
      width: 66px;
      height: 66px;
      border: 3px solid $color-white;
      border-radius: 50%;
      cursor: pointer;
      
      &::before,
      &::after {
        @include total-center;
        display: block;
        position: absolute;
        width: 58.35%;
        height: 5%;
        border-radius: 3px;
        background-color: $color-white;
        content: '';
      }
      
      &::after {
        transform: rotate(90deg) translate(-50%, -50%);
        transform-origin: 0 0;
      }
    }
    
    &-text {
      display: flex;
      position: absolute;
      bottom: 0;
      left: 0;
      flex-direction: column;
      justify-content: flex-end;
      width: 100%;
      height: calc(50% - 33px);
      padding: 15px 18px;
      color: $color-white;
      
      a {
        text-decoration: underline;
        
        &:hover {
          text-decoration: none;
        }
      }
    }
  }
  
  &-title {
    margin-bottom: 5px;
    font-size: 1.6rem;
    font-weight: 700;
  }
  
  @include breakpoint(989px) {
    &-inner {
      .plus {
        top: 45%;
        width: 40px;
        height: 40px;
        border-width: 2px;
      }
      
      &-text {
        a {
          font-size: 1.2rem;
        }
      }
    }
    
    &-title {
      font-size: 1.4rem;
      line-height: 1.06;
    }
  }
  
  @include breakpoint(767px) {
    width: 100%;
    max-width: 375px;
    margin-right: auto;
    margin-left: auto;
    
    &-wide,
    &-square-small {
      display: none;
    }
    
    &-inner {
      .plus {
        top: 45%;
        width: 60px;
        height: 60px;
        border-width: 3px;
      }
    }
  }
  
  // @media (hover: none) {
  //   >a {
  //     z-index: -1;
  //   }
    
  //   &-inner {
  //     display: none;
  //     opacity: 1;
  //   }
  // }
}

// Magnific popup custom styles
.mfp {
  &-bg {
    background-color: $color-blue-dark;
  }
  
  &-content {
    .close {
      top: 10px;
      right: 0;
      left: auto;
      width: 40px;
    }
    
    .mfp-img {
      padding: 60px 40px 40px;
    }
  }
  
  &-figure {
    // -webkit-tap-highlight-color: transparent;
    // tap-highlight-color: transparent;
    
    &::after {
      top: 60px;
      right: 40px;
      left: 40px;
    }
  }
  
  &-bottom-bar {
    padding: 0 40px;
  }
  
  &-counter {
    right: 40px;
  }
  
  &-arrow {
    transition: transform .2s ease, opacity .2s ease;
    color: $color-grey;
    
    &:hover {
      transform: scale(1.1);
    }
    
    &::before {
      font-size: 5.5rem;
    }
  }
  
  // Zoom animation
  &-with-zoom {
    .mfp-container,
    &.mfp-bg {
      transition: opacity .3s ease-out;
      opacity: 0; 
    }
    
    &.mfp-ready {
      .mfp-container {
        opacity: 1;
      }
    }
    
    &.mfp-bg {
      opacity: .95;
    }
    
    &.mfp-removing {
      .mfp-container, 
      &.mfp-bg {
        opacity: 0;
      }
    }
  }
  
  // Zoom out animation
  &-zoom-out {
    // start state
    .mfp-with-anim {
      transform: scale(1.3); 
      transition: transform .3s ease-in-out, opacity .3s ease-in-out;
      opacity: 0;
    }
    
    &.mfp-bg {
      transition: transform .3s ease-out, opacity .3s ease-out;
      opacity: 0;
    }
    
    // animate in
    &.mfp-ready {
      .mfp-with-anim {
        transform: scale(1); 
        opacity: 1;
      }
      
      &.mfp-bg {
        opacity: .95;
      }
    }
    
    // animate out
    &.mfp-removing {
      .mfp-with-anim {
        transform: scale(1.3); 
        opacity: 0;
      }
      
      &.mfp-bg {
        opacity: 0;
      }
    }
  }
  
  @include breakpoint(989px) {
    &-arrow {
      color: $color-white;
      text-shadow: 0 0 6px $color-black;
      opacity: 1;
      
      &:hover {
        transform: scale(.75);
      }
    }
  }
  
  @include breakpoint(659px) {
    &-content {
      .mfp-img {
        padding-right: 0;
        padding-left: 0;
      }
      
      .close {
        opacity: 1;
      }
    }
    
    &-bottom-bar {
      max-height: 36px;
      padding: 0;
    }
    
    &-counter {
      right: 0;
    }
  }
  
  @media only screen and (orientation: landscape) and (max-height: 640px) {
    &-content {
      .mfp-img {
        padding-top: 5px;
        padding-right: 40px;
        padding-left: 40px;
      }
      
      .close {
        top: 0;
      }
    }
    
    &-bottom-bar {
      max-height: 36px;
      padding-left: 40px;
    }
    
    &-counter {
      right: 40px;
    }
  }
}
