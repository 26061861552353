.navbar-fixed {
  display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 65px;
  border-top: 2px solid $color-black;
  background-color: $color-black3;
  color: $color-grey-light;
  font-family: 'Open Sans', sans-serif !important;
  font-size: 16px;
  z-index: 51;

  .container {
    padding: 25px 0 10px;
  }

  * {
    vertical-align: top;
  }

  .control-left {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    padding-right: 25px;

    &::after {
      position: absolute;
      top: -17px;
      right: 0;
      width: 1px;
      height: 65px;
      background-color: $color-black;
      content: '';
    }

    a {
      position: relative;
      margin-left: 26px;
      color: $color-grey-light;
      text-decoration: none;

      &::before {
        position: absolute;
        top: -2px;
        left: -23px;
        transition: left .3s ease;
      }

      &:hover {
        opacity: .8;

        &::before {
          left: -26px;
        }
      }
    }
  }

  .fa-pencil {
    display: inline-block;
    position: relative;
    margin: 0 10px 0 26px;
    color: $color-grey-light;

    &::before {
      position: absolute;
      top: -2px;
      left: -23px;
      transition: all .3s ease;
    }
  }

  .switcher {
    display: inline-block;
    margin-top: -6px;

    [type='checkbox'] {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      visibility: hidden;
      pointer-events: none;
    }

    .content {
      display: inline-block;
    }

    .slider {
      display: inline-block;
      position: relative;
      width: 50px;
      height: 25px;
      border: 2px solid $color-black;
      border-radius: 12.5px;
      background-color: $color-grey;
      cursor: pointer;
      overflow: hidden;
      box-sizing: border-box;

      &::before,
      &::after {
        position: absolute;
        height: 25px;
        transition: left .4s ease, background-color .4s ease;
        content: '';
        box-sizing: border-box;
      }

      &::before {
        top: 0;
        left: -12.5px;
        width: 30px;
        background-color: $color-red;
      }

      &::after {
        top: -2px;
        left: -2px;
        width: 25px;
        border: 2px solid $color-black;
        border-radius: 12.5px;
        background-color: $color-grey-light;
      }

      &:hover {
        &::after {
          background-color: $color-white;
        }
      }

      &.checked {
        &::after {
          left: 23px;
        }

        &::before {
          left: 0;
        }
      }
    }
  }

  .logout-right {
    display: inline-block;
    float: right;

    a {
      position: relative;
      margin-left: 26px;
      color: $color-grey-light;
      text-decoration: none;

      &::before {
        position: absolute;
        top: -2px;
        left: -23px;
        transition: left .3s ease;
        font-size: 20px;
      }

      &:hover {
        opacity: .8;

        &::before {
          left: -26px;
        }
      }
    }
  }

  .fa {
    font-family: 'Open Sans', sans-serif !important;

    &::before {
      @include font-awesome;
      font-size: 18px;
    }
  }
}
