// HELPERS
* {
  outline: none;
  box-sizing: border-box;
/*  backface-visibility: hidden;*/

  &::before,
  &::after {
    box-sizing: border-box;
    backface-visibility: hidden;
  }
}


.banner-in-sidebar {
  margin-top: 50px;
  img {
    margin: auto;
  }
}

.news-announcement {
  padding: 0 0 25px 0;
  text-align: justify;
}

a {
  color: inherit;
  font-weight: 300;
  text-decoration: none;

  &:focus {
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }
}

img {
  display: block;
  max-width: 100%;
}

html {
  body {
    padding: 0 !important;
    background-color: $color-white;
    color: $color-black;
    overflow-x: hidden;
  }

  &.open,
  &.open-mobile {
    position: relative;
    width: 100%;
    overflow: hidden;

    body {
      position: relative;
      width: 100%;
      overflow: hidden;
    }
  }

  @media only screen and (max-width: 989px) {
    body {
      width: 100%;
    }
  }
}

.container {
  position: relative;
  width: 1140px;
  max-width: 100%;
  margin: 0 auto;
  padding: 0;

  &::after {
    @include clear;
  }

  &.page {
    margin-top: 190px;
    margin-bottom: 50px;
    line-height: 1.6em;

    h1 {
      max-width: 100%;
      margin: 0 auto 65px;
      line-height: 1.29;
      text-align: center;
      @media only screen and (max-width: 480px) {
        font-size: 2.4rem;
      }
    }
  }

  @media only screen and (max-width: 1179px) {
    width: 100%;
    padding: 0 20px;
  }

  @media only screen and (max-width: 799px) {
    &.page {
      margin-top: 70px;
    }
  }

  @media only screen and (max-width: 519px) {
    padding: 0 10px;
  }
}

select {
  opacity: 0;
}

// Details columns
.details {
  @media only screen and (max-width: 989px) {
    .col {
      h3 {
        padding: 0 !important;
      }

      p {
        padding: 0 !important;
      }

      &-02 {
        width: 100% !important;
        margin-bottom: 3rem;
        float: none !important;

        &.col-img {
          margin-bottom: 0;
        }
      }

      &-03 {
        &:nth-of-type(1),
        &:nth-of-type(2) {
          width: 50% !important;
          margin-bottom: 5rem;
        }

        &:nth-of-type(3) {
          width: 100% !important;
        }
      }

      &-04 {
        width: 50% !important;
      }

      &-img {
        padding: 0 20px !important;
        text-align: center;

        img {
          display: inline-block !important;
          width: auto !important;
          max-width: 100% !important;
          margin: 0 !important;
          float: none !important;
        }
      }
    }
  }

  @media only screen and (max-width: 499px) {
    .col {
      width: 100% !important;
    }
  }

  @media only screen and (max-width: 479px) {
    %full-width {
      width: 100% !important;
    }

    %margin-bottom-0 {
      margin-bottom: 0;
    }

    .col-03 {
      margin-bottom: 5rem;

      &:nth-of-type(1) {
        @extend %full-width;
        @extend %margin-bottom-0;
      }

      &:nth-of-type(2) {
        @extend %full-width;
      }

      &:last-of-type {
        @extend %margin-bottom-0;
      }
    }

    .col-04 {
      @extend %full-width;
    }
  }
}
// Light menu-primary

@media only screen and (min-width: 130px){
  .menu-primary>li>a[href="https://slotegrator.com/uslugi/platezhnye-sistemy.html"],
    .menu-primary>li>a[href="https://slotegrator.com/services/payment-systems.html"],
    .menu-primary>li>a[href="https://slotegrator.com/servicios/sistemas-de-pago.html"] {
      color: #343434;
    }
    .menu-primary>li>a[href="https://slotegrator.com/uslugi/platezhnye-sistemy.html"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/services/payment-systems.html"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/servicios/sistemas-de-pago.html"]:hover {
      color: #d50000;
    }

    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"],
    .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"],
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"],
    .menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"],
    .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"] {
      color: #343434;
	}

	.menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"],
    .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"] {
		position: relative;
	}

    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"]:hover,
	.menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"]:hover {
      color: #d50000;
    }

    .menu-primary>li>a[href="https://slotegrator.com/infographics/"]:hover,
    .menu-primary>li>a[href="https://slotegrator.com/infografika/"]:hover{
      color: #27d2fe;
    }

    .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"]:before {
      // content: '• ';
      display: inline-block;
      font-size: 25px;
      margin-right: 6px;
      vertical-align: initial;
      transform: scale(1.7);
      color: red;
	}

	.menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"]:before,
    .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"]:before {
		content: 'NEW';
		font-size: 10px;
		padding: 1px 3px 1px 2px;
		vertical-align: initial;
		position: absolute;
		top: 6px;
		right: -35px;
		color: #fff;
		background: red;
		border-radius: 2px;
	  }



    .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"]:before {
    -webkit-animation: blink-animation 1s infinite;
    animation: blink-animation 1s infinite;
  }

  @keyframes blink-animation {
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes blink-animation {
    to {
      opacity: 1;
    }
  }
  }

@media only screen and (min-width: 1130px) {
  .menu-primary>li>a[href="https://slotegrator.com/uslugi/platezhnye-sistemy.html"],
  .menu-primary>li>a[href="https://slotegrator.com/services/payment-systems.html"],
  .menu-primary>li>a[href="https://slotegrator.com/servicios/sistemas-de-pago.html"] {
    color: #ffffff;
    text-shadow: 0 0 6px #12b59c, 0 0 12px #12b59c, 0 0 24px;
    transition: text-shadow 0.5s;
  }
  .menu-primary>li>a[href="https://slotegrator.com/uslugi/platezhnye-sistemy.html"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/services/payment-systems.html"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/servicios/sistemas-de-pago.html"]:hover {
    color: #ffffff;
    text-shadow: 0 0 3px #15caae, 0 0 6px #15caae, 0 0 12px #15caae, 0 0 24px #15caae, 0 0 48px #15caae;
  }

  .menu-primary>li>a[href="https://slotegrator.com/infographics/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/infografika/"]:hover{
    color: #ffffff;
    text-shadow: 0 0 3px #27d2fe, 0 0 6px #27d2fe, 0 0 12px #27d2fe, 0 0 24px #27d2fe, 0 0 48px #27d2fe;
  }

  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"],
  .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"],
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"],
  .menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"],
  .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"] {
    color: #ffffff;
    text-shadow: 0 0 6px #d50000, 0 0 12px #d50000, 0 0 24px;
    transition: text-shadow 0.5s;
  }

  .menu-primary>li>a[href="https://slotegrator.com/infographics/"],
  .menu-primary>li>a[href="https://slotegrator.com/infografika/"]{
    color: #ffffff;
    text-shadow: 0 0 6px #27d2fe, 0 0 12px #27d2fe, 0 0 24px;
    transition: text-shadow 0.5s;
  }

  .menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"]:before {
	top: -14px;
	right: 0;
  }


  .menu-primary>li>a[href="https://slotegrator.com/novosti_meropryatia/onlayn-translyaciya-igb-live-2018.html"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/event_news/igb-live-2018-stream-online.html"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"]:before,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"]:before {
    vertical-align: sub;
  }

  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-goes-to-russian-gaming-week-2018/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-rgw-2018/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-attends-igb-live-2018/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/slotegrator-edet-na-igb-live-2018/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/vebinar/vebinar-ot-slotegrator-i-login-casino-pochemu-uspeshnye-gembling-operatory-vybirayut-virtualnyy-sport/"]:hover,
  .menu-primary>li>a[href="https://slotegrator.com/webinars/webinar-by-slotegrator-and-login-casino-why-do-successful-gambling-operators-choose-virtual-sports/"]:hover {
    color: #ffffff;
    text-shadow: 0 0 3px #d50000, 0 0 6px #d50000, 0 0 12px #d50000, 0 0 24px #d50000, 0 0 48px #d50000;
  }
}



// page-partners
.page-partners {
  .partners {
    .partners-grid {
      justify-content: space-around;
    }
  }
}

// Main styles
main {
  transition: all .3s ease;
  background-color: $color-white;

  &::before {
    display: block;
    position: relative;
    height: 95px;
    transition: height .4s ease;
    background-color: $color-black;
    content: '';
  }
  @media only screen and (max-width: 799px) {
    &::before {
      height: 0;
    }
  }

  >section {
    padding: 100px 0;
  }

  &.page-main {
    .products {
      h3 {
        font-size: 2.2rem;
      }
    }

    .header {
      @include gradient-blue-radial;
    }
  }


  >.news-list {
    padding-top: 0;

    @media (min-width: 1200px) {
      .slick-slide {
        a.illustration {
          img {
            height: 244px;
            object-fit: cover;
          }
        }
      }
    }
  }

  >.news-inner {
    padding-bottom: 15px;
  }

  .news {
    .news-main {
      .details--left {
        font-size: 1.6rem;
      }
    }
  }

  .partners {
    .partners-img {
      img {
        margin: 0 auto;
        cursor: pointer;
      }
    }
    .partners-title {
      cursor: pointer;
    }
    .buttons {
      display: flex;
      justify-content: space-between;
      @media only screen and (min-width: 751px) {
        width: 105%;
      }
      @media only screen and (max-width: 750px) {
        flex-direction: column;
      }
      margin: auto 0 0;
      padding-top: 15px;
      .btn-primary {
        max-width: 199px;
        padding: 0 1.5rem;
        font-size: 1.4rem;
        @media only screen and (min-width: 751px) {
          min-width: 199px;
        }
        @media only screen and (max-width: 750px) {
          max-width: 100%;
        }
      }
      .btn-blue {
        max-width: 142px;
        padding: 0 1.5rem;
        font-size: 1.4rem;
        @media only screen and (min-width: 751px) {
          min-width: 142px;
        }
        @media only screen and (max-width: 750px) {
          max-width: 100%;
          margin-bottom: 10px;
        }
      }
    }
  }

  .commentsWrapp{
	  padding: 15px 0;

	  &+.news-list{
		  padding-top: 100px;
	  }
  }

  @media only screen and (max-width: 799px) {
    &.page-main {
      .products {
        h3 {
          font-size: 1.8rem;
        }
      }
    }

  }

  @media only screen and (max-width: 659px) {
    >section {
      padding: 50px 0;
    }
  }
}
.game-dev-text {
  text-align: center;
}

// Breadcrumb
.breadcrumbs {
  padding: 30px 0 0;

  &+section {
    padding-top: 51px;
  }
  &+section.partner {
    padding-top: 60px;
    @media (max-width: 750px) {
      padding-top: 40px;
    }
  }

  ul {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 899px) {
/*      display: inline-block;*/
      width: 100%;
    }
    li {
/*      @include angle-double-right;*/
/*      display: flex;
      align-self: center;*/
      position: relative;
      padding: 0 4px 0 16px;
      color: $color-grey2;
      height: 14px;
      line-height: 14.5px;
      font-size: 14px;
      font-weight: 300;
/*      vertical-align: middle;*/
      @media (max-width: 899px) {
        display: inline-block;
      }
      &.active {
        vertical-align: middle;
        position: relative;
        top: 0px;
      }

      &::before {
        left: -2px;
        position: absolute;
        top: 50%;
        margin-top: -3px;
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        border: 1px solid $color-black;
        border-bottom: 0;
        border-left: 0;
        content: '';
      }

      &::after {
        position: absolute;
        left: 2px;
        top: 50%;
        margin-top: -3px;
        display: block;
        width: 6px;
        height: 6px;
        transform: rotate(45deg);
        border: 1px solid $color-black;
        border-bottom: 0;
        border-left: 0;
        content: '';
      }

      &:first-of-type {
        padding-left: 0;

        &::before,
        &::after {
          display: none;
        }
      }

      &:last-of-type {
        &::before,
        &::after {
          border-color: $color-grey2;
        }
      }

      a {
        display: inline-block;
        position: relative;
        top: 0px;
        max-width: 250px;
        transition: border-bottom .2s ease;
        border-bottom: 1px solid $color-black;
        color: $color-black;
        font-size: inherit;
        text-decoration: none;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        vertical-align: middle;

        &:hover {
          border-bottom: 1px solid $color-grey-light;
        }
      }
    }
  }

  @media only screen and (max-width: 879px) {
    ul {
      li {
        font-size: 14px;
        margin-bottom: 5px;

        &::before,
        &::after {
      // /*   margin-top: -3.5px;
        }
      }
    }
  }
  @media only screen and (max-width: 620px) {
    ul {
      li {
        font-size: 12px;
        margin-bottom: 5px;
        height: 14px;
        line-height: 14px;
      }
    }
  }
}

/*
	xBreadcrumbs (Extended Breadcrumbs) jQuery Plugin
	� 2009 ajaxBlender.com
	For any questions please visit www.ajaxblender.com 
	or email us at support@ajaxblender.com
*/
.breadcrumbs-top{
  height: 200px;
}

.xbreadcrumbs {
  position: relative;
  z-index: 1;
  li {
    ul {
      position: absolute;
      float: left;
    }
    list-style: none;
    margin: 0;
    padding: 0;
  }
  list-style: none;
  margin: 0;
  padding: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    li {
      list-style: none;
      margin: 0;
      padding: 0;
    }
  }
  clear: both;
  float: left;
  li {
    float: left;
  }
  ul {
    display: none;
  }
  background: #ffffff;
  width: 100%;
  li {
    padding: 5px;
    // border-right: 1px solid #CECECE;
    height: 16px;
    &.current {
      border-right: none;
    }
    a {
      font-size: 11px;
      color: #666666;
      text-decoration: none;
      &:hover {
        color: #000;
      }
      img{
        width: 15px;
        height: 15px;
      }
    }
    &.hover a {
      color: #000;
    }
    &.current a {
      color: #707070;
    }
    ul {
      background: #fff;
      font-size: 11px;
      padding: 5px;
      width: 180px;
      top: 14px;
      border: 1px solid rgb(126, 126, 126);
      li {
        float: left;
        width: 100%;
        border-right: none;
        height: auto;
        a {
          text-decoration: none;
          color: #000000 !important;
          display: block;
          padding: 4px;
          border-bottom: none;
          transition: .2s ease-in-out;
        }
        &:last-child a {
          border-bottom: none;
        }
        a:hover {
          background: #ffffff;
        }
      }
    }
  }
  .wrap-title{
    // display: none;
    display: inline-block;
    z-index: 2;
    a{
      border-bottom: 0;
      &:hover{
        border-bottom: 0;
      }
    }
    ul>li{
      &::before,
      &::after{
        display: none;
      }
    }
    // @media only screen and (max-width:1140px){
    //   display: inline-block;
    //   z-index: 2;
    // }
  }
  .current a{
    border-bottom: 0;
  }
  .xbreadcrumbs-item {
      display: none;
  }

}

/*  Base style of xBreadcrumbs  */
/*  Top Level  */

/*  Top Level - Current Page  */

/*  Sub-level  */


.grey {
  background-color: $color-grey-lightest;
}

//////////////////////////
//===Unified Protocol===//
/////////////////////////
.unified-protocol {
  padding-bottom: 0;
  overflow: hidden;

  .text {
    display: block;
    width: calc(100% - 490px);
    padding: 0 15px 154px 0;
    float: left;
    h2 {
      text-align: left;
    }
  }

  .tablet {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 490px;
    height: 450px;
    padding: 0 35px;
    z-index: 0;

    .slider-tablet {
      height: 100%;
      background: {
        position: center 0;
        image: url('../images/ipad_news1.png');
        repeat: no-repeat;
        size: 100% auto;
      }
    }

    &::before {
      position: absolute;
      top: -250px;
      left: -10%;
      width: 120%;
      height: 100%;
      background: {
        image: url('../images/bg/bg-unified-protocol.png');
        size: 100% auto;
        repeat: no-repeat;
      }
      content: '';
      z-index: -1;
    }
  }

  .read-more {
    margin-top: 30px;
  }

  @media only screen and (max-width: 989px) {
    .tablet {
      top: 0;
      right: 30px;
      bottom: auto;
      width: 375px;
      height: 433px;

      &::before {
        top: -100px;
      }

      &::after {
        display: none;
        position: relative;
        padding-top: 142%;
        content: '';
      }

      %full-height {
        height: 100%;
      }

      .slider-tablet {
        @extend %full-height;
        position: absolute;
        top: 0;
        left: 35px;
        width: calc(100% - 70px);
        padding: 11% 6% 11.2%;

        li,
        .slick-slider,
        .slick-list,
        .slick-track,
        .slick-slide {
          @extend %full-height;
        }
      }
    }

    .text {
      width: calc(100% - 400px);
    }
  }

  @media only screen and (max-width: 799px) {
    .tablet {
      right: 15px;
      width: 320px;
      height: 355px;
    }

    .text {
      width: calc(100% - 305px);

      h2 {
        margin-bottom: 30px;
        font-size: 2.5rem;
      }
    }
  }

  @media only screen and (max-width: 619px) {
    .tablet {
      position: relative;
      right: auto;
      width: 300px;
      max-width: 100%;
      height: 425px;
      margin: 0 auto 25px;
      padding: 0;

      &::before {
        top: -50px;
      }

      .slider-tablet {
        left: 0;
        width: 100%;
        padding: 15.5% 8%;
      }
    }

    .text {
      width: 100%;
      padding: 0 0 50px;
      text-align: center;

      h2 {
        text-align: center;
      }
    }
  }

  @media only screen and (max-width: 360px) {
    .read-more {
      font-size: 1.8rem;

      &::after {
        margin-top: 0;
      }
    }
  }
}

//////////////////////////
//=====Page Contacts====//
//////////////////////////
.page-contacts {
  .header {
    height: calc(100vh - 95px);
    min-height: 725px;
    background: $color-black4 url('../images/contacts/Bg.jpg') 50% 50% / cover no-repeat;

    &::after {
      content: none;
    }

    .container {
      display: flex;
      position: relative;
      flex-wrap: wrap;
      padding: 6.3% 100px;
      color: $color-white-90;
      font-size: 16px;
    }

    form {
      display: flex;
      flex-wrap: wrap;

      textarea {
        height: 17rem;
        resize: none;
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-top: 2rem;
      }

      [class*='-recaptcha'] {
        display: block;
        margin-left: 3rem;
        text-align: center;

        .help-block {
          margin-right: -2.2rem;
          margin-left: -2.2rem;
          padding: .4rem 0;
          transition: opacity .2s;
          text-align: center;
          white-space: nowrap;
          opacity: 0;
        }
      }

      .btn {
        width: 225px;
        margin-right: 6.1rem;
      }

      &.focused {
        [class*='-recaptcha'] {
          .help-block {
            opacity: 1;
          }
        }
      }
    }
  }

  h1 {
    order: 0;
    width: 100%;
    margin-bottom: 5.5rem;
  }

  .contact-info {
    order: 2;
    width: 29.89%;
    padding-top: 12px;
    padding-left: 60px;
    text-align: left;

    h2 {
      margin-bottom: 35px;
      color: inherit;
      font-size: 20px;
      text-align: inherit;
      text-transform: none;
    }

    li {
      margin-bottom: 15px;
      opacity: .8;
      font-weight: 300;

      &:not(:first-of-type) {
        &:hover {
          opacity: 1;
        }
      }

      a {
        transition: color .2s ease;
        color: inherit;

        &:active {
          color: inherit;
        }
      }
    }

    .fa {
      width: 36px;

      &::before {
        font-size: 24px;
      }
    }
  }

  .contact-form {
    order: 1;
    width: 70%;
    min-height: 352px;

    .text-inputs {
      flex: 3;
      padding-right: 15px;

      .form-group {
        height: 50px;

        &.has-error,
        &.has-success {
          height: auto;
        }
      }
    }

    .questions {
      flex: 4;
      padding-top: 0;
      padding-left: 15px;
    }
  }

  .contact-socials {
    order: 3;
    width: 100%;
    margin-top: 8.5rem;

    li {
      margin: .7rem;
    }

    a {
      color: $color-white-80;
    }
  }

  .staff {
    .slider {
      width: calc(100% - 120px);
      margin-right: auto;
      margin-left: auto;
    }

    .slick-slide {
      text-align: center;
      cursor: default;
    }

    .photo {
      img {
        max-width: 100%;
        margin: auto;
        border-radius: 50%;
      }
    }

    h3 {
      margin-top: 4.5rem;
      font-weight: 400;
    }

    p {
      margin-top: 1.5rem;
      font-size: 21px;
      font-weight: 300;
    }
  }

  .events {
    padding-right: 9.6%;
    padding-left: 9.6%;

    &.right {
      .details {
        justify-content: flex-end;
      }

      .col {
        text-align: right;
      }
    }

    .details {
      display: flex;
      align-items: center;
      justify-content: flex-start;

      h3 {
        margin-bottom: 3rem;
        font-size: 2.2rem;
        text-transform: uppercase;

        a {
          color: inherit;
          font-weight: 400;

          &:focus {
            outline: 0;
          }

          &:hover {
            color: $color-red;
          }
        }
      }

      time,
      .place {
        font-size: 1.6rem;
      }

      time {
        display: block;
        margin-bottom: 1.5rem;
      }

      .fa {
        margin-right: 1rem;
        color: $color-red;
        vertical-align: baseline;

        &::before {
          font-size: 1.6rem;
        }
      }

      p {
        margin: 2.5rem 0;
        font-size: 1.6rem;
      }
    }

    .col {
      width: 50%;
      max-width: 655px;
      padding: 0 1.5rem;

      img {
        max-width: 100%;
        margin-left: auto;
      }
    }
  }

  @media only screen and (max-width: 1179px) {
    .events {
      padding-right: 45px;
      padding-left: 45px;

      &.right {
        .col {
          text-align: left;
        }

        .col-img {
          order: -1;
          text-align: center;
        }
      }

      .details {
        flex-wrap: wrap;

        h3 {
          margin-top: 2.5rem;
        }
      }

      .col {
        width: 100%;
        max-width: none;
        margin-bottom: 0;

        img {
          margin: auto;
        }
      }
    }
  }

  @media only screen and (max-width: 1099px) {
    .header {
      height: auto;

      .container {
        padding: 6.3% 5%;
        transform: none;
      }
    }
  }

  @media only screen and (max-width: 989px) {
    h1 {
      margin-bottom: 4rem;
    }

    .contact-form {
      order: 2;
      width: 100%;
    }

    .contact-info {
      order: 1;
      width: 100%;
      padding: 0;

      h2 {
        display: none;
      }

      ul {
        @include horizontal-align;
        display: flex;
        position: relative;
        flex-wrap: wrap;
        justify-content: space-around;
        width: 80%;

        li {
          flex-basis: 200px;
          margin: 0 20px 15px;
        }
      }
    }

    .contact-socials {
      order: 1;
      margin: 4rem;
    }
  }

  @media only screen and (max-width: 659px) {
    .header {
      background-size: auto;
      background-position-y: 0;

      .container {
        padding: 6rem 5%;
      }
    }

    .contact-form {
      height: auto;

      .text-inputs,
      .questions {
        flex: none;
        width: 100%;
        padding: 0;
      }

      form {
        .buttons {
          flex-direction: column;
          align-items: center;
          margin-top: 1rem;
        }

        [class*='-recaptcha'] {
          margin-left: 0;
          transition: max-height .5s ease;

          >div {
            >div {
              >div {
                width: 302px;
                height: 0;
                transition: height .5s ease;
              }
            }
          }
        }

        &.focused {
          [class*='-recaptcha'] {
            max-height: 50px;
            margin-bottom: 2rem;

            >div {
              >div {
                >div {
                  height: 78px;
                }
              }
            }
          }
        }

        .btn {
          width: 100%;
          margin-right: 0;
        }
      }
    }

    .contact-socials {
      margin: 3rem;

      a {
        width: 32px;
        height: 32px;

        &::before {
          font-size: 3.2rem;
        }
      }
    }

    .events {
      padding-right: 15px;
      padding-left: 15px;

      .details {
        h3 {
          font-size: 2rem;
        }

        time,
        .place,
        p {
          font-size: 1.8rem;
        }

        .btn {
          @include horizontal-align;
        }
      }
    }
  }

  @media only screen and (max-width: 449px) {
    .contact-info {
      ul {
        li {
          margin: 0 10px 15px;
        }
      }
    }

    .contact-socials {
      .socials {
        width: 220px;
        margin: auto;
      }

      li {
        margin: .9rem;
      }
    }
  }
}

//////////////////////////
//=======Main Page======//
//////////////////////////
.page-main {
  @media only screen and (max-width: 879px) {
    .header {
      .logo {
        max-width: 90%;
      }
    }
  }

  @media only screen and (max-width: 799px) {
    .header {
      h1 {
        max-width: 65%;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .header {
      h1 {
        max-width: 85%;
        margin-top: 14px;
        margin-bottom: 16px;
        font-size: 2rem;
      }
    }
  }
  .news-list {
    margin-top: 90px;
  }
}

//////////////////////////
//=====Partners-list====//
//////////////////////////
.partners-list {
  ul {
    font-size: 0;
    text-align: center;
  }

  li {
    display: inline-block;
    position: relative;
    width: calc(16.666% - .85px);
    height: 75px;
    margin: 0 0 35px;
    vertical-align: top;
  }

  a {
    &:hover {
      img {
        transform: scale(1.05);
      }
    }

    &:active {
      img {
        transform: scale(1);
        transition: all 0s;
      }
    }
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: 72%;
    max-height: 100%;
    margin: auto;
    transition: all .2s ease;
  }

  @media only screen and (max-width: 989px) {
    li {
      width: 20%;
    }
  }

  @media only screen and (max-width: 799px) {
    li {
      width: 25%;
    }
  }

  @media only screen and (max-width: 659px) {
    li {
      width: 33.333333%;
      height: 70px;
      margin: 0 0 15px;
    }
  }

  @media only screen and (max-width: 479px) {
    li {
      width: 50%;

      img {
        max-width: 80%;
      }
    }
  }
}

//////////////////////////
//====Create project====//
//////////////////////////
.create-project {
  text-align: center;

  p {
    max-width: 930px;
    margin: 0 auto 50px;
    font-size: 1.8rem;
  }

  @media only screen and (max-width: 519px) {
    .btn {
      width: 320px;
      max-width: 100%;
      height: auto;
      padding: .9rem 2.5rem 1rem;
      line-height: 2.2rem;
    }
  }
}
.details {
  font-weight: 300;
  .info {
    font-weight: 300;
  }
}
//////////////////////////
//======Info Pages======//
//////////////////////////
.unified-protocol-info {
  background-color: #ffffff;

  .details {
    .col-02 {
      width: 44%;
    }

    .col-04 {
      width: 28%;
    }

    img {
      width: 140%;
      max-width: 140%;
      float: right;
    }
  }

  @media only screen and (max-width: 989px) {
    .details {
      img {
        width: 100%;
        margin-top: 20px;
        margin-left: -5%;
        float: none;
      }
    }
  }
}

.platform-info {
  @media only screen and (max-width: 989px) {
    .details {
      .col {
        &.col-img {
          img {
            margin-right: -5% !important;
          }
        }
      }
    }
  }
}

.virtual-sports-info {
  .details-02 {
    .col-img {
      float: left;
    }
  }
}


.page-live-dealers{
  .header-secondary .img{
    width: 75%;
  }
  .header-secondary .img img{
    width:100%;
  }

}

.live-dealers-info {


  .live-dealers-providers{

    h3{
      text-align: center;
    }
    margin-bottom: 40px;
    &ImgWrapp{
      min-height: 150px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .details .col.col-img-wide img{
    margin-top: 140px;
  }

  .live-dealers-text{
    margin-top: 100px;
  }

  .live-dealers-list{
    padding: 0 25px;
    li{
      padding-left: 40px;
      margin:10px 0;
      position: relative;
      &:before{
        position: absolute;
        content: '\2022';
        vertical-align: middle;
        left: 0;
        font-size: 23px;
        font-weight: 900;
        line-height: 1;
        color: #000;
      }
      span{
        font-size: 16px;
        font-weight: 300;
      }
    }
  }
}

.game-dev-info {
  .details {
    .col-img {
      img {
        width: 155%;
        max-width: 155%;
        margin-left: -10%;
      }
    }
  }
}

.promotion-info {
  .details {
    .col-img {
      img {
        width: 145%;
        max-width: 145%;
      }
    }
  }
}

.white-label-info {
  .details {
    .col-02 {
      width: 52%;

      &.col-img {
        width: 48%;
        padding: 0 70px;
      }
    }

    &.details-01 {
      .col-img {
        img {
          margin-top: -90px;
        }
      }
    }

    &details-02 {
      .col-img {
        float: left;
      }
    }
  }
}

.telegram-casino-info {
  padding-bottom: 0;
  background-color: $color-grey-lightest;

  .details {
    .col-02 {
      width: 47%;
      float: right;

      &.col-img {
        width: 50%;
        padding: 0 0 0 30px;
        float: left;
      }
    }
  }

  @media only screen and (max-width: 989px) {
    .col-03 {
      width: 50%;

      &.col-img {
        width: 100%;
        margin-top: 20px;
        text-align: center;

        img {
          display: inline-block;
        }
      }
    }

    .col-02 {
      &.col-img {
        width: 100%;
        margin-top: 20px;
        float: none;
        text-align: center;

        img {
          display: inline-block;
          max-width: 90%;
        }
      }
    }
  }
}

//////////////////////////
//======Advantages======//
//////////////////////////
.advantages {
  padding: 100px 0 50px;

  ul {
    display: block;
    width: calc(100% + 30px);
    margin: 0 -15px;
    font-size: 0;
  }

  li {
    display: inline-block;
    width: 33.333333%;
    padding: 0 15px 50px;
    vertical-align: top;

    .icon {
      display: block;
      width: 50px;
      float: left;

      img {
        max-width: 70%;
        max-height: 36px;
      }
    }

    h3 {
      width: calc(100% - 50px);
      margin: -.3rem 0 2rem;
      float: right;
    }

    p {
      display: block;
      width: calc(100% - 50px);
      margin-top: -.8rem;
      float: right;
      color: $color-grey-darker;
      font-size: 1.6rem;
      line-height: 2.5rem;
    }
  }

  @media only screen and (max-width: 989px) {
    li {
      width: 50%;
      padding: 0 5px 35px;
    }
  }

  @media only screen and (max-width: 659px) {
    padding: 50px 0 15px;

    ul {
      text-align: center;
    }

    li {
      width: 100%;
      max-width: 440px;
      padding: 0 15px 35px;
      text-align: left;
    }
  }
}

//////////////////////////
//========Blue BG=======//
//////////////////////////
.bg-blue {
  position: relative;
  background-image: linear-gradient(to top, $color-blue-gradient4 0%, $color-blue-gradient5 100%);

  &::before,
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    content: '';
  }

  &::before {
    background: url('../images/blue-gradient.jpg') 50% 50% / cover no-repeat;
    opacity: .75;
  }

  &::after {
    background-color: rgba(0, 20, 64, 0.67);
    // background-color: #232323;
  }

  .container {
    z-index: 2;
  }

  h2 {
    color: $color-white;
  }

  p {
    color: $color-white;
  }

  form {
    .input {
      .error-message {
        color: $color-red2;
      }
    }
  }
}

//////////////////////////
//=======Read More======//
//////////////////////////
.read-more {
  display: inline-block;
  position: relative;
  transition: color .2s ease;
  color: $color-blue-light;
  font-size: 1.6rem;
  text-transform: uppercase;

  &::after {
    @include font-awesome;
    display: inline-block;
    margin-top: -1px;
    padding-left: 10px;
    transition: all .2s ease;
    font-size: 2.1rem;
    content: '\f105';
    vertical-align: top;
  }

  &:hover {
    color: lighten($color-blue-light, 10%);
  }
}

//////////////////////////
//=====Scroll arrow=====//
//////////////////////////
.scroll-top {
  position: fixed;
  bottom: 80px;
  left: 1vw;
  width: 52px;
  height: 52px;
  transition: opacity .3s ease;
  border: 3.5px solid $color-grey3;
  border-radius: 100%;
  background-color: transparent;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  z-index: 10;

  &::before,
  &::after {
    position: absolute;
    right: 0;
    left: 0;
    margin: auto;
    content: '';
  }

  &::before {
    top: 8px;
    width: 0;
    height: 0;
    border: {
      width: 0 12px 14px 12px;
      color: transparent transparent $color-grey3 transparent;
      style: solid;
    }
  }

  &::after {
    position: absolute;
    top: 19px;
    right: 0;
    left: 0;
    width: 12px;
    height: 16px;
    margin: auto;
    background-color: $color-grey3;
    content: '';
  }

  &.displayed {
    opacity: 1;
    visibility: visible;

    &:hover {
      opacity: .8;
    }

    &:active {
      transform: scale(.95);
      transition: transform 0s;
    }
  }

  @media only screen and (max-width: 989px) {
    display: none;
  }
}

//////////////////////////
//===Notification Bar===//
//////////////////////////
.notification {
  display: table;
  position: fixed;
  bottom: -60px;
  left: 0;
  width: 100%;
  height: 60px;
  transition: bottom .5s;
  background: $color-black-70;
  text-align: center;
  z-index: 999;

  >* {
    display: table-cell;
    vertical-align: middle;
  }

  .icon {
    position: relative;
    width: 60px;
    height: 60px;
    background: $color-black-70;
    text-align: center;

    span {
      @include total-center;
      position: absolute;

      &::before {
        font-size: 3rem;
      }

      &.success {
        color: $color-green;
      }

      &.error {
        color: $color-red;
      }
    }
  }

  p {
    margin: 0;
  }

  .txt {
    padding: 0 20px;
    color: $color-white;
  }

  button {
    @include vertical-align;
    position: absolute;
    right: 20px;
    transition: transform .2s ease;
    background-color: transparent;
    color: $color-white;

    &::before {
      font-size: 2.2rem;
    }

    &:hover {
      transform: scale(1.05) translateY(-50%);
    }

    &:active {
      transform: scale(1) translateY(-50%);
      transition: transform 0s;
    }
  }

  &.displayed {
    bottom: 0;
  }
}

//////////////////////////
//========Sliders=======//
//////////////////////////
.slick-arrow {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 2.5rem;
  height: 4rem;
  margin: auto;
  transition: transform .2s ease, color .2s ease;
  background-color: transparent;
  color: $color-grey;

  &::before {
    height: 4rem;
    font-size: 5.5rem;
    line-height: 4rem;
  }

  &:hover {
    transform: scale(1.1);
    color: $color-red;
  }

  &:active {
    transform: scale(1);
    transition: transform 0s;
  }

  &.fa-angle-left {
    left: -50px;
  }

  &.fa-angle-right {
    right: -50px;
  }
}

.slider-tablet {
  width: 100%;
  padding: 13% 7% 0;

  li {
    position: relative;
    height: 440px;

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      max-width: 100%;
      margin: auto;
    }
  }
}

.slider-news {
  position: relative;

  li {
    img,
    video {
      width: 100%;
    }
  }

  .slick-dots {
    display: block;
    position: absolute;
    right: 0;
    bottom: 50px;
    left: 0;
    height: 15px;
    margin: auto;
    padding: 0 20px;
    font-size: 0;
    text-align: center;
    pointer-events: none;
    @media only screen and (max-width: 650px) {
      bottom: 55px;
    }
    @media only screen and (max-width: 519px) {
      bottom: 50px;
    }
    @media only screen and (max-width: 399px) {
      bottom: 50px;
    }

    li {
      display: inline-block;
      position: relative;
      width: auto !important;
      height: auto !important;
      min-height: 0 !important;
      margin: 0 7px !important;
      float: none;
      background-color: transparent !important;
      z-index: 2;

      button {
        display: block;
        width: 15px;
        height: 15px;
        transition: transform .2s ease;
        border: 1px solid $color-white;
        border-radius: 100%;
        background-color: transparent;
        text-indent: -50000px;
        pointer-events: all;

        &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(1);
          transition: transform 0s;
        }
      }

      &.slick-active {
        button {
          background-color: $color-white;

          &:hover {
            transform: scale(1.1);
          }

          &:active {
            transform: scale(1);
          }
        }
      }
    }
  }
}

.last-games {
  padding-top: 80px;

  &-slider {
    margin-right: 53px;
    margin-left: 53px;
  }

  &-slide {
    margin: 0 12.5px;
  }

  @media only screen and (max-width: 989px) {
    padding-top: 31px;
  }

  @media only screen and (max-width: 569px) {
    &-slider {
      margin-right: 40px;
      margin-left: 40px;
    }

    &-slide {
      margin: 0;

      img {
        margin: auto;
      }
    }
  }
}

//////////////////////////
//=====Product Info=====//
//////////////////////////
.product-info {
  background-color: $color-grey-lightest;

  h2 {
    padding: 0 20px;
  }

  .details {
    width: calc(100% + 40px);
    margin-right: -20px;
    margin-bottom: 50px;
    margin-left: -20px;
    font-size: 0;

    &:last-child {
      margin-bottom: 0;
    }

    .col {
      display: inline-block;
      padding: 0 20px;
      vertical-align: top;

      &.col-img {
        padding: 0;
        text-align: center;

        &-wide {
          img {
            display: block;
            width: 134%;
            max-width: 134%;
            float: left;
          }

          &-left {
            img {
              display: block;
              width: 134%;
              max-width: 134%;

              @media (min-width: 990px) {
                position: relative;
				left: 26%;
				left: 8%;

                &.live-dealers-leftImg{
                  left:-3%;
                  width: 110%;
                  max-width: 110%;
                  //top:-50px;
                }
              }
			  float: right;


            }
          }
        }
      }

      &-02 {
        width: 50%;
      }

      &-03 {
        width: 33.333333%;
      }

      &-04 {
        width: 25%;
      }
    }

    h3 {
      margin-bottom: 1rem;
      padding: 0 25px;
    }

    p {
      margin-bottom: 2rem;
      padding: 0 25px;
      font-size: 1.6rem;
    }

    img {
      display: inline-block;
      max-width: 100%;
    }

    &-02 {
      display: flex;
      flex-direction: row-reverse;
    }
  }

  @media only screen and (max-width: 989px) {
    .details-02 {
      flex-direction: column;
    }
  }
}

.filter-node {
  cursor: pointer;
}

.g-recaptcha {
  transform: scale(.77);
  transform-origin: 0 0;
}

//////////////////////////
//==========FAQ=========//
//////////////////////////
.faq {
  .page {
    margin: 0 auto;

    h1 {
      max-width: none;
      margin-bottom: 5.5rem;
      text-align: center;
    }
  }

  @media only screen and (max-width: 659px) {
    .page {
      h1 {
        margin-bottom: 3rem;
      }
    }
  }
}

// Accordion
.accordion {
  max-width: 750px;
  margin: 0 auto;

  >li {
    margin-bottom: 15px;
    border: 1px solid $color-grey-lightest;
    border-radius: 8px;

    .inner {
      display: none;
      padding: 15px 25px;
      font-weight: 300;
    }

    .toggle {
      display: block;
      position: relative;
      width: 100%;
      max-width: none;
      min-height: 5rem;
      margin: 0;
      padding: 15px 40px 15px 20px;
      border-radius: 8px;
      background: $color-grey-lightest url('/media-new/images/plus_icon.png') calc(100% - 17px) 50%  no-repeat;
      background-color: $color-grey-lightest;
      color: $color-grey-darker;
      font-size: 14px;
      font-weight: 300;
      text-align: left;
      text-transform: none;
      cursor: pointer;

      &:focus {
        outline: 0;
      }

      // &::before {
      //   @include vertical-align;
      //   display: flex;
      //   position: absolute;
      //   right: 17px;
      //   align-items: center;
      //   justify-content: center;
      //   width: 16px;
      //   height: 16px;
      //   border-radius: 50%;
      //   background-color: $color-grey;
      //   color: $color-grey-lightest;
      //   font-size: 18px;
      //   line-height: .85;
      //   text-align: center;
      //   text-indent: 1px;
      //   content: '+';
      // }
    }

    &.show {
      .toggle {
        background-image: url('/media-new/images/minus_icon.png');
        // &::before {
        //   padding-bottom: 4px;
        //   font-size: 25px;
        //   line-height: .4;
        //   content: '-';
        // }
      }
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }

  @media only screen and (max-width: 989px) {
    li {
      .toggle {
        &::before {
          text-indent: 0;
        }
      }
    }
  }

  @media only screen and (min-resolution: 2dppx) {
    >li {
      &.show {
        .toggle {
          &::before {
            padding-bottom: 0;
            line-height: .5;
          }
        }
      }
    }
  }
}

.faq-inner{
  max-width: 750px;
  margin: 0 auto;

  .faq-item{
    margin:0 10px 15px;
    border: 1px solid #f2f2f2;
    border-radius: 8px;
    padding-bottom: 15px;
  }

  h2{
    background-color: $color-grey-lightest;
    color: $color-grey-darker;
    font-size: 14px;
    font-weight: 300;
    text-align: left;
    border-radius: 8px;
    padding: 15px 40px 15px 20px;
    margin-bottom: 15px;
  }

  p{
    font-weight: 300;
    color: #000;
    font-size: 1.6rem;
    padding: 0 25px;
  }

  ul,ol {
    list-style-type: disc;
    padding: 0 25px 0 43px;
    li {
      line-height: 1.57;
      font-weight: 300;
      color: #000;
      font-size: 1.6rem;
    }
  }

  .content-action{
    padding: 15px 25px;
    font-weight: 300;
    color: #000;
    font-size: 1.6rem;
  }
}

.questions,
.comment-form {
  padding-top: 50px;

  @media only screen and (max-width: 659px) {
    padding-top: 30px;
  }
}

//////////////////////////
//=======Comments=======//
//////////////////////////
.comment-form {
  .form-light {
    max-width: 100%;
  }
  .comment-title-form{
    font-size: 2.2rem;
    font-weight: 300;
    display: block;
    max-width: 770px;
    margin: 0 auto 50px;
    color: #000;
    font-weight: 300;
    text-align: center;
    text-transform: uppercase;
  }

  @media only screen and (max-width: 659px) {
    h2 {
      margin-bottom: 30px;
    }
  }
}

.user-comments {
  margin-top: 50px;
  margin-bottom: 0px;
  @media (max-width: 520px) {
    margin-top: 40px;
  margin-bottom: 0px;
  }
}

.comment {
  position: relative;
  margin-bottom: 65px;
  color: $color-black;

  &:last-of-type {
    margin-bottom: 0;
  }

  &-body {
    display: flex;
  }
  &-answer {
    padding-top: 40px;
    padding-left: 75px;
    padding-bottom: 65px;
    @media only screen and (max-width: 600px) {
      padding-left: 35px;
    }
  }

  &-left {
    width: 72px;
    min-width: 72px;
    margin-right: 35px;
  }

  &-right {
    &-top {
      display: flex;
      margin-bottom: 14px;
    }

    &-bottom {
      font-size: 16px;
      text-align: justify;
    }
  }

  &-avatar {
    border-radius: 50%;
    overflow: hidden;
  }

  &-author {
    margin-right: 30px;
    font-size: 16px;
    span {
      position: relative;
      top: -1.5px;
      margin: 0 5px;
    }
  }

  time {
    color: $color-grey2;
    font-weight: 300;
  }

  &-moderation {
    position: absolute;
    top: 0;
    right: 0;
    color: $color-red;
    font-size: 12px;

    .fa {
      vertical-align: middle;

      &::before {
        font-size: 17px;
      }
    }
  }

  &.premoderation {
    .comment-body {
      opacity: .5;
    }
  }

  @media only screen and (max-width: 659px) {
    &-body {
      display: block;
    }

    &-left {
      margin-right: 14px;
      float: left;
    }

    &-moderation {
      top: 90px;
      right: auto;
      left: 0;
    }

    &-right {
      &-top {
        flex-direction: column;
        justify-content: center;
        height: 72px;
      }
    }

    &.premoderation {
      .comment-right-top {
        margin-bottom: 50px;
      }
    }
  }
}

//////////////////////////
//=====commercialApp====//
//////////////////////////


.commercialApp{
	display: flex;
	align-items: center;
	position: relative;
	justify-content: space-between;
	padding: 10px 0;

	&-wrapper{
		margin:20px 0;
		position: relative;
		overflow: hidden;
	}

	&-bg{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		transform: scale(1.1);
		filter: blur(5px) brightness(.4);
		background-repeat:  no-repeat!important;
		background-position:  center!important;
		background-size: cover!important;
	}

	&-text{
		color: white;
		font-family: Roboto;
		font-size: 18px;
		font-weight: 300;
		line-height: 26px;
		max-width: 500px;
	}

	&-btn{
		min-width: 260px;
	}

	@media screen and (max-width: 800px) {
		flex-wrap: wrap;

		&-text{
			max-width: 100%;
			text-align: center;
			padding-bottom: 20px;
		}

		&-btn{
			margin: 0 auto;
		}
	}
}

//////////////////////////
//========Recalls=======//
//////////////////////////
.recalls-list {
  max-width: 740px;
  margin: auto;

  .recall {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 90px;
  }
  .recall:last-child {
    margin-bottom: 0;
  }

  .partner-logo {
    width: 19%;
  }

  .partner-recall {
    width: 75%;

    p {
      line-height: 1.71;
      text-align: justify;
    }
  }

  .partner-links {
    width: 100%;
    margin-top: 16px;
    text-align: right;

    span {
      display: inline-block;
      color: $color-red;
      font-weight: 300;

      &:last-of-type {
        margin-left: 16px;
      }
    }
  }

  @media only screen and (max-width: 659px) {
    .recall {
      margin-bottom: 60px;
    }

    .partner-logo,
    .partner-recall {
      width: 100%;
    }

    .partner-logo {
      margin-bottom: 25px;

      img {
        margin: auto;
      }
    }
  }
}

//////////////////////////
//======Services and Products Pages======//
//////////////////////////
.products-main {
  .products-grid {
    .buttons {
      .btn-blue {
        line-height: 38px;
      }
    }
  }
}

//////////////////////////
//========Academy=======//
//////////////////////////
.page-academy {
  .header-secondary {
    .container {
      max-width: none;

      p {
        max-width: 900px;
        margin: auto;
      }
    }
  }
}
.page-academy{
  .container{
    @media only screen and (max-width:519px){
      padding: 0 20px;
    }
  }
}
.academy-categories-wrap{
  position:relative;
}
.btn-wrap{
  display: flex;
  justify-content: space-between;
  position: absolute;
  z-index: 0;
  top:50%;
  transform:translateY(-50%);
  left:-2%;
  right: -2%;
  @media only screen and (max-width:1240px){
    left: -12px;
    right: -12px;
  }
  div{
    border: 2px solid #fff;
    border-color: #fff  #fff transparent transparent;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    transform: rotateZ(45deg);
    cursor: pointer;
    transition: .05s ease-in-out;
    &:hover{
      border: 4px solid #fff;
      border-color: #fff  #fff transparent transparent;
    }
  }
  .btn-prev{
    transform: rotateZ(-135deg);
  }

}
.page-academy-articles {
  .academy-articles {
    .article {
      .record-image {
        img {
          @media (min-width: 1200px) {
            height: 365px;
            object-fit: cover;
          }
          @media (max-width: 1199px) {
            height: 287px;
            object-fit: cover;
          }
          @media (max-width: 969px) {
            height: 245px;
          }
          @media (max-width: 769px) {
            height: 220px;
          }
        }
      }
    }
    .articles {
      .article-brief {
        margin-top: 0;
      }
      .details {
        margin-top: 15px;
      }
    }
  }
}

.academy {
  &-categories {
    display: flex;
    z-index: 10;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    .videomob {
      display: none;
      @media (max-width: 480px) {
        width: 100%;
        height: 205px;
        object-fit: cover;
      }
      @media (max-width: 325px) {
        height: 189px;
      }
    }
  }

  &-category {
    width: 28%;
    position: relative;
    overflow: hidden;
    margin: 5px;
    .academia-video {
      width: 100%;
      height: auto;
    }
    &:hover {
      .overlay-academy {
        top: -2%;
      }
      .overlay-academybig {
        top: -2%;
      }
    }

    &:nth-child(2) {
      width: 38.5%;
    }

    a {
      font-size: 16px;
      &:hover {
        text-decoration: underline;
      }
    }
    @media (min-width:660px) and (max-width:767px) {
      a {
        font-size: 15px;
      }
    }

    .img-wrap {
      display: block;
      margin-bottom: 13px;
    }
    .overlay-academy, .overlay-academybig {
      position: absolute;
      top: -100%;
      left: 0;
      width: 100%;
      height: 90%;
      padding: 12px 10px 10px 27px;
      transition: all .4s ease;
      background-color: rgba(27, 37, 52, 0.85);
      color: #fff;
      z-index: 3;
      overflow: hidden;
      h3 {
        font-size: 22px;
        font-weight: 400;
        text-align: left;
        text-transform: uppercase;
        padding-bottom: 14px;
      }
      p {
        line-height: 1.7;
        text-align: left;
      }
      span {
        line-height: 1.7;
        text-align: left;
        font-size: 14px;
        font-weight: 300;
      }
      a {
        max-width: 150px;
        font-size: 14px;
        font-weight: 400;
        display: block;
        text-decoration: none;
        text-align: left;
        margin-top: 12px;
        .fa-angle-double-down {
          transform: rotate(-90deg);
          transition: margin 0.2s;
        }
        &:hover {
          color: #d50000;
          .fa-angle-double-down {
           margin-left: 1px;
          }
        }
      }
    }
    .overlay-academybig {
      padding: 12px 45px 10px 47px;
      h3 {
        padding-bottom: 17px;
      }
      a {
        margin-top: 19px;
      }
    }
    @media (max-width:479px) {
      .overlay-academy, .overlay-academybig {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 12px;
        }
        span {
          font-size: 12px;
        }
        a {
          margin-top: 20px;
          .fa-angle-double-down {
            position: relative;
            top: 1px;
          }
        }
      }
      .overlay-academy {
        height: 91%;
      }
      .overlay-academybig {
        padding: 25px 20px 10px;
        height: 88%;
        h3 {
          padding-bottom: 5px;
        }
        a {
          margin-top: 5px;
        }
      }
      .academia-video {
        width: 100%;
        // height: 215px;
        height: auto;
        // object-fit: cover;
      }
    }
    @media (max-width:325px) {
      .overlay-academy {
        height: 89%;
      }
      .overlay-academybig {
        height: 87%;
      }
    }
    @media (min-width:480px) and (max-width:659px) {
      .overlay-academy, .overlay-academybig {
        h3 {
          font-size: 18px;
        }
        p {
          font-size: 12px;
        }
        span {
          font-size: 12px;
        }
        a {
          margin-top: 10px;
          .fa-angle-double-down {
            position: relative;
            top: 1px;
          }
        }
      }
      .overlay-academybig {
        padding: 10px 20px;
        height: 87%;
        h3 {
          padding-bottom: 10px;
        }
        a {
          margin-top: 12px;
        }
      }
    }
    @media (min-width:660px) and (max-width:770px) {
      .overlay-academy, .overlay-academybig {
        padding: 10px 10px 0px;
        h3 {
          font-size: 15px;
          padding-bottom: 2px;
        }
        p {
          font-size: 11px;
        }
        span {
          font-size: 11px;
        }
        a {
          font-size: 12px;
          margin-top: 2px;
        }
      }
      .overlay-academy {
        height: 83.5%;
      }
      .overlay-academybig {
        height: 83.5%;
      }
    }
    @media (min-width:771px) and (max-width:935px) {
      .overlay-academy, .overlay-academybig {
        padding: 12px 12px 0;
        height: 86.2%;
        h3 {
          font-size: 15px;
          padding-bottom: 3px;
        }
        p {
          font-size: 11px;
        }
        span {
          font-size: 11px;
        }
        a {
          font-size: 12px;
          margin-top: 5px;
          .fa-angle-double-down {
            position: relative;
            top: 1px;
          }
        }
      }
      .overlay-academybig {
        h3 {
          padding-bottom: 10px;
        }
        a {
          margin-top: 10px;
        }
      }
    }
    @media (min-width:936px) and (max-width:1099px) {
      .overlay-academy, .overlay-academybig {
        padding: 12px 12px 0;
        height: 88.5%;
        h3 {
          font-size: 15px;
          padding-bottom: 3px;
        }
        p {
          font-size: 11px;
        }
        span {
          font-size: 11px;
        }
        a {
          font-size: 12px;
          margin-top: 5px;
          .fa-angle-double-down {
            position: relative;
            top: 1px;
          }
        }
      }
      .overlay-academybig {
        h3 {
          padding-bottom: 10px;
        }
        a {
          margin-top: 10px;
        }
      }
    }
    @media (min-width:1100px) and (max-width:1279px) {
      .overlay-academy, .overlay-academybig {
        padding: 12px;
        height: 89.5%;
        h3 {
          font-size: 24px;
        }
        a {
          margin-top: 20px;
        }
      }
      .overlay-academybig {
        h3 {
          padding-bottom: 20px;
        }
        a {
          margin-top: 30px;
        }
      }
    }
  }
  &-main {
    .container {
      color: $color-grey2;

      >p {
        max-width: 900px;
        margin: auto;
        text-align: center;
      }
    }

    .infographics {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 98px;
    }

    .circle-chart {
      display: flex;
      justify-content: space-between;
      width: 50%;
      margin-bottom: 95px;

      .chart-legend-wrapper {
        width: 36%;

        p {
          margin-bottom: 18px;
          font-weight: 400;
        }
      }

      .legend-item {
        margin-bottom: 10px;
        font-weight: 300;

        &::before {
          display: inline-block;
          width: 6px;
          height: 6px;
          margin-right: 6px;
          border-radius: 50%;
          content: '';
          vertical-align: middle;
        }

        @for $i from 1 through length($colors-infographic) {
          &:nth-child(#{$i}) {
            &::before {
              background-color: nth($colors-infographic, $i);
            }
          }
        }
      }
    }

    .legend {
      padding-left: 9px;
      border-left: 1px solid transparentize($color-grey2, .8);
      font-weight: 300;
      line-height: 1.57;
    }

    .people-chart {
      display: flex;
      justify-content: space-between;
      width: 45%;

      >* {
        width: 220px;
      }

      .count {
        margin-bottom: 14px;
        font-size: 35px;
        font-weight: 400;
      }

      .chart {
        position: relative;
        height: 140px;
        margin-bottom: 25px;
        background: url('/images/academy/human.png') -1%;

        &::before,
        &::after {
          position: absolute;
          left: 0;
          content: '';
        }

        &::before {
          top: -1px;
          width: 100%;
        }

        &::after {
          background-position-x: -1%;
        }
      }

      .webinars {
        .chart {
          &::before,
          &::after {
            background-image: url('/images/academy/human-red.png');
          }

          &::before {
            height: 55px;
            background-position: -1% -5%;
          }

          &::after {
            top: 56px;
            width: 165px;
            height: 30px;
          }
        }
      }

      .subscribers {
        .chart {
          &::before,
          &::after {
            background-image: url('/images/academy/human-blue.png');
          }

          &::before {
            height: 85px;
            background-position-x: -1%;
          }

          &::after {
            top: 85px;
            width: 90px;
            height: 30px;
          }
        }
      }
    }

    .achievements {
      display: flex;
      justify-content: space-between;
      width: 55%;
    }

    .achievement {
      width: 185px;

      .count-label {
        position: relative;
        margin-bottom: 29px;
        padding-left: 5px;
        color: transparentize($color-black, .93);
        font-size: 150px;
        line-height: 111px;

        &::before {
          display: flex;
          position: absolute;
          bottom: -5px;
          left: 0;
          align-items: center;
          justify-content: center;
          width: 90px;
          height: 90px;
          border-width: 12px;
          border-style: solid;
          border-radius: 50%;
          font-size: 24px;
          line-height: 1;
          content: attr(data-label);
        }
      }

      &:nth-child(1) {
        .count-label {
          &::before {
            border-color: $color-infographic-1;
            color: $color-infographic-1;
          }
        }
      }

      &:nth-child(2) {
        .count-label {
          &::before {
            border-color: $color-infographic-3;
            color: $color-infographic-3;
          }
        }
      }

      &:nth-child(3) {
        .count-label {
          &::before {
            border-color: $color-infographic-5;
            color: $color-infographic-5;
          }
        }
      }
    }

    .map {
      position: relative;
      width: 425px;
      background: url('/images/academy/map.png') 100% 50% / contain no-repeat;

      .mark {
        position: absolute;
        width: 21px;
        height: 23px;
        background: url('/media-new/images/icon/ico-slotegrator-logo.svg') no-repeat;
        filter: drop-shadow(0 2px 5px transparentize($color-black, .7));

        &:nth-child(1) {
          top: 14.735%;
          right: 80%;
        }

        &:nth-child(2) {
          top: 16.52%;
          right: 42.12%;
        }

        &:nth-child(3) {
          top: 12.5%;
          right: 18.355%;
        }

        &:nth-child(4) {
          top: 46.88%;
          right: 44.236%;
        }
      }
    }
  }

  @include breakpoint(1099px) {
    &-main {
      .circle-chart {
        justify-content: center;
        width: 100%;

        .chart-legend-wrapper {
          max-width: 200px;
          margin-left: 70px;
        }
      }

      .people-chart {
        justify-content: center;
        width: 100%;
        margin-bottom: 82px;

        .webinars {
          margin-right: 115px;
        }
      }

      .achievements {
        justify-content: space-around;
        order: 4;
        width: 100%;
      }

      .map {
        height: 226px;
        margin: 0 auto 53px;
      }
    }
  }

  @include breakpoint(659px) {
    &-categories {
      flex-direction: column;
      margin-bottom: -25px;

      .academy-category {
        width: 100%;
        max-width: 320px;
        margin: 0 5px 25px;
      }
    }

    &-main {
      .infographics {
        margin-top: 78px;
      }

      .circle-chart {
        flex-wrap: wrap;
        margin: -50px 0 65px;

        .chart-img {
          margin-top: 50px;
        }

        .chart-legend-wrapper {
          width: 100%;
          margin: 50px 20px 0;
        }
      }

      .people-chart {
        flex-wrap: wrap;
        margin: -50px -10px 82px;

        .webinars {
          margin: 50px 10px 0;
        }

        .subscribers {
          margin: 50px 10px 0;
        }
      }

      .achievements {
        flex-wrap: wrap;
        width: calc(100% + 20px);
        margin: 0 -10px 15px;
      }

      .achievement {
        margin: 0 10px 30px;
      }
    }
  }

  @include breakpoint(479px) {
    &-main {
      .map {
        width: 291px;
        height: 154px;
      }
    }
  }
}
.slick-slider .slick-track, .slick-slider .slick-list{
  display: flex;
  align-items: center;
  position:relative;

}
#btn_notSpam{
  width: 0;
  height: 0;
  opacity: 0;
  display: none;
}

.academy-inner {
  @include details-inner;

  .record {
    .title {
      margin-bottom: 16px;
    }
  }

  .webinar {
    &-img {
      width: 47%;
      max-width: 460px;
      margin-right: 30px;
      float: left;
    }

    &-info {
      margin-bottom: 33px;
    }

    h3 {
      margin-bottom: 20px;
    }

    time {
      display: block;
      margin-bottom: 20px;
      color: $color-grey2;

      span {
        margin-right: 30px;
      }

      i {
        margin-right: 10px;
        color: $color-red;

        &::before {
          font-size: 1.6rem;
        }

        &.clock {
          display: inline-block;
          width: 16px;
          height: 16px;
          background: url('../images/clock-icon.png') 50% 50% / cover no-repeat;
          vertical-align: middle;
        }
      }
    }

    p {
      color: $color-grey2;
    }

    .btn {
      &:first-of-type {
        margin-right: 15px;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
	.webinar{
		&-buttons{
			clear: both;
			margin-top: 33px;
			width: 100%;
			padding-left: 7px;
			text-align: center;
		}

		&-img{
			margin-bottom: 30px;
		}
	}
  }
  @media only screen and (max-width: 989px) {
    &.academy-webinars {
      padding-top: 26px;
    }

    .webinar {
      &-img {
        margin-bottom: 20px;
      }

    //   &-buttons {
    //     width: 100%;
    //     padding-left: 7px;
    //     text-align: center;
    //   }

      .btn {
        width: 230px;

        &:first-of-type {
          margin-right: 45px;
        }
      }
    }
  }


  @media only screen and (max-width: 699px) {
    .webinar {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-img,
      &-info {
        width: 100%;
        margin-bottom: 20px;
      }

      &-img {
        margin-right: auto;
        margin-left: auto;
        float: none;
      }

      &-buttons {
        padding: 0;

        .btn {
          width: 100%;
          max-width: 400px;
          margin-right: 0;

          &:first-of-type {
            margin-right: 0;
            margin-bottom: 10px;
          }
        }
      }

      h3 {
        font-size: 1.6rem;
        font-weight: 400;
        line-height: 1.625;
      }
    }
  }
}

// academy publications
.page-academy-single-article {
  h1 {
    font-size: 3.2rem;
    font-weight: 300;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 300;
  }
  h3 {
    font-size: 2.2rem;
    font-weight: 300;
  }

  .tags {
    margin-left: 0;
    margin-top: 15px;
  }

  @media (max-width: 1023px) and (min-width: 768px) {
    .news-list .slick-slide img {
      height: auto;
    }
  }
}

.academy-webinars {
  padding-top: 80px;
}

//////////////////////////
//======Inner pages=====//
//////////////////////////
.inner-page {
  @include details-inner;

  .container {
    margin-top: 0;
    margin-bottom: 0;

    h1 {
      max-width: 900px;
    }
  }

  @media only screen and (max-width: 989px) {
    .socials {
      a {
        width: 2.2rem;

        &::before {
          font-size: 2.2rem;
        }
      }

      &-colored {
        a {
          width: auto;
        }
      }
    }

    +.news-list {
      padding-top: 0;
    }
  }


  @media only screen and (max-width: 699px) {
    .single-article {
      .details {
        flex-direction: column;
        align-items: flex-start;
      }

      &-stats {
        flex-direction: column;
        align-items: flex-start;
        position: relative;

        .info {
          position: absolute;
          top: 9px;
          margin-left: -7px;
        }

        .rating {
          order: 3;
          margin-top: 19px;
        }

        .socials {
          margin-left: 50px;
          text-align: left;
        }
      }
    }
  }

  @media only screen and (max-width: 499px) {
    .single-article {
      &-stats {
        .info {
          margin-top: 5px;
          top: 2px;
        }
      }
    }
  }
}

.single-article {
  &-img {
    img {
      margin: auto;
    }
  .article-text {
    a {
      color: #c9252b;
    }
  }
  }

  .termExplanation{
	padding: 30px!important;
	border: 1px solid #a0b0cc!important;
	border-radius: 9px;
	position: relative;
	background: transparent!important;
	display: block;
	font-style: normal;

	&::after{
		position: absolute;
		width: 23px;
		height: 24px;
		content: '';
		right: -12px;
		top: 20px;
		padding: 15px 0px;
		background: #fff url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABcAAAAYCAMAAAAmopZHAAAAe1BMVEVHcEygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMygsMzP/TlDAAAAKHRSTlMAJ8XdCPcrEMEMo9UwZG4ivazSE2gD2XO4G5RGmu5gs1vkkMz16IA/1LjL3QAAAMpJREFUKM9tkukSgjAMhLcCpS1nBeRQVMAj7/+EVkdnGiQz/fNtZrNpC6wrxGbF1vRbuAymMf3nliRO7vDST5CCE/gQ3dERtANuHUuiDu1HKBbLsYR6C6OqPBMVSGTR4ASrWUCJOsoxU+GZVF/ciH0X+92H1JnkEHvlmWgPVyxJinPUQiQMd5/uFgXHlh4/7I2EXAYyt9zhnfbXv46FoMQF5Dg0BujpusLQQY1GGipjfufnSGTzdLmssHuEe2LrdI1hhryvNr9As8Fe9qUNj0329joAAAAASUVORK5CYII=');
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}

	@media only screen and (max-width: 768px) {
		padding: 25px;

		&::after{
			right: -8px;
		}
	  }
  }

  @media only screen and (min-width: 990px) {
    &-img {
      img {
        width: 100%;
        height: 630px;
        object-fit: cover;
      }
    }
  }

  &-container {
    max-width: 810px;
    margin: auto;
    .article-text {
      a {
        color: #c9252b;
        text-decoration: underline;
      }
      ol {
        li {
          font-weight: 300;
          line-height: 1.63;
        }
      }
      img {
        width: 100%;
        height: auto !important;

        @media only screen and (max-width: 800px) {
          float: none;
          width: 80% !important;
          margin: 30px auto;
        }
      }
      img.left {
        width: 30%;
        float: left;
        margin: 45px 20px 20px 0;
        @media only screen and (max-width: 800px) {
          float: none;
          width: 80% !important;
          margin: 30px auto;
        }
      }
      img.right {
        width: 30%;
        float: right;
        margin: 45px 0px 20px 45px;
        @media only screen and (max-width: 800px) {
          float: none;
          width: 80% !important;
          margin: 30px auto;
        }
      }

    }
    .article-map {
      padding: 75px 40px 80px;
      margin: 30px 0 45px;
      border: 1px solid rgba(0, 0, 0, 0.12);
      li {
        color: #707070;
        a {
          color: #707070;
        }
      }
      .title-article-map {
        font-size: 2.2rem;
      }
    }
  }

  &-info {
    margin-bottom: 35px;
    color: $color-grey2;
    font-size: 1.6rem;
  }

  &-stats {
    display: flex;
    align-items: center;
    margin-top: 10px;

    .rating {
      margin-right: auto;
      margin-left: 0;
    }

    .info {
      display: flex;
      align-items: center;
      color: $color-grey2;

      .fa {
        font-weight: 300;

        &::before {
          padding-right: 3px;
          font-size: 1.7rem;
        }
      }
    }

    .socials {
      margin-left: 40px;
    }
  }

  @media only screen and (max-width: 989px) {
    &-stats {
      .info {
        .fa {
          font-size: 1.6rem;

          &::before {
            font-size: 1.9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 699px) {
    .details {
      flex-direction: column;
      align-items: flex-start;
    }

    &-stats {
      flex-direction: column;
      align-items: flex-start;

      .rating {
        order: 3;
        margin-top: 19px;
      }

      .socials {
        margin-left: 20px;
      }
    }
    iframe {
      height: 380px;
    }
  }

  @media only screen and (max-width: 499px) {
    &-stats {
      .info {
        margin-top: 5px;
      }
    }
    iframe {
      height: 300px;
    }
  }
}

// Inner pages grid
.grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  &-item {
    display: flex;
    flex-direction: column;
    width: 47.4%;
    margin-bottom: 80px;
    color: $color-grey2;

    .title {
      margin: 8px 0;
      color: $color-black;
      font-size: 1.6rem;
      text-align: left;

      a {
        color: inherit;
        font-weight: 400;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .article-brief {
      margin-top: 10px;
      margin-bottom: 10px;
    }

    &-bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .details {
        margin-top: 0;
        display: flex;
      }
    }

    &-ref {
      @include angle-double-right;
      padding-right: 14px;
      color: $color-red;

      &::before,
      &::after {
        top: 5px;
        border-color: $color-red;
      }

      &::before {
        right: 1px;
      }

      &::after {
        right: 5px;
      }
    }
  }

  @media only screen and (max-width: 989px) {
    .article-brief {
      margin-bottom: 18px;
    }
  }

  @media only screen and (max-width: 699px) {
    .grid {
      justify-content: center;
    }

    .grid-item {
      width: 100%;
      max-width: 360px;
      margin-right: auto;
      margin-bottom: 40px;
      margin-left: auto;

      .title {
        margin-top: 18px;
      }

      &-ref {
        display: none;
      }
    }
  }
}

// Illustrations
.illustration {
  display: block;
  position: relative;
  width: 100%;
  font-weight: 300;
  overflow: hidden;

  >img {
    width: 100%;
    transition: all .2s ease;
  }

  >a {
    >img {
      width: 100%;
      transition: all .2s ease;
    }
    &:hover {
      >img {
        transform: scale(1.1);
      }
    }
  }

  .logo-slotegrator {
    display: inline-block;
    position: absolute;
    top: 8%;
    left: 7%;
    width: 36%;
  }

  .details {
    @include gradient-grey-vertical-180;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40.5%;

    time,
    .info {
      position: absolute;
      bottom: 15%;
      color: $color-white;
      font-size: 1.4rem;
      .ico-comment {
        &::before {
          background-image: url(../images/icons/iconfinder-comments-white.svg);
        }
      }
      .ico-views {
        &::before {
          background-image: url(../images/icons/iconfinder-views-white.svg);
          background-position: 1px 1px;
        }
      }
    }

    time {
      left: 7%;
    }

    .info {
      right: 7%;

      span {
        font-weight: 300;

        &::before {
          margin-right: 4px;
        }

        &:last-of-type {
          margin-left: 11px;
        }
      }
    }
  }

  &:hover {
    >img {
      &:not(.logo-slotegrator) {
        transform: scale(1.1);
      }
    }
  }

  &.no-scale {
    &:hover {
      img {
        transform: none;
      }
    }
  }

  &.main {
    margin-bottom: 80px;
    cursor: pointer;

    .details {
      time {
        right: 163px;
        left: auto;
      }

      .info {
        right: 25px;
      }

      &--left {
        position: absolute;
        bottom: 15%;
        left: 25px;
        width: 60%;
        color: $color-white;
        font-size: 1.6rem;

        .title {
          display: block;
          margin-bottom: 10px;
          font-size: 1.6rem;
        }

        .tags {
          font-size: 1.4rem;
          margin-top: 10px;
          .tag {
            border-color: $color-white-50;
            @media only screen and (max-width: 619px) {
              border-color: transparentize($color-grey2, .5);
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 799px) {
    &.main {
      .details {
        &--left {
          width: 50%;
        }
      }
    }

    .details {
      time,
      .info {
        font-size: 1.6rem;

        .fa {
          &::before {
            font-size: 2rem;
          }
        }
      }
    }

    &.no-scale {
      .details {
        time,
        .info {
          font-size: 1.4rem;

          span {
            &:last-of-type {
              margin-left: 27px;
            }
          }

          .fa {
            &::before {
              font-size: 1.4rem;
            }
          }
        }

        time {
          right: 153px;
        }
      }
    }
  }

  @media only screen and (max-width: 619px) {
    &.main {
      width: auto;
      height: auto;
      margin-right: -10px;
      margin-bottom: 136px;
      margin-left: -10px;
      overflow: visible;
      a {
        img {
          max-height: 333px;
          object-fit: cover;
        }
      }

      >img {
        position: relative;
        left: 50%;
        width: auto;
        max-width: none;
        height: 100%;
        transform: translateX(-50%);
      }

      &:hover {
        >img {
          transform: translateX(-50%);
        }
      }

      .details {
        &--left {
          position: static;
          margin-top: 28%;
          padding-left: 10px;
          width: calc(100% - 20px);
          color: $color-black;

          .title {
            font-weight: 400;
          }

          .tag {
            a {
              color: $color-grey2;
            }
          }
        }
        time {
          right: auto;
          left: 25px;
        }
      }
    }

    .details {
      time,
      .info {
        font-size: 1.4rem;

        .fa {
          &::before {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 540px) {
    &.main {
      a {
        img {
          max-height: 292px;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    &.main {
      a {
        img {
          max-height: 268px;
        }
      }
      .details {
        &--left {
          bottom: -75px;
        }
      }
    }
  }
  @media only screen and (max-width: 459px) {
    &.main {
      a {
        img {
          max-height: 257px;
        }
      }
    }
  }
  @media only screen and (max-width: 426px) {
    &.main {
      a {
        img {
          max-height: 239px;
        }
      }
      .details {
        &--left {
          bottom: -80px;
        }
      }
    }
  }
  @media only screen and (max-width: 377px) {
    &.main {
      a {
        img {
          max-height: 212px;
        }
      }
    }
  }
  @media only screen and (max-width: 325px) {
    &.main {
      a {
        img {
          max-height: 183px;
        }
      }
    }
  }
}

// Tags
.tags {
  margin-bottom: -7px;
  margin-left: -3px;
  clear: both;

  .tag {
    display: inline-block;
    height: 2.5rem;
    margin-bottom: 7px;
    margin-right: 6px;
    padding: 0 1rem;
    transition: border-color .3s;
    border: 1px solid transparentize($color-grey2, .5);
    border-radius: 5px;
    line-height: 2.4rem;

    &:hover {
      border-color: $color-red;
    }
  }
}
.tag-description {
  padding: 0 0 50px 0px!important;
  width: 100%;
  text-align: center;
  margin-top: -20px;
  margin-top: -20px;
  font-size: 18px;
  @media only screen and (max-width: 700px) {
    font-size: 15px;
    padding: 0 0 30px 0px!important;
  }
}



// Rating
%rating-hover {
  color: $color-star-rating;
  cursor: pointer;

  &::before {
    @include font-awesome;
    font-size: inherit;
    content: '\f005';
  }
}

.rating {
  position: relative;
  margin-left: auto;

  &:not(:checked) {
    >input {
      position: absolute;
      clip: rect(0, 0, 0, 0);

      &:not(:disabled) {
        ~label {
          &:hover,
          &:hover ~ label {
            @extend %rating-hover;
          }
        }

        +label {
          &:active {
            transform: translate(1px, 1px);
          }
        }
      }
    }

    >label {
      width: 2.2rem;
      padding: .1rem;
      float: right;
      transition: color .1s, text-shadow .1s, transform .1s;
      color: $color-grey2;
      font-size: 2rem;
      line-height: 1;
      white-space: nowrap;
      overflow: hidden;

      &::before {
        @include font-awesome;
        padding-right: 5px;
        font-size: inherit;
        content: '\f006';
      }
    }
  }

  >input {
    &:checked {
      ~label {
        color: $color-star-rating;

        &::before {
          content: '\f005';
        }
      }

      &:not(:disabled) {
        ~label {
          &:hover,
          &:hover ~ label {
            @extend %rating-hover;
          }
        }

        +label {
          &:hover,
          &:hover ~ label {
            @extend %rating-hover;
          }
        }
      }

      &:not(:disabled) {
        >label {
          &:hover ~input {
            &:checked ~ label {
              @extend %rating-hover;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 989px) {
    &:not(:checked) {
      >label {
        width: 2.5rem;
        font-size: 2.3rem;
      }
    }
  }

  @media only screen and (max-width: 699px) {
    margin-top: 23px;
    margin-left: 0;
  }
}

.get-access {
  margin: 60px 0 40px;
  padding: 75px 20px 80px;
  border: 1px solid $color-black-12;
  text-align: center;

  h3 {
    margin-bottom: 30px;
  }

  .form {
    max-width: 500px;
    .submit-btn {
      padding: 0 4rem;
      @media (min-width: 520px) {
        width: 172px;
      }
    }
  }

  @media only screen and (max-width: 699px) {
    padding: 45px 20px 50px;
  }
}

.get-access2{

	&-wrapper{
		margin: 20px 0;
		position: relative;
		overflow: hidden;
		padding: 60px 100px;
	}

	&-bg{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		width: 100%;

		transform: scale(1.1);
		filter: blur(5px) brightness(0.6);
		background: url(../images/bg/academy-bg.jpg) 50% 0/cover no-repeat;;
	}
	&-title{
		text-align: center;
		color: white;
		font-size: 22px;
		font-weight: 300;
		margin-bottom: 30px;
	}

	&-text{
		text-align: center;
		color: white;
		font-size: 16px;
		font-weight: 300;
		margin-bottom: 40px;
	}


	.form-horizontal{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		// padding-bottom: 55px;
        max-width: 100%;
		button{
			width: 32%;
		}
	}

	@media screen and (max-width:800px) {

      &-wrapper{
        padding: 60px 20px;
      }
		//.form-horizontal{
        //
		//	.input{
		//		width: 100%;
		//	}
        //
		//	.field-form-recaptcha{
		//		margin-left: 0;
		//		width: auto;
		//		max-width: 50%;
		//	}
        //
		//	[class*='-recaptcha'] > div > div{
		//		transform-origin: 0 0;
		//	}
        //
		//	button{
		//		order: 1;
		//		width: auto
		//	}
		//}
	}

	//@media screen and (max-width:640px) {
	//	.form-horizontal{
    //
	//		.input{
	//			width: 100%;
	//		}
    //
	//		.field-form-recaptcha{
	//			max-width: 100%;
	//			width: 100%;
	//			margin: 10px 5px;
	//		}
    //
	//		[class*='-recaptcha'] > div{
	//			max-width: 200px;
	//			margin: 0 auto;
	//		}
    //
	//		[class*='-recaptcha'] > div > div{
	//			transform-origin: 0 0;
	//		}
    //
	//		button{
	//			margin: 10px auto 0;
	//			width: 100%;
	//		}
	//	}
	//}
}


// Blog page
.blog {
  .container {
    display: flex;
    flex-wrap: wrap;
    .blog-main {
      a.nomargin {
        margin-bottom: 0;
      }
    }
  }

  h1 {
    width: 100%;
  }

  &-main {
    width: 68.5%;
  }

  .grid-item {
    margin-bottom: 50px;
    a.illustration {
      height: 250px;
      margin-bottom: 0;
      img {
        height: 250px;
        object-fit: fill;
      }
    }

    .title {
      margin-top: 18px;
    }

    .article-brief {
      margin-top: 0;
      margin-bottom: 14px;
    }
  }

  @media only screen and (max-width: 989px) {
    &-main {
      width: 100%;
    }

    .grid-item {
      margin-bottom: 50px;
    }
  }
}

// News category page
.news {
  .container {
    display: flex;
    display: -webkit-flex;
    flex-wrap: wrap;
  }

  h1 {
    width: 100%;
  }

  &-main {
    width: 68.5%;
  }

  &-grid {
    a.illustration {
      >img {
        height: 178px;
        object-fit: cover;
      }
    }
  }

  .grid {
    &-item {
      flex-direction: row;
      @media (max-width: 699px) {
        flex-wrap: wrap;
      }
      width: 100%;

      &-image {
        max-width: 263px;
        margin-right: 30px;
        margin-bottom: 0;
      }

      .article-brief {
        text-align: justify;
      }
    }

    &-right {
      flex: 1;
    }
  }

  .illustration {
    &.main {
      .title {
        margin-bottom: 0;
      }
    }
  }

  .details {
    // display: flex;
    // align-items: center;

    .fa {
      margin-left: 30px;

      &::before {
        padding-right: 3px;
        font-size: 1.7rem;
      }
    }
  }

  @media only screen and (max-width: 989px) {
    &-main {
      width: 100%;
    }

    .sidebar {
      h3 {
        display: none;
      }
    }
  }

  @include breakpoint(699px) {
    .grid {
      &-item-image,
      &-right {
        flex: none;
        width: 100%;
        max-width: 360px;
        margin: auto;
      }

      &-item {
        &-image {
          img {
            margin: auto;
          }
        }

        &-bottom {
          flex-wrap: wrap;
          align-items: flex-start;
        }

        .details {
          margin-right: 20px;
          margin-bottom: 16px;
          &--left {
            .tags {
              .tag {
                border-color: rgba(112,112,112,.5);
              }
            }
          }
        }

        &-ref {
          display: block;
        }
      }
    }
  }

  @include breakpoint(619px) {
    .illustration {
      &.main {
        a {
          img {
            max-height: 333px;
          }
        }

        .details--left {
          position: static;
          margin-top: 28%;
          padding-left: 10px;
        }
      }
    }
  }
  @media only screen and (max-width: 540px) {
    .illustration {
      &.main {
        a {
          img {
            max-height: 292px;
          }
        }
      }
    }
  }

  @include breakpoint(459px) {
    .illustration {
      &.main {
        a {
          img {
            max-height: 258px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 410px) {
    .illustration {
      &.main {
        a {
          img {
            max-height: 230px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 377px) {
    .illustration {
      &.main {
        a {
          img {
            max-height: 212px;
          }
        }
      }
    }
  }
}
//News publications
.page-news {
  .news-list {
    .grid-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .title {
        margin: -2px 0 0;
        @media only screen and (max-width: 699px) {
          margin: 14px 0 0;
        }
      }
      .tags {
        margin-bottom: 6px;
        margin-left: 0px;
      }
      .article-bottom {
        margin-top: auto;
      }
    }
  }
}

.page-news-inner {
  h1 {
    font-size: 3.2rem;
    font-weight: 300;
  }
  h2 {
    font-size: 2.2rem;
    font-weight: 300;
  }
  h3 {
    font-size: 2.2rem;
    font-weight: 300;
  }
  .article-text p .btn-primary {
    display: block;
    margin: 30px auto 20px auto;
  }
  .news-list {
    padding-top: 50px;
  }
  .tags {
    margin-top: 15px;
  }
}
//Test for icons
.ico-comment {
  display: inline-block;
  height: 20px;
  margin-left: 7px;
  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../images/icons/iconfinder-comments.svg);
    background-repeat: no-repeat;
    background-position: center;
    vertical-align: middle;
  }
}
.ico-views {
  display: inline-block;
  height: 20px;
  margin-left: 7px;
  &::before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url(../images/icons/iconfinder-views.svg);
    background-repeat: no-repeat;
    background-position: 1px 1px;
    vertical-align: middle;
  }
}
// Sidebar
.sidebar {
  width: 25.6%;
  margin-left: auto;
  .side-bar-title,
  .tag-list-title,
  .sidebar-theme-title{
    margin-top: -3px;
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-black-12;
    font-weight: 300;
    display: inline-block;
    font-size: 2.2rem;
    line-height: 1.33;
  }
  h3 {
    margin-top: -3px;
    margin-bottom: 20px;
    padding-bottom: 12px;
    border-bottom: 1px solid $color-black-12;
    font-weight: 300;
    display: inline-block;
  }

  .tags {
    margin-bottom: -10px;
  }

  .tag {
    margin-bottom: 10px;
    &.active {
      border-color: #d50000;
    }

    a {
      color: $color-grey2;
    }
  }

  &-subscribe {
    margin: 50px 0;
    padding: 30px 30px 40px;
    border: 1px solid $color-black-12;
    text-align: center;
    .side-bar-subscribe{
      margin-bottom: 27px;
      font-weight: 300;
      font-size: 1.4rem;
      text-transform: uppercase;
    }
    h4 {
      margin-bottom: 27px;
      font-weight: 300;
    }

    .btn {
      width: 100%;
      font-size: 1.4rem;
      font-weight: 300;
    }
  }

  .provider-list {
    margin-bottom: 27px;
    column-gap: 5px;
    column-count: 2;
    @media (min-width: 990px) and (max-width: 1025px) {
      margin-left: -10px;
    }

    li {
      @include angle-double-right;
      margin-bottom: 12px;
      padding-left: 13px;
      vertical-align: middle;

      &::before {
        top: 8px;
        left: 0;
        vertical-align: middle;
      }

      &::after {
        top: 8px;
        left: 3px;
        vertical-align: middle;
      }

      &.active {
        color: $color-red;

        &::before,
        &::after {
          border-color: $color-red;
        }
      }

      a {
        font-weight: 300;
      }
    }
  }

  .subjects-list {
    margin-bottom: 16px;

    li {
      @include angle-double-right;
      margin-bottom: 12px;
      padding-left: 13px;

      &.active{
        color: #d50000;
      }

      &::before {
        left: -2px;
        transition: border-color .3s;
      }

      &::after {
        left: 1px;
        transition: border-color .3s;
      }

      &:hover {
        &::before,
        &::after {
          border-color: $color-red;
        }
      }

      a {
        transition: color .3s;
        font-weight: 300;
        &:hover {
          color: $color-red;
        }
      }
    }
  }

  .news {
    &-item {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
    }

    &-img {
      width: 70px;
      img {
        object-fit: cover;
      }
    }

    &-title {
      width: 207px;
      margin: -3px 0 0 14px;
      color: $color-black;

      p {
        font-weight: 400;
      }
    }

    &-date {
      color: $color-grey2;
      font-size: 12px;
      font-weight: 300;
    }
  }

  @media only screen and (max-width: 989px) {
    width: 100%;
    margin-top: 80px;

    .tags {
      margin-bottom: 80px;
    }

    &-subscribe {
      display: none;
    }

    .provider-list {
      column-count: 1;

      li {
        display: inline-block;
        margin-right: 26px;
      }
    }

    .subjects-list {
      display: none;
    }

    .news {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      &-item {
        width: 47.8%;
      }

      &-title {
        flex: 1;
        width: auto;
      }
    }
  }

  @media only screen and (max-width: 750px) {
    .provider-list {
      margin-bottom: 42px;
    }

    .news {
      &-item {
        width: 100%;
      }
    }
  }
}

// Tiles grid
.tiles {
  &-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    &-item {
      display: flex;
      flex-direction: column;
      width: 330px;
      min-height: 385px;
      text-align: center;
    }
  }

  img {
    margin: auto;
  }

  &-title {
    margin: 10px 0;
    font-size: 2.2rem;
    font-weight: 300;
    text-transform: uppercase;
  }

  p {
    margin-bottom: 24px;
    color: $color-grey2;
  }

  .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: auto;
    padding-top: 10px;
  }

  .btn {
    width: 160px;
    height: 40px;
    padding: 0 2rem;
    font-size: 1.4rem;
    line-height: 1.73;
  }

  @media only screen and (max-width: 1099px) {
    &-grid {
      margin: auto;

      &-item {
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 750px) {
    padding-bottom: 50px;

    &-grid {
      flex-direction: column;

      &-item {
        width: 100%;
        max-width: 330px;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  @media only screen and (max-width: 379px) {
    .buttons {
      justify-content: center;
    }

    .btn {
      width: 140px;
      padding: 0 1rem;

      +.btn {
        margin-left: 10px;
      }
    }
  }
}

// This style special for new design partners landings
.page-partners-inner {
  .last-games {
    h3 {
      display: block;
      max-width: 770px;
      margin: 0 auto 50px;
      color: #000000;
      font-weight: 300;
      text-align: center;
      text-transform: uppercase;
      font-size: 3.1rem;
      line-height: 1.29;
      @media only screen and (max-width: 480px) {
        font-size: 2.2rem;
        margin-top: 0;
      }
    }
  }
  &:before {
    @media (max-width: 799px) {
      height: 0 !important;
    }
  }
  .topbanner-wrap {
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-color: #000000;
    padding-top: 125px;
    padding-bottom: 150px;
    @media (min-width: 1950px) {
      padding-top: 250px;
      padding-bottom: 250px;
    }
    @media (max-width: 1080px) {
      background-position: 20% 0;
    }
    @media (max-width: 860px) {
      background-position: 34% 0;
    }
    @media (max-width: 960px) {
      background-position: 28% 0;
    }
    @media (max-width: 855px) {
      background-position: 36% 0;
    }
    @media (max-width: 750px) {
      background-position: 38% 0;
    }
    @media (max-width: 620px) {
      background-position: 0 0;
      padding-top: 66%;
      padding-bottom: 50px;
    }
    @media (max-width: 360px) {
      padding-top: 74%;
    }
    @media (max-width: 335px) {
      padding-top: 80%;
    }
    .topbanner-content {
      max-width: 500px;
      margin: 0 auto;
      @media (max-width: 1080px) {
        margin: 0 0 0 auto;
      }
      @media (max-width: 620px) {
        margin: 0 auto;
      }
      .topbanner-tittle {
        margin-bottom: 10px;
        color: #ffffff;
        @media (max-width: 620px) {
          text-align: center;
          padding-left: 0;
        }
      }
      .topbanner-desc {
        max-width: 445px;
        margin: 0 auto 3rem;
        color: #ffffff;
        font-size: 1.4rem;
        text-align: center;
        line-height: 1.36;
        @media (max-width: 620px) {
          text-align: center;
          padding: 0 4%;
        }

      }
      .topbanner-buttons {
        max-width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around;
        @media (max-width: 420px) {
          flex-direction: column;
          padding: 0 4%;
        }
        .btn-secondary {
          font-size: 1.4rem;
          min-width: 200px;
          @media (max-width: 420px) {
            margin-bottom: 1rem;
          }
        }
        .btn-primary {
          font-size: 1.4rem;
          min-width: 200px;
        }
      }
    }
  }
  .partner {
    .page {
     .partner-top-left {
       h2 {
         width: 100%;
         font-size: 2.2rem;
         margin-bottom: 18px;
       }
     }
      .partner-content{
        p {
          .link{
            color: #fe0000 !important;
          }
        }
      }
    }
    .games-list {
      display: block;
      position: relative;
      width: calc(100% + 20px);
      margin: 0 -10px 3rem;
      font-size: 0;
      @media (min-width: 350px) and (max-width: 459px) {
        margin: 0;
      }

      &::before {
        @include total-center;
        display: block;
        position: absolute;
        width: 65px;
        height: 65px;
        transition: all .4s ease;
        background: url('../images/icon/ico-loading.svg');
        content: '';
        opacity: 0;
        visibility: hidden;
        animation: rotateSpinner 1.2s infinite linear;
        pointer-events: none;
      }

      >li {
        display: inline-block;
        position: relative;
        width: calc(25% - 20px);
        margin: 0 10px 10px 10px;
        padding: 3px 3px 0px 0px;
        transition: all .3s ease;
        font-size: 1.7rem;
        opacity: 1;
        overflow: hidden;
        @media (max-width: 989px) {
          width: calc(33.333% - 20px);
        }
        @media (max-width: 690px) {
          width: calc(50% - 20px);
        }
        @media (max-width: 459px) {
          display: none;
          width: 70%;
          &:nth-child(-n + 3) {
            display: block;
            margin: 0 auto 10px auto;
          }
        }
        @media (max-width: 350px) {
          width: 90%;
        }
        // @media (min-width: 350px) and (max-width: 459px) {
        //   width: 70% !important;
        //   display: block;
        //   margin: 0 auto;
        //   display: none;
        // }

        .illustration {
          display: block;
          img {
            width: 100%;
            @media (min-width: 990px) {
              height: 174px;
              object-fit: cover;
            }
            @media (max-width: 989px) {
              height: 159px;
              object-fit: cover;
            }
          }
        }

        .wrap {
          position: absolute;
          top: 3px;
          right: 3px;
          width: 85px;
          height: 88px;

          & ~ .overlay {
            width: 100%;
/*            height: 177px;*/
          }
        }

        .game-tag {
          display: block;
          position: relative;
          top: 15px;
          left: -5px;
          width: 120px;
          padding: 4px 0;
          transform: rotate(45deg);
          color: $color-white;
          font-size: 17px;
          font-weight: 700;
          text-align: center;
          z-index: 2;

          &::before,
          &::after {
            position: absolute;
            bottom: -3px;
            border-right: 3px solid transparent;
            border-left: 3px solid transparent;
            content: '';
          }

          &::before {
            left: 0;
          }

          &::after {
            right: -1px;
          }

          &.is-top {
            background-color: $color-blue;

            &::before,
            &::after {
              border-top: 3px solid $color-blue2;
            }
          }

          &.is-new {
            background-color: $color-red-bright;

            &::before,
            &::after {
              border-top: 3px solid $color-red3;
            }
          }
        }

        .overlay {
          position: absolute;
          top: -100%;
          left: 0;
          width: 99%;
 /*         height: 177px;*/
          padding: 5% 6% 30px;
          transition: all .4s ease;
          background-color: $color-blue-darker;
          color: $color-white;
          z-index: 3;
          @media (min-width: 990px) {
            height: 174px;
          }
          @media (max-width: 989px) {
            height: 160px;
          }

          h3 {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            text-transform: uppercase;
            color: #ffffff;
          }

          .info {
            font-size: 1.4rem;
          }

          ul {
            li {
              display: block;
              width: 100%;
              margin-bottom: 2px;
              font-size: 1.4rem;

              strong {
                display: inline-block;
                font-weight: 600;
              }
            }
          }

          .buttons {
            position: absolute;
            bottom: 6%;
            left: 6%;
            width: 88%;

            .company {
              display: inline-block;
              padding-top: .5rem;
              color: $color-blue-light;
              font-size: 1.4rem;
              font-weight: 600;
              vertical-align: middle;
            }

            .btn {
              height: auto;
              padding: 4px 12px;
              float: right;
              border-width: 2px;
              border-radius: .6rem;
              color: $color-white;
              font-size: 1.3rem;
              font-weight: 600;
              line-height: 1.5rem;
            }
          }
        }

        &:hover {
          .overlay {
            top: 1.5%;
          }

          .wrap {
            ~ .overlay {
              top: 0;
              width: 100%;
              @media (min-width: 990px) {
                height: 177px;
              }
              @media (max-width: 989px) {
                height: 162px;
              }
            }
          }
        }
      }
      .games-not-found {
        font-size: 1.8rem;
        font-weight: 300;
      }
    }
  }
  @media only screen and (max-width: 989px) {
    .sidebar {
      order: 1;
    }
  }
}

.partner {
  h1 {
    width: 100%;
  }

  &-top {
    &-left {
      width: 68.4%;
      overflow: hidden;
      margin-bottom: 25px;
      float: left;
      font-weight: 300;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      p {
        font-size: 1.6rem;
        text-align: justify;
        width: 65%;
        @media only screen and (max-width: 1179px) {
          width: 60%;
        }
        @media only screen and (max-width: 1010px) {
          width: 55%;
        }
        @media only screen and (max-width: 980px) {
          width: 60%;
        }
        @media only screen and (max-width: 767px) {
          width: 100%;
        }
      }

      @media only screen and (max-width: 767px) {
        justify-content: center;
      }
    }

    &-right {
      width: 25.6%;
      margin-bottom: 50px;
      margin-left: auto;
      padding: 30px 30px 40px;
      float: right;
      border: 1px solid $color-black-12;
      text-align: center;

      h4 {
        margin-bottom: 27px;
      }

      .btn {
        width: 100%;
      }
    }
  }

  .clearfix:after {
    content: "";
    display: table;
    clear: both;
  }

  &-content {
    width: 68.4%;
    float: left;
  }

  &-logo {
    width: 260px;
    float: left;

    img {
      max-width: 80%;
/*      max-height: 90%;*/
      margin: 0 auto;
    }
  }

  @media only screen and (max-width: 989px) {
    .container {
      display: flex;
      flex-direction: column;
    }

    &-top {
      &-left {
        width: 100%;
        float: none;
      }
      &-right {
        width: 100%;
        float: none;
        order: 1;
        margin-top: 60px;
        margin-bottom: 0;
      }
    }

    .form {
      justify-content: space-between;

      .input {
        width: 48.46%;
      }

      .btn {
        width: 30.77%;
        min-width: 200px;
        margin: 0 auto;
      }
    }

    &-content {
      width: 100%;
      float: none;
    }
  }

  @media only screen and (max-width: 750px) {
    .container {
      h1 {
        margin-bottom: 10px;
      }
    }

    &-logo {
      float: none;
      margin: 0 auto 20px auto;
    }

    .form {
      .input,
      .btn {
        width: 100%;
      }
    }
  }
}

// Partners inner categories pages
.partners-category {
  &-text {
    text-align: center;
  }
}

.partners-category-grid {
  margin: 100px -7px 42px;

  .grid-item {
    width: 360px;
    margin: 0 7px 50px;
    padding: 50px 40px;
    border: solid 1px $color-grey3;
    border-radius: 5px;
    box-shadow: 0 2px 5px 0 transparentize($color-black, .82);

    img {
      margin: auto;
    }

    p {
      margin: 38px 0 30px;
      text-align: justify;
    }

    &-ref {
      text-align: right;
      margin-top: auto;

      a {
        font-weight: 400;
      }
    }

    .media-partner-ref {
      margin-top: 40px;
      padding-top: 35px;
      border-top: 1px solid $color-black-12;
      text-align: center;

      a {
        transition: color .3s;
        color: $color-black;
        font-size: 16px;
        font-weight: 400;

        &:hover {
          color: $color-academy-gradient2;
        }
      }
    }
  }

  &.game-dev {
    .grid-item {
      min-height: 351px;
    }
  }

  &.media-partners {
    .grid-item {
      min-height: 252px;
    }
  }

  @include breakpoint(1179px) {
    justify-content: space-around;

    .grid-item {
      width: 344px;
    }
  }

  @include breakpoint(699px) {
    .grid-item {
      margin-bottom: 40px;
      padding: 50px 25px;

      &-ref {
        display: block;
      }
    }
  }
}

.partners-form {
  h2 {
    margin-bottom: 13px;
  }

  p {
    margin-bottom: 39px;
    text-align: center;
  }
  p.error-message {
    margin-bottom: 0;
  }
}

.page-media-partners {
  .media-partners {
    .media-partners-grid {
      .grid-item {
        padding: 15px 40px 20px;
        img {
          width: 100%;
          height: 120px;
          object-fit: contain;
        }
        .media-partner-ref {
          padding-top: 30px;
          padding-bottom: 15px;
          margin-top: 30px;
        }
      }
    }
  }
}


// Products main page
.products {
  &-grid {
    justify-content: flex-start;
    margin: 0 -37px -99px;

    &-item {
      margin: 0 37px 99px;
    }
  }

  &-img {
    max-width: 240px;
    margin: 0 auto 10px;

    img {
      max-height: 200px;
    }
  }


  @include breakpoint(1179px) {
    &-grid {
      max-width: 700px;
      margin: 0 auto;

      &-item {
        margin: 0 0 99px;

        &:nth-child(4n+1) {
          margin-right: 5%;
        }
      }
    }
  }

  @include breakpoint(767px) {
    &-grid {
      &-item {
        margin: 0 auto 99px;

        &:nth-child(odd) {
          margin-right: auto;
        }
      }
    }
  }
}

// Page 404
.page-404 {
  p {
    max-width: 600px;
    margin: 0 auto 75px;
    font-size: 16px;
    text-align: center;

    a {
      color: $color-academy-gradient2;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .img-wrap {
    img {
      margin: 0 auto 77px;
    }
  }

  .nav {
    display: flex;
    justify-content: center;
    margin: 0 auto -25px;
    font-size: 14px;
    font-weight: 300;
    text-transform: uppercase;

    span {
      min-width: 100px;
      margin-right: 10px;
      text-align: right;
    }

    ul {
      display: flex;
      flex-wrap: wrap;

      li {
        margin: 0 10px 25px;

        a {
          color: $color-academy-gradient2;
          font-weight: 300;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }

  @include breakpoint(659px) {
    section {
      padding: 100px 0;
    }
  }

  @include breakpoint(619px) {
    .nav {
      flex-direction: column;
      margin-bottom: -17px;

      span {
        margin-right: 0;
        margin-bottom: 17px;
        text-align: center;
      }

      ul {
        justify-content: center;

        li {
          margin-bottom: 17px;
        }
      }
    }
  }
}

// Demo page
.demo {
  h1 {
    +p {
      max-width: 750px;
      margin: 0 auto 68px;
      color: $color-grey2;
      text-align: center;
    }
  }

  .form-light {
    justify-content: space-between;
    max-width: 670px;

    .input {
      width: 320px;

      &-checkbox {
        width: 33.333%;
        margin-bottom: 2px;
      }
    }

    .accordion {
      width: 100%;

      .toggle {
        border: 1px solid $color-grey;
        border-radius: .5rem;
        background-color: transparent;
      }
    }

    .checkboxes-wrap {
      display: flex;
      flex-wrap: wrap;
      margin-top: -3px;
      padding-bottom: 30px;
    }

    .checkboxes-wrap::-webkit-scrollbar {
      width: 5px;
    }
    .checkboxes-wrap::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 4px rgba(0,0,0,0.3);
      -webkit-border-radius: 4px;
      border-radius: 4px;
    }

    .checkboxes-wrap::-webkit-scrollbar-thumb {
      -webkit-border-radius: 5px;
      border-radius: 5px;
      background: rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
    }



    .buttons {
      width: auto;
      min-width: 200px;
      margin: 10px auto 0;
      text-align: center;

      [class*='-recaptcha'] {
        display: block;
        margin: 0 auto;
      }

      .btn {
        width: 100%;
        margin-top: 30px;
      }
    }
  }
  @media screen and (max-width: 770px) {
    .form-light {
      .buttons {
        .btn {
          margin-top: 1rem;
        }
      }
      .demo-buttons {
        width: 300px;
        .input-recaptcha {
          width: 100%;
          >div {
            max-width: 200px;
            margin: 0 auto;
          }
          p.error-message {
            text-align: center;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 767px) {
    padding: 90px 0 100px;

    .form-light {
      flex-direction: column;

      .input {
        width: 100%;
        max-width: 320px;
        margin-right: auto;
        margin-left: auto;

        &-checkbox {
          width: 100%;
        }
      }

      .accordion {
        max-width: 320px;
      }

      .checkboxes-wrap {
        max-height: 300px;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }
  @media screen and (max-width: 325px) {
    .form-light {
      .demo-buttons {
        width: 100%;
      }
    }
  }
  @media screen  and (min-width: 770px) {
    .form-with-borders-vertical {
      .demo-buttons {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .btn-primary {
          width: 225px;
          margin-top: 0;
        }
        .field-form-recaptcha {
          margin-left: 0;
          @media only screen and (max-width:767px){
            
          }
        }
      }
    }
  }
}

// Search page
.search {
  &-query {
    font-weight: 500;
  }

  &-results {
    margin: 6.6rem 0 7rem;

    li {
      margin-bottom: 3.2rem;
    }

    &-caption {
      margin-bottom: 1.3rem;
      color: $color-red;
      font-size: 2.2rem;
      text-decoration: underline;
    }

    strong {
      color: $color-red;
      font-weight: 500;
    }
  }

  @include breakpoint(659px) {
    &-page {
      padding-bottom: 96px;
    }
  }
}

// Page about
.about {
  .container {
    >p {
      max-width: 760px;
      margin: 0 auto 20px;
      font-size: 1.6rem;
      text-align: center;
    }
  }

  .advantages-cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 70px -25px -55px;

    .col {
      // max-width: 355px;
      flex: 1 0 335px;
      max-width: 355px;
      margin: 0 25px 55px;
      text-align: center;

      &:nth-child(2) {
        // max-width: 290px;
        flex-basis: 290px;
      }
    }

    .img-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 120px;
      height: 120px;
      margin: 0 auto 35px;
      border: solid 1px $color-red;
      border-radius: 50%;

      img {
        max-width: 54px;
        max-height: 54px;
      }
    }

    p {
      margin-top: 25px;
      font-size: 1.6rem;
    }
  }

  @include breakpoint(767px) {
    .advantages-cols {
      margin-right: 0;
      margin-left: 0;

      .col {
        flex-basis: auto;
        max-width: 100%;
        margin-right: 0;
        margin-left: 0;
      }
    }
  }

  @include breakpoint(659px) {
    padding: 100px 0;
  }
}

// Reviews
.reviews {
  padding-top: 0;

  &-slider {
    margin-bottom: 104px;
  }

  &-slide {
    text-align: center;
  }

  .avatar {
    max-width: 180px;
    max-height: 90px;
    margin: 0 auto 30px;

    img {
      margin: auto;
    }
  }

  .reviewer-name {
    margin-bottom: 25px;
    font-size: 2.2rem;
  }

  .reviewer-quote {
    max-width: 800px;
    margin: auto;
    font-size: 1.6rem;

    &::after {
      display: block;
      width: 50px;
      margin: 22px auto 0;
      border-top: 1px solid $color-black-12;
      content: '';
    }
  }

  .slick-arrow {
    &.fa-angle-left {
      left: 0;
    }

    &.fa-angle-right {
      right: 0;
    }
  }

  .slick-dots {
    @include horizontal-align;
    display: flex;
    position: absolute;
    top: calc(100% + 90px);

    li {
      margin: 0 4px;
    }

    button {
      width: 13px;
      height: 13px;
      border: 1px solid $color-grey;
      border-radius: 50%;
      background: none;
      text-indent: -50000px;

      &:hover {
        transform: scale(1.1);
      }
    }

    .slick-active {
      button {
        border-color: transparent;
        background-color: $color-red;
      }
    }
  }

  .read-all {
    position: relative;
    top: -67px;
    text-align: center;
  }

  @include breakpoint(989px) {
    .reviewer-quote {
      max-width: 550px;
    }
  }

  @include breakpoint(659px) {
    margin-top: -20px;
    padding-bottom: 100px;

    .reviewer-name {
      max-width: 50%;
      margin-right: auto;
      margin-left: auto;
      font-size: 2rem;
    }

    .reviewer-quote {
      max-width: 72%;
    }
  }
}

// page-payment page
.page-payment {
  .header-secondary {
    .img {
      &::before {
        position: absolute;
        top: -12vw;
        left: -15%;
        width: 110%;
        height: 600px;
        background: url('../images/unified-protocol/bg-header.png') no-repeat center top;
        background-size: 100% auto;
        content: '';
        z-index: -1;
      }
      &::after {
        background: radial-gradient(ellipse at center,#126557 0%,transparent 50%,transparent 99%);
        position: absolute;
        top: -140%;
        left: -150%;
        width: 400%;
        height: 380%;
        content: '';
        z-index: -1;
      }
    }
  }
}

.colored-effect .colored-shadow {
  transform: scale(0.99);
  top: 12px;
  filter: blur(12px);
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  z-index: 0;
  transition: opacity .45s;
  opacity: 0;
}

.colored-effect .illustration {
  overflow: inherit;
}

.colored-effect .illustration {
  overflow: inherit;
}

.colored-effect .illustration img{
  position: relative;
  z-index: 1;
  border-radius: 6px
}

.colored-effect .colored-shadow {
  opacity: 1;
}

.is-bold a {
	font-weight: bold;
}

.full-access-message {
  color: #fe0000;
}

strong {
  font-weight: bold!important;
}
em > strong {
  font-weight: bold!important;
}

strong > em {
  font-weight: bold!important;
}

body.android .breadcrumbs ul li::after,
body.android .breadcrumbs ul li::before {
  margin-top: -3px;
}

//Moscow meet page

.moscowMain, .amsterdamMain, .sigmaMain {
  min-width: 320px;
  font-weight: 300;
  position: relative;
  h2 {
    text-transform: none;
    font-weight: 300;
  }

  button {
    height: 60px;
    width: 338px;
    font-size: 24px;
    font-weight: 300;
    @media only screen and (max-width: 800px) {
      display: block;
      margin: 0 auto;
    }

    @media only screen and (max-width: 600px) {
      width: 85%;
      max-width: 302px;
      height: auto;
      font-size: 18px;
    }
    @media only screen and (max-width: 450px) {
      font-size: 16px;
    }
    @media only screen and (max-width: 350px) {
      font-size: 14px;
    }

  }
  .topBlock {
    position: relative;
    color: #ffffff;
    .topInfo {
      padding-top: 260px;
      padding-bottom: 300px;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      @media only screen and (max-width: 1300px) {
        padding-top: 160px;
        padding-bottom: 200px;
      }

      @media only screen and (max-width: 600px) {
        padding-top: 50px;
        padding-bottom: 120px;
      }

      .infoLeft {
        -webkit-perspective: 1200px;
        perspective: 1200px;
        -moz-transform: perspective(1200px);
        -webkit-transform-style: preserve-3d;
        -moz-transform-style: preserve-3d;
        transform-style: preserve-3d;
        @media only screen and (max-width: 800px) {
          padding-right: 0;
          width: 100%;
        }
        .animationWrapper {
          width: 300px;
          height: 300px;
          -webkit-transform-style: preserve-3d;
          -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
          position: relative;
          top: -42px;
          @media only screen and (max-width: 800px) {
            width: 250px;
            height: 250px;
            margin: 0 auto;
            top: 25px;
          }
        }
        .imgWrapper {
          width: 55px;
          position: absolute;
          display: block;
          top: 50%;
          left: 50%;
          margin-left: -27.5px;
          margin-top: -51.5px;
          @media only screen and (max-width: 800px) {
            width: 42px;
            margin-left: -21.5px;
            margin-top: -39.5px;
          }
          img {
            width: 100%;
            height: 100%;
          }
        }

        .front {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          z-index: 2;
          -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
          position: absolute;
          -webkit-animation: turn 3s infinite;
          animation: turn 3s infinite;
          transform-style: preserve-3d;
          img {
            width: 100%;
            height: 100%;
          }
        }
        .back {
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          position: absolute;
          -webkit-animation: turn 3s infinite;
          animation: turn 3s infinite;
          transform-style: preserve-3d;
          img {
            width: 100%;
            height: 100%;
          }
        }

        @-webkit-keyframes turn {
          to {
            -webkit-transform: rotateY(360deg);
            transform-style: preserve-3d;
          }
        }
        @keyframes turn {
          to {
            transform: rotateY(360deg);
            transform-style: preserve-3d;
          }
        }


      }

      .infoRight {
        width: 520px;
        text-align: left;
        @media only screen and (max-width: 600px) {
          width: calc(100% - 60px);
          margin: 0 auto;
        }
        a.mainLogo {
          position: relative;
          left: -10px;
          @media only screen and (max-width: 800px) {
            img {
              margin: 0 auto;
              left: 0;
            }
          }
        }
        h2 {
          margin-bottom: 20px;
          line-height: 1.5;
          text-align: left;
          color: #ffffff;
          @media only screen and (max-width: 800px) {
            text-align: center;
          }
        }
        .dateAndLocation, .date-AndLocation {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          align-items: center;
          padding:15px 0;
          border-top: 1px solid #FFFFFF;
          border-bottom: 1px solid #FFFFFF;
          @media only screen and (max-width: 600px) {
            justify-content: flex-start;
          }
          span {
            display: flex;
            align-items: center;
            @media only screen and (max-width: 600px) {
              &:nth-child(1) {
                padding-bottom: 15px;
              }
              width: 100%;
              justify-content: flex-start;
            }
            img {
              height: 31px;
              margin-right: 7px;
              @media only screen and (max-width: 400px) {
                height: 20px;
                margin-right: 4px;
              }
            }
          }

        }
        button {
          margin-top: 35px;
          @media only screen and (max-width: 800px) {
            margin-top: 35px;
          }
        }
      }
    }
  }

  .exhibition {
    position: relative;
    background-color: #ffffff;
    text-align: left;
    .exhibitionContainer {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 50px;
      @media only screen and (max-width: 800px) {
        justify-content: center;
        padding: 0 25px;
      }
      h2 {
        width: 100%;
        max-width: 100%;
        text-align: left;
        @media only screen and (max-width: 800px) {
          text-align: center;
        }
      }
      p {
        width: 47%;
        @media only screen and (max-width: 800px) {
          width: 75%;
          margin-bottom: 5%;
        }
        @media only screen and (max-width: 600px) {
          width: 90%;
        }
        @media only screen and (max-width: 400px) {
          width: 95%;
        }
      }
    }

    .exhibitionPhotos {
      padding-top: 90px;
      padding-bottom: 50px;
      @media only screen and (max-width: 800px) {
        padding-top: 50px;
        padding-bottom: 0px;
      }
      .exhibitionPhoto {
        width: 31.5%;
        margin-bottom: 30px;
        img {
          width: 100%;
        }
        @media only screen and (max-width: 800px) {
          width: 75%;
          margin: 0 auto 10px auto;
        }
        @media only screen and (max-width: 650px) {
          width: 90%;
        }
      }
      .top {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        .exhibitionPhoto:nth-child(1) {
          width: 65.8%;
          @media only screen and (max-width: 800px) {
            width: 75%;
          }
          @media only screen and (max-width: 650px) {
            width: 90%;
          }

        }
        .exhibitionPhoto:nth-child(2) {

          @media only screen and (max-width: 800px) {
            width: 75%;
          }
          @media only screen and (max-width: 650px) {
            width: 90%;
          }
        }
      }
      .bottom {
        width: 70%;
        //display: flex;
        //justify-content: space-between;
        //flex-wrap: wrap;
        @media only screen and (max-width: 992px) {
          width: 100%;
        }

      }
    }


  }

  .ourProgram {
    position: relative;
    padding-top: 115px;
    padding-bottom: 170px;
    @media only screen and (max-width: 1300px) {
      padding: 115px 50px 170px 50px;
    }
    @media only screen and (max-width: 550px) {
      padding: 70px 25px 100px 25px;
    }

    h2 {
      width: 100%;
      max-width: 100%;
      padding-left: 50px;
      padding-bottom: 30px;
      text-align: left;
      color: #ffffff;
      @media only screen and (max-width: 1300px) {
        padding-left: 0;
      }
      @media only screen and (max-width: 800px) {
        text-align: center;
      }
    }
    .daysContent {
      //display: flex;
      //justify-content: space-between;
      //flex-wrap: wrap;
      .day, .sigmaDay{
        position: relative;
        width: 40%;
        color: #ffffff;
        @media only screen and (max-width: 1300px) {
          width: 45%;
        }
        @media only screen and (max-width: 950px) {
          width: 90%;
        }
        @media only screen and (max-width: 550px) {
          width: 100%;
        }
        span.bgNumber {
          display: block;
          position: absolute;
          top: 50%;
          margin-top: -225px;
          left: 50%;
          margin-left: -216px;
          opacity: 0.1;
          font-size: 400px;
          font-family: 'Roboto-BlackItalic';
          @media only screen and (max-width: 550px) {
            font-size: 350px;
            margin-left: -190px;
            margin-top: -218px;
          }
          @media only screen and (max-width: 400px) {
            font-size: 300px;
            margin-left: -165px;
            margin-top: -185px;
          }
          @media only screen and (max-width: 350px) {
            font-size: 280px;
            margin-left: -155px;
            margin-top: -175px;
          }

        }
        h3 {
          padding-left: calc(22% + 30px);
          font-weight: 100;
          font-size: 24px;
          margin-bottom: 40px;
          opacity: 0.7;
        }
        ul {
          position: relative;
          z-index: 2;
          @media only screen and (max-width: 550px) {
            width: 100%;
          }
          li {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-bottom: 20px;
            .time, .sigmaDay_time {
              width: 26%;
              text-align: right;
              font-size: 48px;
              font-family: 'Roboto-BlackItalic';
              color: #E41414;
              @media only screen and (max-width: 550px) {
                font-size: 30px;
              }
            &.sigmaDay_time{
              color: #ffffff;
              @media only screen and (max-width: 550px) {
                text-align: center;
              }
            }
            }
            .description, .sigmaDay_descr {
              display: inline-block;
              text-align: left;
              width: 70%;
              margin-left: 50px;
              opacity: 0.7;
              font-size: 16px;
              font-weight: 300;
              @media only screen and (max-width: 1300px) {
                margin-left: 20px;
              }
              @media only screen and (max-width: 550px) {
                margin-left: 10px;
              }
            }
            .sigmaDay_descr{
              @media only screen and (max-width: 550px) {
                margin-left: 20px;
              }
            }
          }
        }
      }
      .day:nth-child(1) {
        @media only screen and (max-width: 950px) {
          margin-bottom: 30px;
        }
        &:after {
          content: '';
          display: block;
          width: 1px;
          height: 75%;
          position: absolute;
          right: -25%;
          bottom: 28px;
          background-color: #ffffff;
          @media only screen and (max-width: 1300px) {
            right: -15%;
          }
          @media only screen and (max-width: 950px) {
            display: none;
          }
        }
      }
    }

  }

  .mapContent {
    position: relative;
    background-color: #ffffff;
    color: #4F4F4F;
    h2 {
      width: 100%;
      max-width: 100%;
      padding-left: 50px;
      text-align: left;
      color: #4F4F4F;
      @media only screen and (max-width: 1180px) {
        padding-left: 30px;
      }
      @media only screen and (max-width: 800px) {
        text-align: center;
        padding-left: 0;
      }
    }
    p {
      padding-left: 50px;
      margin-bottom: 120px;
      @media only screen and (max-width: 1180px) {
        padding-left: 30px;
      }
      @media only screen and (max-width: 800px) {
        margin-bottom: 50px;
        padding-left: 15px;
      }
    }
    #map {
      width: 100%;
      height: 650px;
      @media only screen and (max-width: 800px) {
        height: 450px
      }
      @media only screen and (max-width: 600px) {
        height: 350px
      }
    }
    #detailMap {
      position: absolute;
      width: 600px;
      height: 500px;
      bottom: 175px;
      right: 7.5%;
      border-radius: 50px;
      overflow: hidden;
      -webkit-box-shadow: -2px 2px 0px 2px rgba(221,7,7,1);
      -moz-box-shadow: -2px 2px 0px 2px rgba(221,7,7,1);
      box-shadow: -2px 2px 0px 2px rgba(221,7,7,1);
      display: none;
      @media only screen and (max-width: 1300px) {
        width: 400px;
        height: 350px;
        bottom: 250px;
        border-radius: 35px;
      }
      @media only screen and (max-width: 800px) {
        width: 220px;
        height: 200px;
        bottom: 220px;
        right: 45px;
        border-radius: 25px;
      }
      @media only screen and (max-width: 600px) {
        bottom: 125px;
      }
      @media only screen and (max-width: 400px) {
        width: 175px;
        height: 160px;
        right: 30px;
      }
      img {
        width: 100%;
        height: 100%;
        border-radius: 15px;
      }
    }
  }

  .meetWithUs {
    position: relative;
    background-color: #ffffff;
    padding-top: 10px;
    padding-bottom: 125px;
    @media only screen and (max-width: 1024px) {
      padding-bottom: 150px;
    }
    .container {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      @media only screen and (max-width: 1024px) {
        justify-content: center;
      }
      .formBlock {
        width: 45%;
        height: 650px;
        text-align: center;
        @media only screen and (max-width: 1024px) {
          width: calc(100% - 60px);
          max-width: 550px;
          height: auto;
        }
        @media only screen and (max-width: 400px) {
          width: calc(100% - 20px);
        }
        h2 {
          margin-bottom: 20px;
        }

        form {
          .input {
            input {
              border: 1px solid #E0E0E0;
              margin-top: 25px;
            }
            textarea {
              border: 1px solid #E0E0E0;
              height: 200px;
              margin-top: 30px;
              margin-bottom: 10px;
            }
          }
          button {
            margin-top: 30px;
            @media only screen and (max-width: 1024px) {
              display: block;
              margin: 30px auto 0 auto;
            }
          }
          [class*=-recaptcha] {
            display: none!important;
          }
        }
        form.focused {
          [class*=-recaptcha] {
            display: inline-block!important;
          }
          textarea[class*=-recaptcha] {
            display: none!important;
          }
        }
      }
      .imageBlock {
        width: 900px;
        position: absolute;
        left: calc(45% + 44px);
        bottom: 80px;
        @media only screen and (max-width: 1024px) {
          display: none;
        }
        img {
          width: 100%;
        }
      }
    }

  }

  #rightNavigation {
    position: fixed;
    right: 70px;
    top: 50%;
    margin-top: -120px;
    z-index: 3;
    @media only screen and (max-width: 1300px) {
      right: 30px;
    }
    @media only screen and (max-width: 700px) {
      right: 20px;
      display: none;
    }
    @media only screen and (max-width: 500px) {
      right: 10px;
    }
    li {
      margin-bottom: 50px;
      a {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        span.navItem {
          display: inline-block;
          position: relative;
          right: 60px;
          top: 30px;
          opacity: 0;
          transition: 0.3s;
          color: #FD0B01;
          font-weight: 500;
          @media only screen and (max-width: 1300px) {
            top: 10px;
            right: 20px;
          }
          @media only screen and (max-width: 700px) {
            display: none;
          }
          &:after {
            content: '';
            display: block;
            position: absolute;
            width: 60%;
            height: 1px;
            bottom: -5.5px;
            right: -10.5px;
            background-color: #FD0B01;
          }
          &:before {
            content: '';
            display: block;
            position: absolute;
            width: 65px;
            height: 1px;
            bottom: 15px;
            right: -68px;
            transform: rotate(-40deg);
            background-color: #FD0B01;
            @media only screen and (max-width: 1300px) {
              width: 25px;
              right: -30px;
              bottom: 4px;
              transform: rotate(-60deg);
            }
          }
        }

        .blackText {
          color: #000000!important;
          &:before {
            background-color: #000000!important;
          }
          &:after {
            background-color: #000000!important;
          }

        }


        span.round {
          width: 10px;
          height: 10px;
          display: inline-block;
          border: 2px solid #FD0B01;
          border-radius: 50%;
        }
      }
    }
  }


  #videoBg {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
    z-index: 0;
  }
}


.showPageName {
  opacity: 1!important;
}

//slide shadow and naming

.page-main {
  .news-list {
    .slick-slide {
      position: relative;
      &:after {
        content: '';
        position: absolute;
        display: block;
        height: 100%;
        width: 100%;
        bottom: 0px;
        left: 0;
        z-index: 0;
        -webkit-box-shadow: inset 0px -200px 214px -24px rgba(0,0,0,0.49);
        -moz-box-shadow: inset 0px -200px 214px -24px rgba(0,0,0,0.49);
        box-shadow: inset 0px -200px 214px -24px rgba(0,0,0,0.49);
        @media only screen and (max-width: 1132px) {
          bottom: 0px;
        }

        @media only screen and (max-width: 750px) {
          display: none;
        }

        @media only screen and (max-width: 650px) {
          -webkit-box-shadow: inset 0px -200px 300px -24px rgba(0,0,0,0.9);
          -moz-box-shadow: inset 0px -200px 300px -24px rgba(0,0,0,0.9);
          box-shadow: inset 0px -200px 300px -24px rgba(0,0,0,0.9);
        }

      }
      img {
        transition: 0.4s;
      }
      li {
        overflow: hidden;
      }
      .article-name {
        display: inline-block;
        margin: 0 auto;
        position: absolute;
        width: 100%;
        z-index: 2;
        bottom: 85px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        text-transform: none;
        font-weight: 300;
        text-align: center;
        color: #ffffff;
        span {
          display: inline;
          vertical-align: bottom;
          margin: 0 15px;
        }

        @media only screen and (max-width: 1132px) {
          bottom: 35px;
          height: 55px;
          display: table-cell;
          width: 100%;
          vertical-align: bottom;
          align-items: flex-end;
          justify-content: center;
          border: 0;
        }
        @media only screen and (max-width: 750px) {
          display: block;
          position: static;
          padding-top: 10px;
          transform: translateX(0%);
          color: #000000;
          text-align: center;
        }
        @media only screen and (max-width: 320px) {
          height: 60px;
        }
      }

      }

    }
}

._2p3a {
  width: 100%!important;
}

#RGWMeetingButton {
  position: fixed;
  height: 109px;
  z-index: 4;
  left: 0;
  bottom: 250px;
  border-top-right-radius: 52px;
  border-bottom-right-radius: 54px;
  color: #ffffff;
  background: rgb(194,15,35);
  background: linear-gradient(270deg, rgba(194,15,35,1) 21%, rgba(100,42,102,1) 73%, rgba(34,61,149,1) 100%);
  transition: 0.4s;
  @media only screen and (max-width: 1600px) {
    height: 100px;
  }

  @media only screen and (max-width: 1000px) {
    bottom: 50px;
    height: 85px;
  }

  a {
    display: block;
    padding: 15px 100px 15px 15px;
    @media only screen and (max-width: 1600px) {
      padding-right: 90px;
    }
    @media only screen and (max-width: 1000px) {
      padding-left: 10px;
      padding-right: 70px;
    }
  }

  .displayedContent {
    transition: 0.3s;
    span:nth-child(1) {
      font-size: 24px;
    }
    span:nth-child(3) {
      font-size: 15.5px;
    }

    span:nth-child(5) {
      font-size: 23px;
      span {
        margin: 0 11px;
      }
    }
    @media only screen and (max-width: 1600px) {
      span:nth-child(1) {
        font-size: 20px;
      }
      span:nth-child(3) {
        font-size: 12.7px;
      }

      span:nth-child(5) {
        font-size: 19px;
        span {
          margin: 0 11px;
        }
      }
    }
    @media only screen and (max-width: 1000px) {
      span {
        font-size: 12px;
     }
      span:nth-child(1) {
        font-size: 12px;
      }
      span:nth-child(3) {
        font-size: 11px;
      }

      span:nth-child(5) {
        font-size: 12px;
        span {
          margin: 0 5px;
        }
      }
    }
  }

  .hiddenContent {
    width: 270px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 20px;
    opacity: 0;
    transition: 0.3s;
    @media only screen and (max-width: 1600px) {
      width: 240px;
      font-size: 21px;
      left: 15px;
    }
    @media only screen and (max-width: 1000px) {
      width: 162px;
      font-size: 14px;
      left: 2.5px;
    }
  }

  .glassWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    bottom: 0;
    overflow: hidden;
    border-bottom-right-radius: 52px;
    @media only screen and (max-width: 1600px) {
      height: 100%;
      border-bottom-right-radius: 50px;
    }
    @media only screen and (max-width: 1000px) {
      border-bottom-right-radius: 43px;
    }
    img {
      position: absolute;
      width: 65px;
      right: 25px;
      top: 50%;
      transform: translateY(-50%);
      @media only screen and (max-width: 1600px) {
        width: 55px;
        right: 20px;
      }
      @media only screen and (max-width: 1000px) {
        width: 40px;
        right: 17.5px;
      }
    }
  }
  /*

  img {
    position: absolute;
    width: 300px;
    right: -90px;
    top: -145px;
    margin-top: 0px;
    @media only screen and (max-width: 1600px) {
      width: 220px;
      top: -90px;
      right: -60px;
    }
    @media only screen and (max-width: 1000px) {
      width: 110px;
      margin-top: -23.5px;
      top: 0px;
      right: -15px;
    }
  }
  */

  span#close {
    display: none;
    width: 20px;
    height: 20px;
    position: absolute;
    right: -10px;
    top: -10px;
    border: 1px solid #c20f23;
    border-radius: 50%;
    text-align: center;
    color: #ffffff;
    background-color: #c20f23;
    cursor: pointer;
    @media only screen and (max-width: 1000px) {
      display: block;
    }
  }
}

.tableWrapper {
  overflow-y: scroll;
}

body[data-page-id="4020"] {
  p {
    img {
      width: 30%;
      float: left;
      margin-right: 40px;
      margin-top: 20px;
    }
  }
}

.page-unified-protocol {
  .product-info {
   background-color: #ffffff;
    padding-bottom: 0px;
   .details {
     p {
       a {
         text-decoration: underline;
       }
     }
     img {
       width: 125%;
       max-width: 125%;
       position: relative;
       left: 25%;
       @media only screen and (max-width: 1500px) {
         width: 100%;
         max-width: 100%;
         position: static;
       }
     }

    .integrationProcess {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: flex-start;
      padding-top: 90px;
      .integrationProcess__item {
        width: 20%;
        @media only screen and (max-width: 992px) {
          width: 40%;
        }
        @media only screen and (max-width: 600px) {
          width: 100%;
        }
      }
    }
   }
 }
}

.page-payment {
  .product-info {
    padding-bottom: 0;
    background-color: #ffffff;
    .details {
      img {
        width: 125%;
        max-width: 125%;
        @media only screen and (max-width: 1500px) {
          width: 100%;
          max-width: 100%;
        }
      }

      .paymentSystem {
        padding-top: 25px;
        ul {
          padding: 0;
          margin: 0;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          li {
            width: calc(18% - 25px);
            display: flex;
            justify-content: center;
            align-items: center;
            margin-bottom: 30px;
            @media only screen and (max-width: 992px) {
              width: 25%;
            }
            @media only screen and (max-width: 650px) {
              width: 50%;
            }
            img {
              width: 100%;
              max-width: 140px;
              @media only screen and (max-width: 400px) {
                max-width: 100px;
              }
            }
          }
        }
      }
    }
  }
}

// styles fr amsterdam igb lending
.monegrator_link a {
  color: #fff;
  text-shadow: 0 0 6px #12b59c, 0 0 12px #12b59c, 0 0 24px;
  transition: text-shadow .5s;
}
.monegrator_link:hover a {
  color: #fff;
  text-shadow: 0 0 3px #15caae, 0 0 6px #15caae, 0 0 12px #15caae, 0 0 24px #15caae, 0 0 48px #15caae;
}






.amsterdamMain, .sigmaMain{
  background-image: url(../images/landing/igb-2018/bg_africa2.jpg);
  background-attachment: fixed;
  background-size: cover;
  .topBlock .topInfo .infoRight {
    width: 90%;
    text-align: center;
    img {
      display: inline-block;
    }
    h2 {
      display: inline-block;
      margin-bottom: 6px;
      line-height: 1.5;
      font-size: 4.5rem;
      font-weight: 400;
      vertical-align: bottom;
    }
    h2.mainLogo__title{
      font-weight: 300;
      text-align: center;
      @media only screen and (max-width: 479px){
        font-size: 3.5rem;
      }
    }
    button {
      border-radius: 30px;
    }
    .dateAndLocation, .date-AndLocation {
      border-bottom: none;
      justify-content: space-around;
      span {
        font-size: 1.7rem;
        font-weight: 400;
        line-height: 2.5;
      }
    }
    .date-AndLocation span{
      font-weight: 300;
    }
  }
  .exhibition {
    .exhibitionContent {
      width: 100%;
      padding-right: 5%;
      @media only screen and (max-width: 992px) {
        padding-right: 0;
      }
      p {
        width: 100%;
        margin-bottom: 5%;
        padding-left: 10%;
        @media only screen and (max-width: 992px) {
          padding-left: 0;
        }
      }
    }
    &Text{
      width: 100% !important;
      margin-bottom: 5%;
      padding-right: 10%;
      @media only screen and (max-width: 992px) {
        padding-right: 0;
      }
    }
    .exhibitionContainer {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
        .exhibitionPhotos {
          width: 100%;
        }
        .exhibitionContent {
          width: 100%;
        }
      }
        h2 {
          text-align: center;
          font-weight: 400;
          width: 100%;
        }
    }
    .exhibitionPhotos {
      //width: 60%;
      padding-top: 0;
      display: flex;
      @media only screen and (max-width: 992px) {
        flex-direction: column;
      }
      .top .exhibitionPhoto:nth-child(1) {
        width: 100%;
      }
      .bottom .exhibitionPhoto {
        width: 100%;
      }
    }
  }
  .ourProgram h2 {
    padding-bottom: 65px;
  }
  .ourProgram .daysContent .day:nth-child(1):after {
    display: none;
  }
  .mapContent{
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 100px;
    #map {
      width: 50%;
      #bodyContent {
        padding: 5px 0 5px 10px;
        p {
          margin: 0;
          padding: 0;
        }
      }
    }
    .formBlock {
      padding-left: 5%;
      @media only screen and (max-width: 1345px) {
        padding-right:5%;
      }
      width: 30%;
      @media only screen and (max-width: 992px) {
       padding-right:5%;
      }
      h2 {
        padding-left: 0;
        font-weight: 400;
        color: #000000;
        margin-bottom: 30px;
      }
      .input {
        margin-bottom: 2rem;
        input, textarea {
          border: 1px solid rgba(0,0,0,0.3);
          border-radius: 5px;
        }
      }
      p.error-message {
        margin: 0;
      }
    }
    @media only screen and (max-width: 1345px) {
      flex-direction: column;
      padding-bottom: 50px;
      #map {
        width: 100%;
        margin-bottom: 30px;
      }
      .formBlock {
        margin: 0 auto;
        width: auto;
      }
    }
    .container {
    @media only screen and (min-width: 2278px) {
      width: 100%;
    }
    }
  }
}

.sigmaMain{
  background-image: url(../images/landing/sigma_bg.png);
}

////////////////////////////////////
//=====Page calendar_of_events====//
////////////////////////////////////

.calendar__block__months{
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  font-family: Roboto, sans-serif;
  color: #5e5b62;
  .calendar__block__item a:first-child,
  .calendar__block__item a:last-child {
    font-weight: 500;
    font-size: 150%;
  }
}
.calendar__block__slider{
  margin-bottom: 40px;
}
.slider__winner__text p{
  font-size: 14px;
  transform: scale(1);
  transition: all .15s ease-in-out;
  &:hover{
    color: #ff0000;
    transform: scale(1.05);
    
  }
  @media only screen and (max-width: 1279px){
    font-size: 12px;
  }
  @media only screen and (max-width: 640px){
    font-size: 18px;
  }
}

.slider__winner{
//   &.active::after{
//     background-color: #ccc;
//     border-radius: 50%;
//     margin: auto;
//     margin-top: 10px;
//     content: '';
//     display: block;
//     width: 16px;
//     height: 16px;
//     box-shadow: inset 0 0 0 5px #f00;
//     @media only screen and (max-width:767px){
//      display: none;
//     }
//   }
  &.active p{
    color: #ff0000;
    font-size: 23px;
    font-weight: 300;
    line-height: 0.8;
    @media only screen and (max-width:1279px){
      font-size: 20px;
    }
    @media only screen and (max-width:767px){
      font-size: 25px;
      height: 27px;
    }
  }
  &.prew_active p{
    font-size: 18px;
    line-height: 1.2;
    @media only screen and (max-width:1279px){
      font-size: 16px;
    }
    @media only screen and (max-width:639px){
      font-size: 18px;
    }
  }
  &.after_active p{
    font-size: 16px;
    line-height: 1.2;
    @media only screen and (max-width:1279px){
      font-size: 15px;
    }
    @media only screen and (max-width:639px){
      font-size: 18px;
    }
  }
}
.accordion-recapch.accordion-recapch.accordion-recapch{
  @media only screen and (max-width:767px){
    position: absolute;
    top: 110px;
    left: 50%;
    margin-left: -102px;
  }
  @media only screen and (max-width:640px){
    top: 235px;
  }
}
.accordion__button6-open.accordion__button6-open.accordion__button6-open.accordion__button6-open{
  @media only screen and (max-width:767px){
   padding-top: 60px;
  }
}

.swiper-button-next, .swiper-container-rtl .swiper-button-prev{
  top:10px;
  }
.swiper-button-prev, .swiper-container-rtl .swiper-button-next{
  top:10px;
}
.swiper-container{
  margin-bottom: 20px;
}
.calendar__block__slider{
  @media only screen and (max-width: 767px){
      display: none;
    }
  }
  .swiper-button-prev,
  .swiper-button-next{
  @media only screen and (max-width: 767px) {
    background-size: 11px 22px;
    }
  }
  .swiper-button-prev,
  .swiper-container-rtl .swiper-button-next {
  @media only screen and (max-width: 640px) {
    left: 15px;
    }
  }
  .swiper-button-next,
  .swiper-container-rtl .swiper-button-next {
    @media only screen and (max-width: 640px) {
      right: 15px;
    }
  }
.container-accordion{
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
  @media only screen and (max-width: 1024px){
    font-size: 13px;
  }

.accordion {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 0;
  &__inform{
    border: 2px solid #d9d9d9;
    margin-top: 20px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
    display: flex;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    border-radius: 10px;
    @media only screen and (max-width: 767px){
      flex-wrap: wrap;
      padding: 15px 0 0;
    }
    @media only screen and (max-width: 640px){
      flex-direction: column;
      align-items: center;
    }
    .accordion__data{
      color: red;
      padding-right: 20px;
      font-family: Roboto, sans-serif;
      font-size: 17px;
      font-weight: 300;
      @media only screen and (max-width:767px){
        font-size: 14px;
      }
      @media only screen and (max-width:640px){
        padding-right: 0;
        padding-bottom: 15px;
      }
    }
    .accordion__title{
      width: 650px;
      max-width: 100%;
      font-family: Roboto, sans-serif;
      font-size: 17px;
      font-weight: 300;
      padding-right: 20px;
      @media only screen and (max-width: 1139px){
        font-size: 14px;
      }
      @media only screen and (max-width: 767px){
        width: 300px;
        padding-right: 0;
      }
      @media only screen and (max-width: 640px){
        text-align: center;
        padding-bottom: 15px;
      }
    }
    .accordion__country{
      color: #283a7c;
      font-family: Roboto, sans-serif;
      font-size: 17px;
      font-weight: 300;
      width:167px;
      @media only screen and (max-width:1139px){
        font-size: 14px;
      }
      @media only screen and (max-width:767px){
        text-align: center;
      }
      // @media only screen and (max-width:640px){
      //   display: none;
      // }
    }
    .accordion__country1{
      width:165px;
      text-align: left;
      @media only screen and (max-width:767px){
        font-size: 14px;
        text-align: center;
      }
    }
    .accordion__pointer {
      background: url("../images/icons/pointer_accordion.png") no-repeat center / cover;
      width: 25px;
      height: 25px;
      padding: 0 10px;
      transform: rotate(180deg);
      cursor: pointer;
      @media only screen and (max-width: 767px){
        display: none;
      }
    }
    .accordion__button{
      margin-right: 20px;
      width: 97px;
      @media only screen and (max-width: 767px){
        font-size: 14px;
        width:100%;
        text-align: center;
        order:1;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-right: 0;
      }
      &:last-child{
        margin-right: 0;
      }
      button{
        color: #ffffff;
        font-family: Roboto, sans-serif;
        font-size: 17px;
        font-weight: 300;
        width: 97px;
        height: 31px;
        border: none;
        background-color: #ff0000;
        border-radius: 3px;
        @media only screen and (max-width: 767px){
          font-size: 14px;
          height: 39px;
        }
      }
    }
    .accordion__arrow-box{
      width: 100%;
      display: none;
      @media only screen and (max-width: 767px){
        display: flex;
        order: 1;
      }
    }
    .accordion__arrow{
      padding: 12px 0;
      background-color: #e8e8e8;
      display: none;
      width: 100%;
      justify-content: center;
      @media only screen and (max-width: 767px){
        display: flex;
        
      }
    }
  }

  .accordion__arrow{
    display: none !important;
  }
  &__dropdown-text{
    font-family: Roboto;
    font-size: 17px;
    font-weight: 300;
    p{
      padding-bottom: 20px;
      text-align: justify;
      @media only screen and (max-width:767px){
        text-align: left;
      }
      @media only screen and (max-width:640px){
        text-align: center;
      }
    }
  }
} 
span.ui-icon{
    display: none;
    width: 0;
  }
.accordion__form{
  max-width: 770px;
  display: flex;
  margin-right: 20px;
  justify-content: space-between;
  @media only screen and (max-width: 767px){
    // max-width: 610px;
    width:100%;
    flex-wrap: wrap;
    margin-right: 0;
  }
  @media only screen and (max-width: 640px){
    flex-direction: column;
    align-items: center;
    margin-right: 0;

  }
  .input__name{
    margin-right: 10px;
    max-width: 270px;
    width: 100%;
    @media only screen and (max-width: 767px){
      margin-left: 20px;
      margin-right: 0;
    }
    @media only screen and (max-width: 640px){
      margin-bottom: 8px;
      margin-right: 0;
      margin-left: 0;
      width: 265px;
    }
    input{
      padding: 6px 10px;
      border: 2px solid #dcdcdc;
      border-radius: 3px;
      width:100%;
      @media only screen and (max-width: 767px){
        max-width: 265px;
      }
    }
    input::placeholder{
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 17px;
      @media only screen and (max-width: 767px){
        font-size: 14px;
      }
    }
  }
  .input__email{
    margin-right: 5px;
    max-width: 270px;
    width: 100%;
    position: relative;
    @media only screen and (max-width: 767px){
      margin-right: 15px;
    
    }
    @media only screen and (max-width: 640px){
      margin-bottom: 20px;
      margin-right: 0;
      width: 265px;
    }
    input{
      padding: 6px 10px;
      border: 2px solid #dcdcdc;
      border-radius: 3px;
      width:100%;
      @media only screen and (max-width: 767px){
        max-width: 265px;
      }
    }
    input::placeholder{
      color: #000000;
      font-family: 'Roboto', sans-serif;
      font-weight: 300;
      font-size: 17px;
      @media only screen and (max-width: 767px){
        font-size: 14px;
      }
    }
    // input:valid:not(:placeholder-shown) {
    //   border-color: green;
    // }

    input:invalid:not(:placeholder-shown) {
      border-color: red;
    }
    input:invalid:not(:placeholder-shown) + .form__error {
      display: block;
    }
    .form__error{
      position: absolute;
      top: 30px;
      width:100%;
      color: red;
      text-align: left;
      font-size: 12px;
      display: block;
      margin-top: 3px;
      display: none;
      @media only screen and (max-width: 767px){
        width: 148px;
        text-align: center;
        font-size: 11px;
        top:29px;
        left: -5px;
      }
    }
  }
}
.dropdown__arrow{
  display: none;
  justify-content: center;
  transform: rotate(180deg);
  padding: 10px 0;
  cursor: pointer;
  @media only screen and (max-width: 767px){
    display: flex;
  }
}
.accordion-captcha{
  transform: scale(0.66);
}
.accordion__inform__open {
  display: none;
  padding-bottom: 20px;
  @media only screen and (max-width:945px){
    padding-bottom: 30px;
  }
  @media only screen and (max-width:830px){
    padding-bottom: 45px;
  }
  @media only screen and (max-width:767px){
    padding-bottom: 0px;
  }
}
.accordion__inform1{
  flex-wrap: nowrap;
  padding: 15px 20px;
}
.data-meetup.data-meetup{
  @media only screen and (max-width: 767px){
    padding-left:20px;
  }
  @media only screen and (max-width: 639px){
    padding-left:0;
  }
}
.open1 {
  display: flex;
}
.accordion__data-send{
  @media only screen and (max-width: 767px){
    display: none;
  }
}
.accordion__country-send{
  @media only screen and (max-width: 767px){
    display: none;
  }
}
.accordion__inform-top{
  display: none;
  width: 100%;
  @media only screen and (max-width: 767px){
   display: flex;
   justify-content: space-between;
   padding: 15px 20px;
  }
  @media only screen and (max-width: 640px){
    flex-direction: column;
    align-items: center;
   }
}
.rotate.rotate{
  background: url("../images/icons/pointer_accordion.png") no-repeat center / cover;
  width: 25px;
  height: 25px;
  padding: 0 10px;
  transform: rotate(0deg);
}   
.accordion__title1.accordion__title1{
  width: 780px;
  padding-right: 20px;
  @media only screen and (max-width: 767px){
    padding-right: 0;
  }
}
.ui-accordion .ui-accordion-content{
  cursor: pointer;
  padding: 24px 100px;
  background: #fbfbfb;
  border-radius: 5px;
  border: 2px solid #d9d9d9;
  @media only screen and (max-width: 767px){
    padding: 24px 70px;
  }
  @media only screen and (max-width: 640px){
    padding: 24px 20px;
  }
}
}

.navigation{
  padding: 40px 0;
  .navigation__wrap ul{
    display: flex;
    width: 70px;
    margin: 0 auto;
    justify-content: space-between;
    .navigation__item{
      width: 10px;
      height: 10px;
      display: none;
      background-color: #e9e9e9;
      border-radius: 50%;
      &:nth-of-type(4){
        background-color: #ff0000;
      }
      @media only screen and (max-width: 767px){
       display: block;
      }
    }
  }
}
.swiper-button-prev, .swiper-button-next{
  cursor: pointer;
}
//=====Page calendar_of_events  END!!!====//
///////////////////////////////////////////

.modal.settingsModal {
  opacity: 1;
  max-width: 900px;
  transform: translate(-50%, -50%);
  .tabs__content {
    display: none;
    width: 65%;
    padding: 55px 45px;
    height: 100%;
    button {
      background-color: #011f40;
      width: 116px;
      height: 46px;
      margin-right: 100%;
      color: #fff;
      border-radius: 5px;
      transition: all 0.3s ease-in;
      margin-top: 2rem;
    }
    button:hover {
      border: 1px solid #011f40;
      color: #011f40;
      background-color: #fff;
    }
    h2 {
      font-size: 1.8rem;
      color: #000;
      font-weight: 400;
      text-transform: none;
      margin-bottom: 4rem;
    }
    p, li {
      font-size: 1.4rem;
      color: #000;
      text-align: left;
      margin-bottom: 7px;
      line-height: 1.3em;
      a {
        text-decoration: underline;
      }
    }
    li {
      line-height: 2.5;
      input {
        visibility: hidden;
      }
      label {
        position: relative;
        padding-left: 10px;
      }
      label:before {
        content: '';
        position: absolute;
        left: -20px;
        bottom: 0;
        width: 17px;
        height: 17px;
        border: 1px solid rgba(0,0,0,0.3);
        background-color: #fff;
      }
      label:after {
        content: '';
        position: absolute;
        left: -14px;
        bottom: 4px;
        width: 0;
        height: 0;
        border: 2px solid #fff;
        border-top: none;
        border-left: none;
        transform: rotate(45deg);
        transition: all 0.15s ease-out;
      }
      input:checked + label:after {
        border-color: rgba(0,0,0,0.5);
        width: 7px;
        height: 14px;
        transition: all 0.15s ease-out;
      }
    }
    ul {
      margin: 20px 0;
    }
  }
  .tabs__content.active {
    display: block;
  }
  #tabs {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    background-color: #fff;
    color: #000000;
    border-radius: 5px;
    overflow: hidden;
  }
  .tabs__caption {
    width: 35%;
    background-color: #c5c5c5;
    height: 500px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    padding-top: 60px;
    li {
      color: #011f40;
      height: 60px;
      line-height: 60px;
      width: 100%;
      padding-left: 80px;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      a {
        font-size: 1.8rem;
        font-weight: 400;
      }
    }
    li:first-child {
      background-image: url(../images/landing/igb-2018/priv.png);
      background-repeat: no-repeat;
      background-position: 40px 50%;
    }
    li:last-child {
      background-image: url(../images/landing/igb-2018/setting.png);
      background-repeat: no-repeat;
      background-position: 40px 50%;
    }
    li.active {
      background-color: #fff;
    }
  }
}
.noEvent{
  color: #999797;
  .slider__winner__text p{
    &:hover{
      color: #999797;
    }
  }  
}
// settings block
.set__settings__block {
  position: fixed;
  bottom: 40px;
  left: 30px;
  width: 580px;
  height: 360px;
  padding: 50px 60px;
  background-color: #011f40;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 6px;
  z-index: 10;
  h3 {
    font-size: 18px;
    text-align: center;
    margin-bottom: 3rem;
  }
  p {
    font-size: 15px;
    a {
      text-decoration: underline;
    }
  }
  button {
    background-color: #fff;
    width: 116px;
    height: 46px;
    color: #011f40;
    border-radius: 5px;
    transition: all 0.3s ease-in;
    margin: 0 auto;
    margin-top: 3rem;
  }
  button:hover {
    border: 1px solid #fff;
    color: #fff;
    background-color: #011f40;
  }
}

.menu-primary > li.purple-shadow > a{
  color: #ffffff;
  text-shadow: 0 0 6px #ca00ff, 0 0 12px #ca00ff, 0 0 24px;
  transition: text-shadow 0.5s;
}

.menu-primary > li.purple-shadow > a:hover{
  text-shadow: 0 0 3px #ca00ff, 0 0 6px #ca00ff, 0 0 12px #ca00ff, 0 0 24px #ca00ff, 0 0 48px #ca00ff;
}

.menu-primary > li.ice-shadow > a{
  color: #ffffff;
  text-shadow: 0 0 6px #27d2fe, 0 0 12px #27d2fe, 0 0 24px;
  transition: text-shadow 0.5s;
}

.menu-primary > li.ice-shadow > a:hover{
  text-shadow: 0 0 3px #27d2fe, 0 0 6px #27d2fe, 0 0 12px #27d2fe, 0 0 24px #27d2fe, 0 0 48px #27d2fe;
}

.menu-primary > li.red-shadow > a{
  color: #ffffff;
  text-shadow: 0 0 6px #ff0600, 0 0 12px #ff0600, 0 0 24px;
  transition: text-shadow 0.5s;
}

.menu-primary > li.red-shadow > a:hover{
  text-shadow: 0 0 3px #ff0600, 0 0 6px #ff0600, 0 0 12px #ff0600, 0 0 24px #ff0600, 0 0 48px #ff0600;
}

.menu-primary > li.green-shadow > a{
  color: #ffffff;
  text-shadow: 0 0 6px #43a047, 0 0 12px #43a047, 0 0 24px;
  transition: text-shadow 0.5s;
}

.menu-primary > li.green-shadow > a:hover{
  text-shadow: 0 0 3px #43a047, 0 0 6px #43a047, 0 0 12px #43a047, 0 0 24px #43a047, 0 0 48px #43a047;
}

.menu-primary > li.orange-shadow > a{
  color: #ffffff;
  text-shadow: 0 0 6px #fb8c00, 0 0 12px #fb8c00, 0 0 24px;
  transition: text-shadow 0.5s;
}

.menu-primary > li.orange-shadow > a:hover{
  text-shadow: 0 0 3px #fb8c00, 0 0 6px #fb8c00, 0 0 12px #fb8c00, 0 0 24px #fb8c00, 0 0 48px #fb8c00;
}

////////////////////////////
//========Sitemap======//
////////////////////////////
.sitemap__page h1{
  margin-bottom: 25px !important;
}
.sitemap__block{
  padding-bottom: 120px;
  ul{
    list-style-type: disc;
    margin-left: 80px;
    font-size: 20px;
  }
  li{
    margin-bottom: 10px;
  }
  a{
    font-size: 20px;
    font-weight: 500;
    transition: .12s ease-in;
    &:hover{
      color: #707070;
    }
  }
  @media only screen and (max-width: 1024px){
    ul{
      font-size: 20px;
    }
    a{
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 767px){
    ul{
      font-size: 18px;
    }
    a{
      font-size: 16px;
    }
  }
  @media only screen and (max-width: 479px){
    ul{
      font-size: 16px;
      margin-left: 20px;
    }
    a{
      font-size: 14px;
    }
  }
}
.sitemap__block li > ul li{
  list-style-type: circle;
  &:first-child{
    margin-top: 10px;
  }
}
.moscowMain .ourProgram .daysContent .day, .moscowMain .ourProgram .daysContent .sigmaDay, .amsterdamMain .ourProgram .daysContent .day, .amsterdamMain .ourProgram .daysContent .sigmaDay, .sigmaMain .ourProgram .daysContent .day, .sigmaMain .ourProgram .daysContent .sigmaDay{
  width: 90%;
@media only screen and (max-width: 1300px) {
  width: 100%;
}
@media only screen and (max-width: 950px) {
  width: 100%;
}
@media only screen and (max-width: 550px) {
  width: 100%;
}
}
.amsterdamMain .mapContent, .sigmaMain .mapContent .formBlock-sigma h1{
   margin-bottom: 10px;
   text-align: left;
   font-size: 3.1rem;
   @media only screen and (max-width: 1345px){
    margin: 0 0 10px;
  }
   @media only screen and (max-width: 800px){
     text-align: center;
   }
}
.amsterdamMain .mapContent, .sigmaMain .mapContent .formBlock-sigma h2{
  font-size: 2.3rem;
  font-weight: 300;
}

.news_wrapper{
  position: relative;
  overflow: hidden;
  .news_wrapper__bg{
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    // background-color: rgba(123, 124, 128, .95);
    // background-color: rgba(0, 0, 0, 0.45);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .news_wrapper__video{
    overflow: hidden;
    iframe{
      display: block;
    } 
  }
}


 
