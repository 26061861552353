// Text editor

.partner .html-editor-content {

  p:nth-child(1) {
    margin-top: 0px;
  }
}

.partner .html-editor-content h2 {
  margin-top: 0px;
  line-height: 1;
}

.html-editor-content {
  color: $color-black;
  font-size: 1.6rem;
  font-weight: 300;


  p {
    padding: 0 0 15px;
    line-height: 1.63;
    text-align: justify;
    
    +h3 {
      margin-top: 25px;
    }
    
    img {
      margin: 30px auto;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    text-align: left;
  }

  h2 {
    min-width: 100%;
    margin: 25px 0 10px;
    font-size: 2.2rem;
    line-height: 1.33;
    text-align: left;
    @media only screen and (max-width: 480px) {
      font-size: 2.2rem;
    }
  }
  
  h3 {
    margin-bottom: 14px;
  }
  
  h4 {
    font-size: 2.2rem;
  }

  h5 {
    font-size: 2rem;
  }
  
  em,
  i {
    font-style: italic;
  }

  .marker {
    background-color: $color-yellow;
  }
  
  .underline {
    text-decoration: underline;
  }
  
  blockquote {
    position: relative;
    margin: 0 9rem 2rem 8rem;
    padding: 1rem 0 1rem 2rem;
    font-style: italic;
    font-weight: 300;
    text-align: justify;

    p {
      margin: 0;
    }

    &::before {
      display: inline-block;
      position: absolute;
      top: 1px;
      left: 0;
      width: 2px;
      height: 100%;
      background-color: $color-grey;
      content: '';
    }
  }

  ul {
    margin: 0 0 15px;
    
    li {
      margin-left: 25px;
      font-weight: 300;
      list-style: disc;
    }
  }

  ol {
    margin: 0 0 15px;

    li {
      margin-left: 25px;
      list-style-type: decimal;
    }
  }
  
  .btn-group {
    margin: 50px 0 80px;
    text-align: center;
    
    .btn {
      min-width: 250px;
      
      +.btn {
        margin-left: 26px;
      }
    }
  }

  .get-access {
    h3 {
      text-align: center;
    }
  }
  
  @media only screen and (max-width: 989px) {
    blockquote {
      margin: 0 6rem 2rem 5rem;
    }
  }
  
  @media only screen and (max-width: 619px) {
    blockquote {
      margin: 0 3rem 2rem 2rem;
    }
    
    .btn-group {
      margin-top: 20px;
      
      .btn {
        width: 100%;
        max-width: 400px;
        
        +.btn {
          margin-top: 15px;
          margin-left: 0;
        }
      }
    }
  }
}

// .text {
//   color: $color-black;
//   text-align: left;

//   h1,
//   h2,
//   h3,
//   h4,
//   h5 {
//     text-align: left;
//   }

//   h3 {
//     font-size: 2.4rem;
//   }

//   h4 {
//     font-size: 2.2rem;
//   }

//   h5 {
//     font-size: 2rem;
//   }

//   strong,
//   b {
//     font-weight: bold;
//   }

//   em,
//   i {
//     font-style: italic;
//   }

//   .marker {
//     background-color: $color-yellow;
//   }

//   p {
//     margin-bottom: 2rem;
//   }

//   .underline {
//     text-decoration: underline;
//   }

//   img {
//     display: block;
//     width: auto;
//     max-width: 100%;
//     margin: 2rem auto;
//   }

//   blockquote {
//     position: relative;
//     margin: 1rem 2rem;
//     padding: 1rem 0 1rem 2rem;
//     font-style: italic;
//     font-weight: 300;
//     text-align: justify;

//     p {
//       margin: 0;
//     }

//     &::before {
//       display: inline-block;
//       position: absolute;
//       top: 1px;
//       left: 0;
//       width: 2px;
//       height: 100%;
//       background-color: $color-grey;
//       content: '';
//     }
//   }

//   ul {
//     margin-bottom: 2rem;

//     li {
//       margin: 0;
//       list-style-type: disc;
//       list-style-position: inside;
//     }
//   }

//   ol {
//     margin-bottom: 2rem;

//     li {
//       margin: 0;
//       list-style-type: decimal;
//       list-style-position: inside;
//     }
//   }
// }
