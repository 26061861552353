// Fonts
html {
  font-size: 62.5%;

  body {
    @include font-main;
    font-size: 1.4rem;
    line-height: 1.25;
  }
}

.fa {
  @include font-main;

  &::before {
    @include font-awesome;
  }
}

%h1-h2 {
  display: block;
  max-width: 770px;
  margin: 0 auto 50px;
  color: $color-black;
  font-weight: 300;
  text-align: center;
  text-transform: uppercase;
}

h1 {
  @extend %h1-h2;
  font-size: 3.4rem;
  line-height: 1.18;
  
  @media only screen and (max-width: 449px) {
    font-size: 3rem;
    line-height: 1.4;
  }
}

h2 {
  @extend %h1-h2;
  font-size: 3.1rem;
  line-height: 1.29;

  @media only screen and (max-width: 449px) {
    font-size: 2.6rem;
  }
}

h3 {
  font-size: 2.2rem;
  font-weight: 300;
  line-height: 1.33;
}

h4 {
  font-size: 1.4rem;
  text-transform: uppercase;
}

p {
  font-weight: 300;
  line-height: 1.57;
  
  &.title {
    display: block;
    margin-bottom: 50px;
    font-size: 2.2rem;
    line-height: 1.33;
    text-align: center;

    @media only screen and (max-width: 449px) {
      font-size: 2rem;
    }
  }
}