.games {
  background-color: $color-white;

  aside {
    display: block;
    width: 200px;
    float: left;
    border-right: 1px solid $color-grey-dark;

    h3 {
      margin: 0 0 2rem;
      color: $color-grey-darker;
      font-size: 2.2rem;
    }

    ul {
      position: relative;
      max-height: 670px;
      overflow-x: hidden;
      li {
        display: block;
        margin-bottom: 1rem;
        @media only screen and (max-width: 699px) {
          display: inline-block;
        }

        >* {
          transition: border-bottom-color .2s ease;
          border-bottom: 1px solid transparent;
          background-color: transparent;
          color: $color-grey-dark;
          font-size: 1.4rem;

          &:hover {
            border-bottom-color: $color-red;
          }
        }

        &.active {
          >* {
            color: $color-red;
          }
        }

        &.disabled {
          >* {
            opacity: .4;
            pointer-events: none;
          }
        }
      }
    }
  }

  .content {
    display: block;
    position: relative;
    width: calc(100% - 200px);
    padding-left: 60px;
    float: left;

    >h3 {
      display: none;
    }

    .filters {
      display: block;
      margin-bottom: 4rem;
      font-size: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 10px 20px;
        color: $color-grey-darker;
        font-size: 1.8rem;
        vertical-align: top;

        >* {
          display: block;
          height: 34px;
          padding: 0 25px;
          transition: border-color .2s ease;
          border: 1px solid $color-grey-dark;
          border-radius: .6rem;
          background-color: transparent;
          color: $color-grey-darker;
          line-height: 32px;

          &:hover {
            border-color: $color-red;
          }
        }

        &.active {
          >* {
            border-width: 2px;
            border-color: $color-red;
          }
        }

        &.disabled {
          >* {
            opacity: .4;
            pointer-events: none;
          }
        }
      }

    }

    .games-list {
      display: block;
      position: relative;
      width: calc(100% + 20px);
      margin: 0 -10px 3rem;
      font-size: 0;
      @media (min-width: 350px) and (max-width: 459px) {
        margin: 0;
      }

      &::before {
        @include total-center;
        display: block;
        position: absolute;
        width: 65px;
        height: 65px;
        transition: all .4s ease;
        background: url('../images/icon/ico-loading.svg');
        content: '';
        opacity: 0;
        visibility: hidden;
        animation: rotateSpinner 1.2s infinite linear;
        pointer-events: none;
      }

      >li {
        display: inline-block;
        position: relative;
        width: calc(33.333333% - 20px);
        margin: 0 10px 10px 10px;
        padding-top: 3px;
        padding-right: 3px;
        transition: all .3s ease;
        font-size: 1.7rem;
        opacity: 1;
        overflow: hidden;
        @media (min-width: 350px) and (max-width: 459px) {
          width: 70% !important;
          display: block;
          margin: 0 auto 10px auto;
        } 

        .illustration {
          display: block;
          img {
            width: 100%;
            @media (min-width: 990px) {
              height: 173px;
              object-fit: cover;
            }
            @media (max-width: 989px) {
              height: 159px;
              object-fit: cover;
            }
          }
        }

        .wrap {
          position: absolute;
          top: 3px;
          right: 3px;
          width: 85px;
          height: 88px;

          & ~ .overlay {
            width: 99%;
            height: 99%;
          }
        }

        .game-tag {
          display: block;
          position: relative;
          top: 15px;
          left: -5px;
          width: 120px;
          padding: 4px 0;
          transform: rotate(45deg);
          color: $color-white;
          font-size: 17px;
          font-weight: 700;
          text-align: center;
          z-index: 2;

          &::before,
          &::after {
            position: absolute;
            bottom: -3px;
            border-right: 3px solid transparent;
            border-left: 3px solid transparent;
            content: '';
          }

          &::before {
            left: 0;
          }

          &::after {
            right: -1px;
          }

          &.is-top {
            background-color: $color-blue;

            &::before,
            &::after {
              border-top: 3px solid $color-blue2;
            }
          }

          &.is-new {
            background-color: $color-red-bright;

            &::before,
            &::after {
              border-top: 3px solid $color-red3;
            }
          }
        }

        .overlay {
          position: absolute;
          top: -100%;
          left: 0;
          width: 99%;
          height: 176px;
          padding: 5% 6% 30px;
          transition: all .4s ease;
          background-color: $color-blue-darker;
          color: $color-white;
          z-index: 3;

          h3 {
            margin-bottom: 1rem;
            font-size: 1.6rem;
            text-transform: uppercase;
          }

          .info {
            font-size: 1.4rem;
          }

          ul {
            li {
              display: block;
              width: 100%;
              margin-bottom: 2px;
              font-size: 1.4rem;

              strong {
                display: inline-block;
                font-weight: 600;
              }
            }
          }

          .buttons {
            position: absolute;
            bottom: 6%;
            left: 6%;
            width: 88%;

            .company {
              display: inline-block;
              padding-top: .5rem;
              color: $color-blue-light;
              font-size: 1.4rem;
              font-weight: 600;
              vertical-align: middle;
            }

            .btn {
              height: auto;
              padding: 4px 12px;
              float: right;
              border-width: 2px;
              border-radius: .6rem;
              color: $color-white;
              font-size: 1.3rem;
              font-weight: 600;
              line-height: 1.5rem;
            }
          }
        }

        &:hover {
          .overlay {
            top: 2%;
          }

          .wrap {
            ~ .overlay {
              top: 0;
              width: 100%;
              @media (min-width: 990px) {
                height: 176px;
              }
              @media (max-width: 989px) {
                height: 164px;
              }
            }
          }
        }
      }
      .games-not-found {
        font-size: 1.8rem;
        font-weight: 300;
      }
    }

    &.hide {
      .games-list {
        &::before {
          opacity: 1;
          visibility: visible;
        }

        li {
          transform: scale(.2);
          opacity: 0;
        }
      }
    }
  }
  
  @media only screen and (max-width: 1099px) {
    .content {
      .games-list {
        >li {
          .overlay {
            padding: 3% 4% 40px;

            h3 {
              margin-bottom: .1rem;
              font-size: 1.4rem;
            }

            ul {
              li {
                font-size: 1.2rem;
              }
            }

            .buttons {
              bottom: 5%;
              left: 4%;
              width: 92%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 989px) {
    .content {
      .filters {
        li {
          margin: 0 5px 5px;
        }
      }

      .games-list {
        >li {
          width: calc(50% - 20px);

          .overlay {
            padding: 5% 6% 40px;
            height: 160px;

            h3 {
              margin-bottom: .4rem;
              font-size: 1.6rem;
            }

            ul {
              li {
                font-size: 1.4rem;
              }
            }

            .buttons {
              bottom: 6%;
              left: 6%;
              width: 88%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 819px) {
    .content {
      padding-left: 30px;
    }
  }
  
  @media only screen and (max-width: 799px) {
    .content {
      .games-list {
        >li {
          .overlay {
            padding: 3% 4% 40px;

            h3 {
              margin-bottom: .1rem;
              font-size: 1.4rem;
            }

            ul {
              li {
                font-size: 1.2rem;
              }
            }

            .buttons {
              bottom: 5%;
              left: 4%;
              width: 92%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 699px) {
    aside {
      width: 100%;
      margin-bottom: 2rem;
      padding-top: 0;
      padding-right: 0;
      border-right: 0;
      text-align: center;

      li {
        display: inline-block;
        margin: 0 .5rem .5rem 0;

        &:last-of-type {
          &::after {
            display: none;
          }
        }

        &::after {
          display: inline-block;
          width: 4px;
          height: 4px;
          margin: 0 0 .2rem .5rem;
          border-radius: 50%;
          background-color: $color-grey-dark;
          content: '';
        }
      }
    }

    .content {
      width: 100%;
      padding-left: 0;

      >h3 {
        display: block;
        margin: 0 0 2rem;
        color: $color-grey-darker;
        font-size: 2.2rem;
        text-align: center;
      }

      .filters {
        li {
          margin: 0 5px 5px;

          >* {
            padding: 0 15px;
          }
        }
      }

      .games-list {
        >li {
          .overlay {
            padding: 5% 6% 40px;

            h3 {
              margin-bottom: .4rem;
              font-size: 1.6rem;
            }

            ul {
              li {
                font-size: 1.4rem;
              }
            }

            .buttons {
              bottom: 6%;
              left: 6%;
              width: 88%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 569px) {
    .content {
      .games-list {
        >li {
          .overlay {
            padding: 3% 4% 40px;

            h3 {
              margin-bottom: .1rem;
              font-size: 1.4rem;
            }

            ul {
              li {
                font-size: 1.2rem;
              }
            }

            .buttons {
              bottom: 5%;
              left: 4%;
              width: 92%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 459px) {
    .content {
      .games-list {
        width: 100%;

        >li {
          width: 100%;

          .overlay {
            padding: 5% 6% 40px;

            h3 {
              margin-bottom: .4rem;
              font-size: 2.4rem;
            }

            ul {
              li {
                font-size: 2rem;
              }
            }

            .buttons {
              bottom: 6%;
              left: 6%;
              width: 88%;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 379px) {
    .content {
      .games-list {
        >li {
          .overlay {
            h3 {
              font-size: 2rem;
            }

            ul {
              li {
                font-size: 1.8rem;
              }
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 335px) {
    .content {
      .games-list {
        >li {
          .overlay {
            h3 {
              font-size: 1.8rem;
            }

            ul {
              li {
                font-size: 1.6rem;
              }
            }
          }
        }
      }
    }
  }
}
