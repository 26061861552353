

.header-casino {
    padding-bottom: 0px;
    background: url('../images/slotegrator-casino/bg1.png') no-repeat;
    background-size: cover;
    box-shadow: inset 0 0 300px 50px #000000;
    background-position: 50% 50%;
    position: relative;
    overflow: hidden;
    .title {
        font-size: 70px;
        color: #ffffff;
        position: relative;
        text-transform: none;
        margin-bottom: 40px;
        z-index: 1;
        @media only screen and (max-width: 767px){
           font-size: 30px;
        }
  }
    .info{
        max-width: 650px;
        text-align: center;
        margin: 0 auto;
        color: #ffffff;
        padding-bottom: 100px;
        position: relative;
        z-index: 1;
        p{
            padding-bottom: 20px;
            font-size: 16px;
            letter-spacing: 0.5px;
            line-height: 1.2;
            font-weight: 300;
        }
        .btn_casino{
            max-width: 200px;
            width: 100%;
            @media only screen and (max-width: 767px){
               display: none;
             }
        }
        @media only screen and (max-width: 767px){
            padding-bottom: 0;
          }
    }
}
.container-header_casino{
    @media only screen and (max-width: 767px){
         padding-top: 200px;
    }
}
.header-casino__dark-bg{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0,0,0, .3);
    z-index: 1;
    @media only screen and (max-width: 767px){
        background: linear-gradient(transparent ,rgba(0, 0, 0, .8),rgba(0, 0, 0, .9), rgba(0, 0, 0, 1) );
      }
}
.header-casino-bg{
    position: absolute;
    top: 15px;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    @media only screen and (max-width: 1179px){
        align-items: flex-end;
        
    }
    .bg-left{
        width: 560px;
        position: relative;
        z-index: 0;
        @media only screen and (max-width: 1179px){
            left: -145px;
            position: absolute;
            bottom: 40px;
        }
        @media only screen and (max-width: 767px){
            width: 350px;
            top: 0;
            left: 10px;
            bottom: auto;
         }
         @media only screen and (max-width: 480px){
            width: 300px;
            top: 0;
            left:-85px;
            bottom: auto;
         }
        img{
            // height: 560px;
            width: 560px;
            position: relative;
            z-index: 1;
            @media only screen and (max-width: 767px){
                width: 400px;
            }
        }
        &::after{
            content:' ';
            height: 700px;
            width: 700px;
            border-radius: 50%;
            background: radial-gradient( rgba(250, 0, 0, 0.55),  rgba(250, 0, 0, 0.5), transparent,transparent);
            position: absolute;
            top: -210px;
            left: 150px;
            bottom: 0;
            right: 0;
        
        }
    }
    .bg-right{
        width: 600px;
        position: relative;
        z-index: 0;
        @media only screen and (max-width: 1179px){
            left: auto;
            right: 0;
            position: absolute;
            bottom: -20px;
        }
        img{
            // height: 600px;
            width: 580px;
            position: relative;
            z-index: 1;
        }
        .img-hide{
            display: none; 
            }
        @media only screen and (max-width: 1179px){
            width: auto;
           .img-hide{
            display: block; 
            width: 482px;
            @media only screen and (max-width: 480px){
                width: 200px;
                top: 0;
                bottom: auto;
                left: 105px;
             }
            }
            .img-supermen{
                display: none;
            }

        }
         @media only screen and (max-width: 767px){
            width: 305px;
            top: 0;
            bottom: auto;
            }
        &::before{
            content: ' ';
            height: 700px;
            width: 700px;
            border-radius: 50%;
            background: radial-gradient(rgba(1, 66, 153, 0.55),  rgba(1, 66, 153,0.5), transparent,transparent);
            position: absolute;
            top: -190px;
            left: -275px;
            right: 0;
            bottom: 0;
            @media only screen and (max-width: 1179px){
                left: -40px;
             }
        }
    }
}
.logo-casino{
    // background-color: #000000;
    padding: 0;
    background-size: cover;
    background-position: 50% 64%;
    z-index: 1;
    position: relative;
    .fa-angle-left{
        left: 5px;
        display: none;
        &:active {
            outline: none;
            outline-offset: 0;
            background-color: transparent;
        }
    }
    .fa-angle-right{
        right: 0;
        display: none;
    }
    .slick-arr::before{
        line-height: 7rem;
        color: #ff0000;
        cursor: pointer;
    }
    ul{
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-wrap: wrap;
        padding-left: 58px;
        padding-right: 58px;
        @media only screen and (max-width: 1179px){
            padding-left: 20px;
            padding-right: 20px;
        }
        li{
            // padding: 10px;
            margin: 0 auto;
        }
        a{
            width:80%;
            display: block;
            margin: 0 auto;
        }
    }
    &__shadow{
        padding: 60px 0 20px;
        background: linear-gradient( transparent, rgba(0, 0, 0, 1),rgba(0, 0, 0, 1),rgba(0, 0, 0, 1));
    }
        ul{
            max-width: 100%;
            margin: 0 auto;
            @media only screen and (max-width: 1439px){
                width:767px;
            }
        }
        .logo-casino__slider{
            img{
                margin: 0 auto;
            }
        }
}
.compared-casino{
    padding: 85px 0;
    color: #000000;
    .compared-wrap{
        display: flex;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 1179px){
             display: block;
             padding-left: 50px;
             padding-right: 50px;     
        }
        @media only screen and (max-width: 767px){
            padding-left: 0;
            padding-right: 0;
        }
       .compared-info{
            font-size: 50px;
            width: 415px;
            font-weight: 300;
            line-height: 1.2;
            @media only screen and (max-width: 1179px){
                width: 100%;
                padding-bottom: 30px;    
           }
           @media only screen and (max-width: 767px){
            font-size: 30px;
            }
        }
        .compared-text{
            width: 595px;
            font-size: 16px;
            @media only screen and (max-width: 1179px){
                width: 100%;   
           }
            p{
                font-weight: 400;
                line-height: 1.2;
               
            }
        } 
    }
    
}

.offer-casino{
    background-color: #F7F7F7;
    text-align: center;
    color: #000000;
    padding-bottom: 160px;
    .offer-card-wrap{
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        .offer-card{
            width: 260px;
            padding: 2px 2px 10px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media only screen and (max-width: 1179px){
                padding-bottom: 50px;
            }
            .offer-logo{
                padding-bottom: 25px;
                img{
                    margin: 0 auto; 
                    height: 100px;
                }  
            }
            h3{
                font-size: 25px;
                text-transform: uppercase;
                font-weight: 400;
                margin-bottom: 15px;
            }
            p{
                font-size: 16px;
                font-weight: 400;
                padding-bottom: 20px;
            }
            a{
                max-width: 200px;
                width: 100%;
                font-size: 16px;
                margin-bottom: 0;
                margin-top: auto; 
            }
        }
    } 
}

.features{
    background: url('../images/slotegrator-casino/bg_world.png') no-repeat 50% 50%;
    background-size: cover;
    padding: 0px 0;
    position: relative;
    @media only screen and (max-width: 1179px){
        padding: 50px 0;
        background-position: 12% 50%;
        .features-wrap{
            text-align: center;
            display: block;
            position: relative;
        }
        .features-img{
            display: inline-block;
            
        }
       
    }
    @media only screen and (max-width: 767px){
        background-position: 80% 50%;
    }
    
    &-img{
        outline: 1px solid ridge;
        img{
            margin: -90px 0;
            max-width: 100%;
            @media only screen and (max-width: 1179px){
                margin: -179px 0;
                margin-bottom: -250px;
            }
            @media only screen and (max-width: 767px){
                margin: 0;
                margin-top: -30%;
                margin-bottom: -10%
            }
        }
    }
    &-info{
        width: 50%;
        color: #ffffff;
        @media only screen and (max-width: 1179px){
            width: 100%;
            padding-left:30px;
            padding-right: 30px;
        }
        @media only screen and (max-width: 767px){
            padding-left: 0;
            padding-right: 0;
        }
        h2{
            font-size: 36px;
            font-weight: 300;
            color:#ffffff;
            margin-bottom: 20px;
            text-align: left;
            @media only screen and (max-width: 1179px){
                margin: 0;
                margin-bottom: 20px;
            }
            @media only screen and (max-width: 767px){
                font-size: 30px;
            }
        }   
        p{
            font-size: 16px;
            font-weight: 400;
            text-align: left;
        } 
    }
    &-wrap{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        position: relative;
        z-index: 1;
    }
    
}
.table1__casino{
    .table1__wrap{
        display: flex;
        align-items: center;
    }
        .table__items{
            margin-right: 20px;
            width: 220px;
            li{
                padding-bottom: 15px;
                color: #000000;
                font-size: 16px;
            }
        }
        .table__card{
            margin: 0 5px;
            border-radius: 20px;
            text-align: center;
            width: 237px;
            padding-bottom: 35px;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            li{
                //padding-bottom: 15px;
                font-size: 16px;
                width: 100%;
                text-align: center;
                img{
                    width: 24px;
                    margin: 0 auto;
                    height: 24px;
                    text-align: center;
                }
            }
                
            .table__card-top{
                font-size: 16px;
                text-transform: uppercase;
                color: #ffffff;
                font-weight: 400;
            }
            .card-blue{
                background: #183B67;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                padding: 28px 0;

            }
            .card-red{
                background: #D70F0F;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                padding: 28px 0;
            }
            .btn-card-casino{
                width: 140px;
                height: 40px;
                line-height: 2.5;

            }
           
        }
        .table-blue{
            ul{
                padding-top: 30px;
                padding-bottom: 30px;
            }
            .btn-card-casino{
                background: #183B67;
                border: 0.1rem solid #183B67;
                &:hover{
                    box-shadow: 0 2px 0 0 rgb(10, 23, 37);
                }
            }    
        }
        .table-red{
            ul{
                padding-top: 55px;
                padding-bottom: 55px;
            }
            .btn-card-casino{
                background: #D70F0F;
                
            }     
        }
}
.more_height.more_height.more_height {
    padding-bottom: 35px;
}
.more_height1.more_height1.more_height1 {
    padding-bottom: 25px;
}
.table__height.table__height.table__height{
    font-size: 14px;
}
.container-table{
    padding-bottom: 30px;
}
.customization{
    background: url('../images/slotegrator-casino/bg33.jpg') no-repeat 50% 50%;
    background-size: cover;
    padding: 64px 0;
    position: relative;
    .custom-bg{
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        position: absolute;
        z-index: 1;
        background: linear-gradient(transparent,rgba(0,0,0, .1));
        @media only screen and (max-width: 1179px){
            z-index: 1;
        background: linear-gradient(transparent, transparent,rgba(0,0,0, .9), rgba(0,0,0, .9));
        }
        // @media only screen and (max-width: 767px){
        //     z-index: 1;
        // background: linear-gradient(transparent,rgba(0,0,0, .9), rgba(0,0,0, 1));
        // }
        @media only screen and (max-width: 379px){
            z-index: 1;
        background: linear-gradient(transparent,transparent, rgba(0,0,0, 09), rgba(0,0,0, .9), rgba(0,0,0, .9));
        }
    }
    .custom-wrap{
        display: flex;
        align-items: center;
        @media only screen and (max-width: 1179px){
            flex-direction: column;
        }
    
        .custom-info {
            font-size: 16px;
            padding-right: 20px;
            width: 52%;
            z-index: 1;
            color: #ffffff;
            h2{
                font-size: 36px;
                text-align: left;
                color: #ffffff;
                margin-left: 0;
                margin-bottom: 30px;
                @media only screen and (max-width: 767px){
                    font-size: 30px;
                }

            }

            @media only screen and (max-width: 1179px){
                width: 100%;
                margin-top: 150px;
                order: 1;
                z-index: 1;
                padding: 0 40px;
            }
            @media only screen and (max-width: 767px){
                padding: 0;
            }
            @media only screen and (max-width: 379px){
                margin-top: 290px;
             }
        }
        .games-info{
            order:1;
        }
        .custom-img {
            position: relative;
            width: 550px;
             @media only screen and (max-width: 1179px){
               order:0;
            }
            // @media only screen and (max-width: 767px){
            //     width: 100%;
            //  }
            img{
                width: 100%;
            }
            &::before{
                content: "";
                position: absolute;
                top: -255px;
                right: 0;
                background: url('../images/slotegrator-casino/Customization.png') no-repeat;
                background-size: contain;
                width: 100%;
                height: 550px; 
                order:0; 
                @media only screen and (max-width: 1179px){
                    top: -140px;
                 }
                 @media only screen and (max-width: 767px){
                    top: -95px;
                 }
                 @media only screen and (max-width: 379px){
                    top: -100px;
                 }
            }
           
        }
        .games-img{
            position: relative;
            width: 550px;
             @media only screen and (max-width: 1179px){
               order:0;
            }
            // @media only screen and (max-width: 767px){
            //     width: 100%;
            //  }
            img{
                width: 100%;
            }
            &::before{
                content: "";
                position: absolute;
                top: -275px;
                right: 0;
                background: url('../images/slotegrator-casino/Group.png') no-repeat;
                background-size: contain;
                width: 100%;
                height: 550px; 
                order:0; 
                @media only screen and (max-width: 1179px){
                    top: -190px;
                 }
                 @media only screen and (max-width: 767px){
                    top: -150px;
                 }
                 @media only screen and (max-width: 379px){
                    top: -160px;
                 }
            }
        }
    }
}
.custom-info-p.custom-info-p.custom-info-p.custom-info-p.custom-info-p{
    line-height: 1.33;
}
.games-block{
    background: url('../images/slotegrator-casino/Games_bg4.jpg')    no-repeat 50% 50%;
    background-size: cover;
    padding: 100px 0;
}
.services-games{
    background: url('../images/slotegrator-casino/services_bg.jpg')    no-repeat 50% 50%;
    background-size: cover;
    padding: 100px 0;
    .services-img {
        position: relative;
        width: 240px;
        margin-left: 200px;
        @media only screen and (max-width: 1179px){
            width: 200px;
            margin-left: 0;
            order:0;
        }
        // @media only screen and (max-width: 767px){
        //     width: 100%;
        //  }
        img{
            width: 100%;
        }
        &::before{
            content: "";
            position: absolute;
            top: -210px;
            margin-right: -125px;
            background: url('../images/slotegrator-casino/services.png') no-repeat;
            background-size: contain;
            width: 100%;
            height: 437px; 
            order:0; 
            @media only screen and (max-width: 1179px){
                top: -110px;
             }
             @media only screen and (max-width: 379px){
                top: -110px;
             }
        }
       
    }
}
.table_casino1{
    @media only screen and (max-width: 1179px){
        display: none;
    }
}
.services-img-wrap{
    display: none;
    @media only screen and (max-width: 1179px){
        display: block;
        width: 100%;
    }
    @media only screen and (max-width: 767px){
        display: none;
    }  
}
.services-img{
    display: block;
    @media only screen and (max-width: 1179px){
        display: none;
    }
    @media only screen and (max-width: 767px){
        display: block;
    }  
}
.services-img-group{
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: space-around;
    top: -117px;
}

.table__casino_mob{
    display: none;
    @media only screen and (max-width: 1179px){
        display: block;
    }
    .container__table-mob{
        max-width: 100%;
        width:670px;
        margin-bottom: 10px;
        img{
            width:24px;
            height: 24px;
            margin: 0 auto;
        }
        .table1__card_mob{
            width: 100%;
            box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
            border-radius: 20px;
            .table__card-top{
                padding: 28px 0 28px 70px;
                @media only screen and (max-width: 520px){
                    padding: 28px 5px 28px 30px;
                }
            }
            .card-blue{
                background: #183B67;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .card-red{
                background: #d50000;
                border-top-left-radius: 18px;
                border-top-right-radius: 18px;
                color: #ffffff;
                font-size: 16px;
                font-weight: 400;
                letter-spacing: 1px;
                text-transform: uppercase;
            }
            .table__card-body{
                padding: 35px 70px 15px 50px;
                display: flex;
                justify-content: space-between;
                font-size: 16px;
                font-weight: 400;
                @media only screen and (max-width: 540px){
                    padding: 35px 10px 15px 10px;
                }
                .card-body_left{
                    li{
                        padding-bottom: 14px;
                    }
                }
                .card5-body_left{
                    li{
                    padding-bottom: 19px; 
                    } 
                } 
                .card4-body_left{
                    li{
                    padding-bottom: 15px; 
                    } 
                }
                .card6-body_left{
                    width:130px;
                    @media only screen and (max-width: 519px){
                         li{
                            padding-bottom: 34px; 
                        } 
                    }
                   
                } 
                .card-body_rigth{
                    li{
                        padding-bottom: 14px;
                    }
                }
                .card2-body_rigth{
                    width: 157px;
                }
                .card4-body_right{
                    @media only screen and (max-width: 519px){
                        width:154px;
                    }
                }
                .card5-body_right{
                    @media only screen and (max-width: 660px){
                        width:128px;
                    }
                }
                .card11-body_left{
                    li  {
                         @media only screen and (max-width: 660px){
                            padding-bottom: 54px;
                        }
                    }   
                }
                .card13-body_left{
                    @media only screen and (max-width: 660px){
                        width: 330px;
                     
                    }   
                }
                .card15-body_right{
                    @media only screen and (max-width: 660px){
                        width: 148px;
                     
                    }   
                }
                .card1-table_mob_right{
                    width: 200px;
                }
                .card2-table_mob_right{
                    width: 130px;
                    li{
                        padding-bottom: 26px;
                    }
                }
                .card1-body_left{
                    li{
                        padding-bottom: 46px;
                    }      
                }
                .card2-body_left{
                    li{
                        padding-bottom: 15px;
                    }
                }
                .card3-body_left{
                    width:139px;
                    li{
                        padding-bottom: 14px;
                    }
                }
            }
            .table__card-footer{
                padding-bottom: 35px;
                text-align: center;
                a{
                    width: 170px;
                    height: 40px;
                    line-height: 2.5;
                }
            }
        }
    }
}
