.world-map {
  // box-shadow: inset 0 6px 10px rgba(0, 0, 0, 0.4);
  position: relative;
  &::before {
    background: linear-gradient(to bottom,#000 0%,transparent 100%);
    position: absolute;
    top: -10px;
    left: 0;
    width: 100%;
    height: 10px;
    content: '';
    opacity: .1;
    z-index: 3;
    transform: rotate(180deg);
  }
  .details {
    display: block;
    width: calc(100% + 30px);
    margin: 0 -15px 50px;
    font-size: 0;

    li {
      display: inline-block;
      width: 25%;
      padding: 0 15px 50px;
      vertical-align: top;

      .icon {
        display: block;
        width: 50px;
        float: left;

        img {
          max-width: 70%;
          max-height: 40px;
        }
      }

      p {
        display: block;
        width: calc(100% - 50px);
        margin-top: -.8rem;
        float: left;
        color: $color-grey-darker;
        font-size: 1.8rem;
        line-height: 2.5rem;
      }
    }
  }

  .map {
    display: block;
    position: relative;

    img {
      width: 100%;
    }

    .tooltip {
      @include horizontal-align;
      display: block;
      position: absolute;
      bottom: 100%;
      width: 210px;
      padding: 15px 20px;
      transition: all .3s ease;
      border-radius: 10px;
      background-color: $color-black-90;
      text-align: center;
      opacity: 0;
      visibility: hidden;

      &::before {
        position: absolute;
        right: 0;
        bottom: -9px;
        left: 0;
        width: 0;
        height: 0;
        margin: auto;
        border-width: 10px 10px 0;
        border-style: solid;
        border-color: transparentize($color-black, .2) transparent transparent;
        content: '';
      }

      &::after {
        position: absolute;
        top: 100%;
        left: 0;
        width: 100%;
        height: 0;
        content: '';
        z-index: -1;
      }

      img {
        width: 70%;
        margin: 0 auto 10px;
        vertical-align: top;
      }

      h3 {
        display: inline-block;
        padding-top: 1px;
        color: $color-white;
        font-size: 1.6rem;
        vertical-align: top;
      }

      p {
        display: block;
        margin-top: 10px;
        color: $color-white;
        font-size: 1.3rem;
      }
    }

    .mark {
      position: absolute;
      width: 0;
      transition: all .3s ease;

      img {
        opacity: .8;
      }

      &:hover {
        transform: scale(1.05) translateY(-1px);
        z-index: 2;

        img {
          opacity: 1;
        }

        .tooltip {
          bottom: calc(100% + 25px);
          opacity: 1;
          visibility: visible;

          &::after {
            height: 25px;
          }
        }
      }

      &.active {
        >img {
          animation: bounce 2s infinite linear;
        }

        &::before {
          @include gradient-shadow-radial;
          position: absolute;
          top: 103%;
          left: -17%;
          width: 140%;
          height: 25%;
          transform: scale(1);
          transform-origin: 0% 50%;
          content: '';
          opacity: 1;
          animation: bounceShadow 2s infinite linear;
          pointer-events: none;
        }
      }
    }
  }

  @media only screen and (max-width: 1179px) {
    .details {
      li {
        p {
          font-size: 1.6rem;
        }
      }
    }
  }

  @media only screen and (max-width: 989px) {
    .details {
      width: calc(100% + 10px);
      margin: 0 -5px 35px;

      li {
        width: 33.333333%;
        padding: 0 5px 35px;
      }
    }
  }

  @media only screen and (max-width: 659px) {
    .map {
      display: none;
    }

    .details {
      margin: 0 -5px -25px;

      li {
        width: 50%;
        padding: 0 5px 25px;
      }
    }
  }

  @media only screen and (max-width: 479px) {
    .details {
      li {
        .icon {
          width: 42px;
        }

        p {
          width: calc(100% - 42);
        }
      }
    }
  }

  @media only screen and (max-width: 399px) {
    .details {
      text-align: center;

      li {
        width: 100%;
        max-width: 250px;
        text-align: left;
      }
    }
  }
}